import { apiRoutes, api_ } from "config";

export namespace IDeleteSubContent {
    export type Input = { id: string };
    export type Output = void;
}

export const deleteSubContent = async (input: IDeleteSubContent.Input): Promise<IDeleteSubContent.Output> => {
    const url = `${apiRoutes.v1.subContent}/${input.id}`;

    await api_.delete(url);
}