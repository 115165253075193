import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Loading } from "../..";
import { configSlide1, configSlide1Small } from "../Config";

type Props = {
  title: string;
  midTitle?: string;
  endTitle?: string;
  isLoading?: boolean;
  description?: string;
  children: any;
  textPlus?: string;
  link?: string;
  icon: "uil-award" | "uil-graduation-cap" | "uil-chat-bubble-user" | "uil-book-open";
  type: "normal-4" | "normal-5";
  showButtons?: boolean;
};

export default function LargeSlides({
  title,
  description,
  children,
  textPlus,
  icon,
  type,
  midTitle,
  endTitle,
  link,
  isLoading = false,
  showButtons = true,
}: Props) {
  const goTo = useNavigate();
  let slides_: any;

  return (
    <>
      {isLoading ? (
        <div className="loading-box">
          <Loading.Small />
        </div>
      ) : (
        <div className="container-fluid areaCaixas">
          <div className="row cabecalho graduacao">
            <div className="col-xl-8 col-lg-8 col-md-7 areaTitulo">
              <div className="tituloArea">
                {title} {midTitle != undefined && <em>{midTitle}</em>}
                {endTitle}
              </div>
              {description && <div className="descricao">{description}</div>}
            </div>

            {showButtons && (
              <div className="col-xl-4 col-lg-4 col-md-5 areaBotoes">
                <div className="circulo botao esq" onClick={() => slides_.slickPrev()}>
                  <i className="uil uil-angle-left"></i>
                </div>
                <div className="circulo botao dir" onClick={() => slides_.slickNext()}>
                  <i className="uil uil-angle-right"></i>
                </div>

                <div className="circulo icone">
                  <i className={`uil ${icon}`}></i>
                </div>
              </div>
            )}
          </div>

          <Slider
            ref={(c) => (slides_ = c)}
            adaptiveHeight
            className="row caixas graduacao"
            {...(type == "normal-4" ? configSlide1 : configSlide1Small)}
          >
            {children}
          </Slider>

          <div className="row botaoMais" hidden={!textPlus}>
            <div className="col-xl-12 linha">
              <div className="botao" onClick={() => goTo(link)}>
                {textPlus}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
