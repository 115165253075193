import { Status } from 'enums';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AreaInputModel, AreaModel, TypeInputModel, TypeModel } from 'models';
import { createContext, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteArea, saveArea, updateArea } from 'services_/Area';
import { deleteType, saveType, updateType } from 'services_/Type';
import { addArea, addType, removeArea, removeType } from 'slices/GlobalValues';

export enum EnumRegisterTypes {
    AREA = 'area',
    TYPE = 'tipo'
}
type RegisterTypes  = EnumRegisterTypes.AREA | EnumRegisterTypes.TYPE;
export type ObjectOptions = TypeModel & AreaModel
export type ObjectOptionsInput = TypeInputModel & AreaInputModel

type ContextProps  = {
    registerType       : RegisterTypes;
    selectedItem       : ObjectOptions;
    setSelectedItem    : (item: ObjectOptions) => void;
    items              : ObjectOptions[];
    handleAddNewOption : () => void;
    handleSaveOption   : (data: ObjectOptionsInput) => void;
    handleUpdateOption : (data: Partial<ObjectOptionsInput> & { id: string }) => void;
    handleDeleteOption : (id: string ) => void;
}

type ProviderProps = {
    children: React.ReactNode;
}

export const ObjectRegisterOptionsContext = createContext({} as ContextProps);
export const ObjectRegisterOptionsProvider = ({ children }: ProviderProps) => {
    const { areas, types } = useAppSelector(state => state.globalValues)

    const { type: registerType } = useParams<{ type: RegisterTypes }>()
    const [selectedItem, setSelectedItem] = useState<ObjectOptions>({
        id     : '',
        name   : '',
        status : Status.Inactive
    })
    const [items, setItems] = useState<ObjectOptions[]>([])

    const dispatch = useAppDispatch()
    const goTo     = useNavigate()

    const handleAddNewOption = () => {
        const newOption: ObjectOptions = {
            id     : `tempId-${registerType === EnumRegisterTypes.AREA ? areas.length + 1 : types.length + 1}`,
            name   : '',
            status : Status.Inactive 
        }
        setSelectedItem(newOption)
    }

    const handleSaveOption = (data: ObjectOptionsInput) => {
        const save = (): Promise<ObjectOptions> => {
            if (registerType === EnumRegisterTypes.AREA) return saveArea(data)
            else return saveType(data)
        }

        const savingOption = toast.loading(`Salvando ${registerType === EnumRegisterTypes.AREA ? 'área' : 'tipo'}...`, { duration: null })
        save()
            .then((res) => { 
                if (registerType === EnumRegisterTypes.AREA) dispatch(addArea(res))
                else dispatch(addType(res))

                toast.success(`${registerType === EnumRegisterTypes.AREA ? 'Área salva' : 'Tipo salvo'} com sucesso!`, { id: savingOption })
            })
            .catch((err) => {
                toast.error(err.response.data.message, { id: savingOption })
            })
    }

    const handleUpdateOption = (data: Partial<ObjectOptionsInput> & { id: string }) => {
        const update = (): Promise<ObjectOptions> => {
            if (registerType === EnumRegisterTypes.AREA) return updateArea({ ...data, id: data.id })
            else return updateType({ ...data, id: data.id })
        }

        const savingOption = toast.loading(`Atualizando ${registerType === EnumRegisterTypes.AREA ? 'área' : 'tipo'}...`, { duration: null })
        update()
            .then((res) => { 
                toast.success(`${registerType === EnumRegisterTypes.AREA ? 'Área atualizada' : 'Tipo atualizado'} com sucesso!`, { id: savingOption })
                window.location.reload()
            })
            .catch((err) => {
                toast.error(err.response.data.message, { id: savingOption })
            })
    }

    const handleDeleteOption = (id: string ) => {
        const delete_ = async (): Promise<void> => {
            if (registerType === EnumRegisterTypes.AREA) deleteArea({ id })
            else deleteType({ id })
        }

        const deletingOption = toast.loading(`Deletando ${registerType === EnumRegisterTypes.AREA ? 'área' : 'tipo'}...`, { duration: null })
        delete_()
        .then((res) => { 
                if (registerType === EnumRegisterTypes.AREA) dispatch(removeArea(id))
                else dispatch(removeType(id))

                toast.success(`${registerType === EnumRegisterTypes.AREA ? 'Área deletada' : 'Tipo deletado'} com sucesso!`, { id: deletingOption })
            })
            .catch((err) => {
                toast.error(err.response.data.message, { id: deletingOption })
            })
    }

    useEffect(() => {
        if (![EnumRegisterTypes.AREA, EnumRegisterTypes.TYPE].includes(registerType)) goTo('/home')
    }, [areas.length])

    useEffect(() => {
        if (registerType === EnumRegisterTypes.AREA) setItems(areas)
        else setItems(types)
    }, [registerType, areas.length, types.length])

    return (
        <ObjectRegisterOptionsContext.Provider value={{ registerType, selectedItem, setSelectedItem, items, handleAddNewOption, handleSaveOption, handleUpdateOption, handleDeleteOption }}>
            {children}
        </ObjectRegisterOptionsContext.Provider>
    )
}