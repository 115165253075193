import { Box, Button, ButtonArea, Container, Input, Loading, Notification } from "components"
import { useForm, usePasswordState, useService } from "hooks"
import { useCallback, useState, Fragment } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import PasswordRecovery from "./PasswordRecovery"
import { IAdminLogin, adminLogin } from "services_/Admin"
import './style.css'

export default function AdminLogin() {
	const { passwordState, handleChangeStatePassword } = usePasswordState()
	const [forgotPassword, setForgotPassword] = useState(false)

	const goTo = useNavigate()

	const { data, errors, handleChangeValue, handleSubmit } = useForm<IAdminLogin.Input>({
		validations: {
			email: {
				required: true,
				isEmail: true,
			},
			password: {
				required: true,
			}
		},
		onSubmit: () => handleLogin()
	})
	
	const {
		isLoading: isLogging,
		handle: handleLogin,
	} = useService({
		service: () => adminLogin(data),
		onLoading: () => toast.loading('Efetuando login...'),
		onSuccess: (res) => {
			toast.dismiss()
			window.location.href = '/admin/home'
		},
		onError: (err) => {
			toast.dismiss()
			toast.error(err?.response?.data?.message, { duration: 15000 , position: 'top-center'})
		}
	})

	const handleCallBackReturn = useCallback(() => setForgotPassword(false), [forgotPassword])

	return (

		<div className="admin-login">
			<Container.Normal>
				{
					!forgotPassword ? (
						<Box title={'Login - Admin'}>
							{
								!isLogging &&
								<form onSubmit={handleSubmit}>
									<>
										<div className="row">
											<Input
												type="email"
												placeholder="E-mail *"
												name="cp_email"
												value={data.email}
												messageError={errors.email}
												disabled={isLogging}
												onChange={handleChangeValue('email')}
											/>

											<Input
												type="password"
												placeholder="Senha *"
												name="cp_senha"
												passwordState={passwordState}
												changePasswordState={handleChangeStatePassword}
												value={data.password}
												messageError={errors.password}
												disabled={isLogging}
												omitIcon
												onChange={handleChangeValue('password')}
											/>

											<ButtonArea className="linhaBotoes login-button-area">
												<Button type="submit" loading={isLogging} disabled={isLogging}>
													Entrar
												</Button>
											</ButtonArea>


											<div className="col-xl-12 linhaLinks-centered"
												onClick={() => setForgotPassword(true)}
											>
												<strong>Esqueci minha senha</strong>
											</div>
										</div>
									</>
								</form>
							}
							{isLogging && <Loading.Small loading={isLogging} />}
						</Box>
					) : (
						<PasswordRecovery _return={handleCallBackReturn} />
					)
				}
				<Notification position="top-right" />
			</Container.Normal>
		</div>
	)
}