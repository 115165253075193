import { useAppDispatch, useAppSelector, useService } from 'hooks';
import { createContext, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { IAnalyzeObject, analyzeObject } from 'services_/Admin';
import { setObjectsToAnalyze } from 'slices/GlobalObjects';

type ContextProps  = {
    handleSubmitAnalysis : (analysis: Partial<IAnalyzeObject.Input>) => void;
    sendingAnalysis      : boolean;
}

type ProviderProps = {
    children: React.ReactNode;
}

export const ObjectAnalysisContext = createContext({} as ContextProps);
export const ObjectAnalysisProvider = ({ children }: ProviderProps) => {
    const { objectsToAnalyze } = useAppSelector(state => state.globalObjects)
    const [sendingAnalysis, setSendingAnalysis] = useState(false);

    const dispatch = useAppDispatch()
    const goTo = useNavigate()

    const handleSubmitAnalysis = async (analysis: Partial<IAnalyzeObject.Input>) => {
        const sendingAnalysis = toast.loading('Enviando análise...', { duration: null })
        setSendingAnalysis(true)
        try {
            /*if(initialState!==changedState){
            console.log("True")
            }*/

            await analyzeObject({
                status      : analysis.status,
                observation : analysis.observation,
                objectId    : analysis.objectId
            })

            toast.success('Análise enviada com sucesso!', { id: sendingAnalysis })
            
            dispatch(setObjectsToAnalyze(objectsToAnalyze.filter(object => object.id !== analysis.objectId)))
            goTo('/admin/home')
        } catch (error) {
            toast.error(error.response.data.message, { id: sendingAnalysis })
        } finally {
            setSendingAnalysis(false)
        }
    }

    return (
        <ObjectAnalysisContext.Provider value={{ handleSubmitAnalysis, sendingAnalysis }}>
            { children }
        </ObjectAnalysisContext.Provider>
    )
}