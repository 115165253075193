import { apiRoutes, api_ } from "config";

export namespace IDeleteType {
    export type Input = { id: string }
    export type Output = void
}

export const deleteType = async (info: IDeleteType.Input): Promise<IDeleteType.Output> => {
    const url = `${apiRoutes.v1.types}/${info.id}`;
    await api_.delete(url);
}