import { useAppDispatch } from "hooks";
import { closeSteps } from "slices/AuthStepsSlice";
import { UserTypes } from "enums";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "contexts";
import "./styles.css";

export default function ProfileSelect() {
  const { userInfo, handleSelectProfile, profileSelected } = useContext(UserContext);

  const profilePicture = userInfo?.profilePicture || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

  const dispath = useAppDispatch();
  const goTo = useNavigate();

  const handleProfileRedirect = (profile: UserTypes.Apprentice | UserTypes.Partner) => {
    handleSelectProfile(profile, !(profile === UserTypes.Apprentice && window.location.pathname === "/"));
    if (profile === UserTypes.Apprentice && window.location.pathname === "/") window.location.href = "/home";
    dispath(closeSteps());
  };

  return (
    <>
      <div className="col-xl-12 linhaPessoa-centered">
        <div className="linha">
          <img className="userPic" src={profilePicture} />
          <span>{userInfo?.name}</span>
        </div>
      </div>

      <div className="col-xl-12 linhaPessoa-centered entrarComo">Entrar como</div>

      <div className="profile-container">
        <div className="btn-profile" role="button" onClick={() => handleProfileRedirect(UserTypes.Apprentice)}>
          <div className="profile-icon">
            <i className="uil uil-graduation-cap" />
          </div>
          <div>
            <span>Aprendiz</span>
          </div>
        </div>

        <div className="btn-profile" role="button" onClick={() => handleProfileRedirect(UserTypes.Partner)}>
          <div className="profile-icon">
            <i className="uil uil-suitcase-alt" />
          </div>
          <div>
            <span>Parceiro</span>
          </div>
        </div>
      </div>
    </>
  );
}
