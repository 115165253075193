import { useContext } from "react"
import { MultiSelect } from "../../.."
import { ObjectsSearchContext } from "contexts"
import { AreaModel, TypeModel } from "models"
import { TField } from "types"
import './style.css'

 
type Props = {
    types?: TypeModel[]
    modalities?: TField[]
    areas?: AreaModel[]
    status?: TField[]
}

export default function SearchFilters({ types, modalities, areas, status }: Props) {
    const { handleSetObjectFilter, searchParams } = useContext(ObjectsSearchContext);

    return (
        <>
            <div className="col-xl-12 filtros">
                <div className="row colunas">
                    {/* <div className="col-xl-1 col-lg-1">
                        <div className="legendaFiltros">Filtros</div>
                    </div> */}
                    {
                        status?.length >= 0 &&
                        <div className="col-xl-4 filters">
                            <MultiSelect
                                label="Exibir por Status"
                                placeholder='Selecione...'
                                data={
                                    status.map((status_) => ({ text: status_?.text, value: status_?.value }))
                                }
                                values={
                                    searchParams.getAll('status') && 
                                    status.filter(status_ => searchParams.getAll('status').includes(status_.value))
                                        .map(status_ => ({ label: status_.text, value: status_.value }))
                                }
                                onChange={ ({ item, action }) => handleSetObjectFilter({ param: 'status', value: item?.value, action }) }
                                limit={4} 
                            />
                        </div>
                    }
                    {
                        types?.length >= 0 &&
                        <div className="col-xl-4 filters">
                            <MultiSelect
                                label="Tipo de Recurso"
                                placeholder='Selecione...'
                                data={ types.map((type) => ({ text: type?.name, value: type?.id })) }
                                values={
                                    searchParams.getAll('typeId') && 
                                    types.filter(type => searchParams.getAll('typeId').includes(type?.id))
                                        .map(type => ({ label: type.name, value: type?.id }))
                                }
                                onChange={ ({ item, action }) => handleSetObjectFilter({ param: 'typeId', value: item?.value, action }) }
                                limit={3} 
                            />
                        </div>
                    }

                    {
                        modalities?.length >= 0 &&
                        <div className="col-xl-4 filters">
                            <MultiSelect
                                label="Modalidade"
                                placeholder='Selecione...'
                                data={
                                    modalities.map((modality) => ({ text: modality?.text, value: modality?.value }))
                                }
                                values={
                                    searchParams.getAll('modality') && 
                                    modalities.filter(modality => searchParams.getAll('modality').includes(modality.value))
                                        .map(modality => ({ label: modality.text, value: modality.value }))
                                }
                                onChange={ ({ item, action }) => handleSetObjectFilter({ param: 'modality', value: item?.value, action }) }
                                limit={3} 
                            />
                        </div>
                    }
                   
                    {
                        areas?.length >= 0 &&
                        <div className="col-xl-4 filters">
                            <MultiSelect
                                label="Área de Conhecimento"
                                placeholder='Selecione...'
                                data={ areas.map((area) => ({ text: area?.name, value: area?.id })) }
                                values={
                                    searchParams.getAll('areaId')?.length > 0 && 
                                    areas.filter(area => searchParams.getAll('areaId').includes(area?.id))
                                        .map(area => ({ label: area.name, value: area?.id }))
                                }
                                onChange={ ({ item, action }) => handleSetObjectFilter({ param: 'areaId', value: item?.value, action }) }
                                limit={3} 
                            />
                        </div>

                    }
                </div>
            </div>
        </>
    )

}