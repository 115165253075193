import { useContext, useEffect, useState } from 'react'
import { removeCurrencyMask } from 'Functions'
import { useAppDispatch, useAppSelector, useMask, useService } from 'hooks'
import { Cart } from "types/Cart";
import './style.css'
import { HorizontalTable } from 'components'
import toast from 'react-hot-toast'
import { deleteCartItens } from 'services_/Class'
import { RootState } from 'Store'
import { UserContext } from 'contexts';
import { fetchCartItems } from 'slices/CartSlice';
import { setOrder } from 'slices/OrderSlice';
import { useNavigate } from 'react-router-dom';

export default function PaymentResume() {
    const { order } = useAppSelector(state => state.order)
    const [total, setTotal] = useState('')
	const cart = useAppSelector((state: RootState) => state.cart);
	const { userInfo } = useContext(UserContext);
	const dispatch = useAppDispatch();
	const goTo     = useNavigate();

    // Função para calcular o total
	const calculateTotal = (orderItems) => {
        const total_ = orderItems.reduce((acc, item) => acc + removeCurrencyMask(String(item.object.price)), 0)
        return total_.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    }
	
    // Efeito para recalcular o total quando houver uma alteração no pedido ou no carrinho
    useEffect(() => {
        setTotal(calculateTotal(order.classes))
    }, [order, cart])

	// Efeito para fechar o checkout caso o carrinho fique vazio
	useEffect(() => {
        if(!order.classes.length) goTo('/');
    }, [order]);    

	useEffect(() => {

	})

	const HandleDeleteItem = async (item) => {
    try {
        // Deleta o item da lista de itens do carrinho
        await deleteCartItens({
            listOfIds: [item.addClasses.id],
            userId: userInfo.userId,
        });

        // Atualiza o estado do carrinho no Redux
        await dispatch(fetchCartItems());

        // Atualiza o estado do order removendo o item deletado
        const updatedOrderClasses = order.classes.filter(orderItem => orderItem.addClasses.id !== item.addClasses.id);
        dispatch(setOrder(updatedOrderClasses));

        // Exibe mensagem de sucesso
        toast.success('Item removido com sucesso!');
    } catch (error) {
        // Exibe mensagem de erro
        toast.error( error?.response?.data?.message || 'Erro ao remover item do carrinho.');
    }
};
	
	return (
		<div className="col-xl-12">
			<div className="row">
				<div className="col-xl-12">
					<HorizontalTable title="Resumo de compra">
						{
							order.classes.map(item=> (
								<>
									<HorizontalTable.tr>
										<HorizontalTable.td>
											{item.object.title} - {item.class.title}
										</HorizontalTable.td>
										
										<HorizontalTable.td highlight>
											{
												String(item.object.price).includes('R$')
													? item.object.price
													: item.object.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
											}
										</HorizontalTable.td>
										<HorizontalTable.td>
										{item.addClasses && item.addClasses.id && (
											<div 
											key={item.addClasses.id} 
											onClick={() => HandleDeleteItem(item)}
											className="delete-icon"
											>
											<i className="uil uil-trash-alt" style={{ fontSize: '24px' }}></i>
											</div>
										)}
										</HorizontalTable.td>
									</HorizontalTable.tr>
								</>
							))
						}
						</HorizontalTable>
						<HorizontalTable  title="Resumo de pagamento">
						<HorizontalTable.tr>
							<HorizontalTable.td>
								<b>Total</b>
							</HorizontalTable.td>
							<HorizontalTable.td highlight>
								{total}
							</HorizontalTable.td>
						
						</HorizontalTable.tr>
					</HorizontalTable>
				</div>
			</div>
		</div>
	)
}