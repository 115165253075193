import linkedinImage from "./pt_BR.png";
import "./style.css";
export default function AddLinkdinButton({ onClick }: { onClick: () => void }) {
  return (
    <>
      {/* <div
        style={{
          marginTop: "10px",
        }}
      >
        <a
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Test%20Certificate&organizationId=1337&issueYear=2018
&issueMonth=2&expirationYear=2020&expirationMonth=5&certUrl=
https%3A%2F%2Fdocs.microsoft.com%2Fen-us%2Flearn%2Fcertifications%2Fd365-functional-consultant-sales&certId=1234"
        >
          <img
            className="linkedinbutton"
            src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png "
            alt="LinkedIn Add to Profile button"
          />
        </a>
      </div>

      <div className="botao-addLinkedin">
        <i className="ui uil-linkedin" />
        <a>ADICIONAR AO PERFIL</a>
      </div> */}

      <div className="botao linkedin" onClick={() => onClick()}>
        <div>
          <i className="ui uil-linkedin" />
          ADICIONAR AO PERFIL
        </div>
      </div>
    </>
  );
}
