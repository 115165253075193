import { useEffect, useState, useContext } from "react";
import { useAppSelector } from "hooks";
import { StepsBox } from "components";
import { ObjectStatus } from "enums";
import { Approval, Classes, Contents, InitialInfos } from "./components";
import { ObjectRegisterContext } from "contexts";
import { useDispatch } from "react-redux";
import { setShowOnlyClass, setStep } from "slices/StepsBoxSlice";
import { ContentRegisterProvider } from "./components/Contents/components/ContentRegisterContext";

export default function ObjectRegister() {
  const { object, loadingObject } = useContext(ObjectRegisterContext);
  const { currentStep, showOnlyClass } = useAppSelector(
    (state) => state.stepsBox
  );
  const [title, setTitle] = useState<string>("Informações do Recurso");
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentStep != 3)
      setTitle(
        (loadingObject
          ? ""
          : ObjectStatus.isCreating(object?.status) ||
            object?.status == undefined
          ? "Cadastro das "
          : "Visualização das ") + "Informações do Recurso"
      );
    else setTitle("Cadastro de Turmas no Recurso");
  },[currentStep, loadingObject, object?.status]);

  //Impedir que ele passe para enviar análise sem ter algum dado
  useEffect(() => {
    if (currentStep === 2 && object?.contents.length === 0) dispatch(setStep( currentStep - 1))
  },[currentStep, object?.contents])

  const handleNextStep = () => {
    dispatch(setStep(currentStep + 1));
  };

  return (
    <StepsBox
      isLoading={loadingObject}
      title={title}
      steps={[
        {
          index: 0,
          title: "Informações Iniciais",
          icon: "uil uil-info-circle",
          element: <InitialInfos onNext={handleNextStep} />,
          isActive: true,
          hidden: showOnlyClass,
        },
        {
          index: 1,
          title: "Conteúdos",
          icon: "uil uil-book-alt",
          element: <ContentRegisterProvider children={<Contents />}/>,          
          isActive: !!object?.id,
          hidden: showOnlyClass,
        },
        {
          index: 2,
          title: "Enviar para análise",
          icon: "uil uil-file-search-alt",
          element: <Approval />,
          isActive: !!object?.contents?.length, // É necessário ter conteúdos para enviar para análise.
          hidden: showOnlyClass,
        },
        {
          index: 3,
          title: "Turmas",
          icon: "uil uil-users-alt",
          element: <Classes />,
          isActive: ObjectStatus.isApproved(object?.status),
        },
      ]}
    />
  );
}
