import { api_ } from "config";
import { authenticate } from "./Authenticate";

export namespace IEmailConfirmation {
    export type Input = {
        code: string;
    };
    export type Output = void;
}

export const emailConfirmation = async ({ code }: IEmailConfirmation.Input): Promise<IEmailConfirmation.Output> => {
    const url = `/v1/emailConfirmation/${code}`;
    await api_.post(url);
    await authenticate()
}