import { createSlice } from "@reduxjs/toolkit";
import { IStates } from "interfaces";

interface IStepsBoxState extends IStates {
    currentStep: number;
    showOnlyClass: boolean;
}

const initialState: IStepsBoxState = {
    isLoading   : false,
    isError     : false,
    isSuccess   : false,
    showOnlyClass: false,
    errorData   : null,
    currentStep : 0
}


export const stepsBoxSlice = createSlice({
    name: 'stepsBox',
    initialState,
    reducers: {
        nextStep    : (state) => { state.currentStep += 1 },
        previous : (state) => { state.currentStep -= 1 },
        resetSteps  : (state) => { state.currentStep = 0 },
        setStep     : (state, action) => { state.currentStep = action.payload },
        setShowOnlyClass: (state, action) => { state.showOnlyClass = action.payload }
    }
})

export const { nextStep, previous, resetSteps, setStep, setShowOnlyClass } = stepsBoxSlice.actions
export default stepsBoxSlice.reducer
