import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { IStates } from "interfaces";
import { orderService } from "services";
import { ISubscription, TClass, TObject, TPayment } from "types";
import { Cart } from "types/Cart";

type TOrderResume = { class: Pick<TClass.Entity, 'id' | 'title'>, object: Pick<TObject.Entity, 'title' | 'price'>, addClasses?: Cart.CartItem }
type TOrder       = {
    classes : TOrderResume[]
    payment : TPayment.Input
    addClasses?: Cart.CartItem
}

interface IOrderState extends IStates {
    order           : TOrder,
    paymentProfiles : TPayment.PaymentProfile[]
}

export const initialState: IOrderState = {
    order: {
        classes: [],
        payment: undefined,
        addClasses: undefined,
    },
    paymentProfiles : [],
    isError         : false,
    isLoading       : false,
    isSuccess       : false,
    errorData       : undefined
}

export const getPaymentProfiles = createAsyncThunk(
    'order/getPaymentProfiles',
    async (_, thunkAPI) => {
        const loadingPaymentProfiles = toast.loading('Carregando perfis de pagamento...')
        try {
            const response = await orderService.getPaymentProfiles()
            toast.dismiss(loadingPaymentProfiles)
            return response
        } catch (error) {
            toast.dismiss(loadingPaymentProfiles)
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const classSubscription = createAsyncThunk(
    'order/classSubscription',
    async (data: ISubscription.Input, thunkAPI) => {
        const loadingSubscription = toast.loading('Gerando pedido...',{duration:100000})
        
        try {
            const response = await orderService.classSubscription(data)
            toast.success('Pedido gerado com sucesso!', { id: loadingSubscription })
            window.location.href = '/usuario/recursos'
            return response
        } catch (error) {
            toast.error(error.response.data.message, { id: loadingSubscription })
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deletePaymentProfile = createAsyncThunk(
    'order/deletePaymentProfile',
    async (id: number, thunkAPI) => {
        const deletingPayementProfile = toast.loading('Deletando perfil de pagamento...')
        try {
            await orderService.deletePaymentProfile(id)
            toast.success('Perfil de pagamento deletado com sucesso!', { id: deletingPayementProfile })
            thunkAPI.dispatch(removePaymentProfile({ id }))
        } catch (error) {
            toast.error(error.response.data.message, { id: deletingPayementProfile })
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetOrderState: (state) => {
            state.order.classes = []
            state.order.payment = undefined
            state.isError       = false
            state.isLoading     = false
            state.isSuccess     = false
            state.errorData     = undefined
        },
        setOrder(state, action: PayloadAction<TOrderResume[]>) {
            state.order.classes = action.payload
        },
        setPaymentProfile(state, action: PayloadAction<TPayment.Input>) {
            if (!!action.payload.payment_profile_id) state.order.payment = {
                payment_method     : action.payload.payment_method,
                payment_profile_id : action.payload.payment_profile_id,
                card               : {
                    card_cvv             : undefined,
                    card_expiration_date : undefined,
                    card_number          : undefined,
                    card_method          : undefined,
                    card_name            : undefined,
                    cpf_cnpj             : undefined,
                    user_name            : undefined
                },
                installments: action.payload.installments,
                save_profile: action.payload.save_profile
            }
            else state.order.payment = action.payload
        },
        removePaymentProfile(state, action: PayloadAction<Pick<TPayment.PaymentProfile, 'id'>>) {
            state.paymentProfiles = state.paymentProfiles.filter(profile => profile.id !== action.payload.id)
        }
    },
    extraReducers: (builder) => {
        builder
            // ----------------- GET PAYMENT PROFILES -----------------
            .addCase(getPaymentProfiles.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPaymentProfiles.fulfilled, (state, action) => {
                state.isLoading     = false
                state.isSuccess     = true
                state.paymentProfiles = action.payload
            })
            .addCase(getPaymentProfiles.rejected, (state, action) => {
                state.isLoading = false
                state.isError   = true
                state.errorData = action.payload
            })
            // ----------------- CLASS SUBSCRIPTION -----------------
            .addCase(classSubscription.pending, (state) => {
                state.isLoading = true
            })
            .addCase(classSubscription.fulfilled, (state) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(classSubscription.rejected, (state, action) => {
                state.isLoading = false
                state.isError   = true
                state.errorData = action.payload
            })
            // ----------------- DELETE PAYMENT PROFILE -----------------
            .addCase(deletePaymentProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deletePaymentProfile.fulfilled, (state) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(deletePaymentProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isError   = true
                state.errorData = action.payload
            })
    }
})

export const { resetOrderState, setOrder, setPaymentProfile, removePaymentProfile } = orderSlice.actions
export default orderSlice.reducer