import { ReactNode } from "react"
import './style.css'
type Props = {
    children: ReactNode
}

export namespace Container {
    export function Normal({ children }: Props) {
        return (
            <div id="tela" className='flex-centered'>
                <div className="centro">
                    <div className="container">
                        <div className="row">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    export function Fluid({ children }: Props) {
        return (
            <div className="comum" id="tela">
                <div className="centro">
                    <div className="container-fluid">
                        <div className="row">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    export function Internals({ children }: Props) {
        return (
            <div className="container-fluid">
                <div className="row areaCaixas">
                    {children}
                </div>
            </div>
        )
    }

    export function Modal({ children }: Props) {
        return (
            <div id="tela-modal">
                <div className="centro">
                    <div className="container">
                        <div className="row">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // TODO: Substituir por Body
    export function ObjectBody({ children }: Props) {
        return (
            <div id="corpo" className='corpo'>
                <div className="caixa menor">
                    {children}
                </div>
            </div>
        )
    }

    export function Body({ children }: Props) {
        return (
            <div id="corpo" className="container body">
                <div className="caixa menor body">
                    { children }
                </div>
            </div>
        )
    }
}