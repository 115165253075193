import { Terms } from "../../../index";
import { useAppDispatch, useAppSelector, useForm, usePasswordState, useService } from "hooks";
import { Box, Modal, Input, SocialLogin, MultiSelect, Button, ButtonArea, Checkbox, Loading } from "../../../../../index";
import { closeSteps, openSteps } from "slices/AuthStepsSlice";
import { IAuthSteps } from "interfaces";
import { useState, useContext } from "react";
import { Status, UserTypes } from "enums";
import toast from "react-hot-toast";
import { saveUser } from "services_/User";
import { UserInputModel } from "models";
import { UserContext } from "contexts";
import "./style.css";

type UserRegisterForm = UserInputModel & {
  areas: string[];
  termsChecked: boolean;
};

type Props = {
  defaultValues?: UserRegisterForm;
};

export default function UserRegister({ defaultValues }: Props) {
  const { areas, isLoading: isLoadingGlobalValues } = useAppSelector((state) => state.globalValues);
  const { passwordState, handleChangeStatePassword } = usePasswordState();
  const { handleSelectProfile, handleSetUserInfoByToken } = useContext(UserContext);

  const [isPartner, setIsPartner] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const dispatch = useAppDispatch();

  const { isLoading: savingUser, handle: handleSaveUser } = useService({
    service: () =>
      saveUser({
        userInfo: { ...data, type: isPartner ? UserTypes.Apprentice_Partner : UserTypes.Apprentice },
        chosenAreas: data.areas,
      }),
    onSuccess: (res) => {
      const profile = isPartner ? UserTypes.Partner : UserTypes.Apprentice;
      handleSelectProfile(profile, false);

      if (!isPartner) handleSetUserInfoByToken(res.accessToken);

      const userData: UserInputModel = { ...data, type: isPartner ? UserTypes.Apprentice_Partner : UserTypes.Apprentice };
      dispatch(
        openSteps({
          step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
          props: {
            title: "Confirmação de E-mail",
            email: data.email,
            type: "email",
            previousStep: IAuthSteps.Steps.USER_REGISTER,
            nextStep: {
              step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
              props: { userData },
            },
          },
        })
      );
    },
    onError: (error) => toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'}),
  });

  const { data, errors, handleChangeValue, handleChangeCheckValue, handleSubmit, handleChangeMultiSelectValue, setData } =
    useForm<UserRegisterForm>({
      initialValues: defaultValues,
      validations: {
        name: {
          required: true,
        },
        email: {
          required: true,
          isEmail: true,
        },
        password: {
          required: true,
        },
        confirmPassword: {
          required: true,
        },
        termsChecked: {
          required: true,
        },
        areas: {
          required: !isPartner,
          numberOfItems: isPartner ? 0 : 3,
        },
      },
      onSubmit: () => handleNextStep(),
    });

  const handleNextStep = () => {
    if (!isPartner) handleSaveUser();
    else {
      const userData: UserInputModel = { ...data, type: isPartner ? UserTypes.Apprentice_Partner : UserTypes.Apprentice };
      if (data.password === data.confirmPassword) {
        dispatch(
          openSteps({
            step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
            props: {
              previousStep: {
                step: IAuthSteps.Steps.USER_REGISTER,
                props: { defaultValues: userData },
              },
              userData,
            },
          })
        );
      } else {
        toast.error("As senhas não conferem", { duration: 15000 , position: 'top-center'});
      }
    }
  };

  const handleCloseModal = () => dispatch(closeSteps());

  return (
    <>
      <Modal className="user-register-modal" show={true} onClose={handleCloseModal} isLoading={savingUser || isLoadingGlobalValues}>
        <Box title={!showTerms ? "Cadastre-se" : "Termos de uso"}>
          {!savingUser && !isLoadingGlobalValues ? (
            <form onSubmit={handleSubmit}>
              {!showTerms ? (
                <div className="row">
                  <ButtonArea className="user-register">
                    <Button type="button" className={`botao ${isPartner ? "cadastrese" : ""}`} onClick={() => setIsPartner(false)}>
                      <i className="uil uil-graduation-cap"></i>
                      Aprendiz
                    </Button>
                    <Button type="button" className={`botao ${!isPartner ? "cadastrese" : ""}`} onClick={() => setIsPartner(true)}>
                      <i className="uil uil-suitcase-alt"></i>
                      Parceiro
                    </Button>
                  </ButtonArea>

                  <Input
                    name="cp_name"
                    placeholder="Nome *"
                    type="text"
                    icon="uil-user"
                    value={data.name}
                    messageError={errors.name}
                    onChange={handleChangeValue("name")}
                  />

                  <Input
                    name="cp_email"
                    placeholder="E-mail *"
                    type="email"
                    icon="uil-envelope"
                    value={data.email}
                    messageError={errors.email}
                    onChange={handleChangeValue("email")}
                  />

                  <Input
                    name="cp_password"
                    placeholder="Senha *"
                    type="password"
                    icon="uil-lock"
                    passwordState={passwordState}
                    changePasswordState={handleChangeStatePassword}
                    value={data.password}
                    messageError={errors.password}
                    onChange={handleChangeValue("password")}
                  />

                  <Input
                    name="cp_passwordConfirm"
                    placeholder="Confirmar Senha *"
                    type="password"
                    icon="uil-lock"
                    passwordState={passwordState}
                    changePasswordState={handleChangeStatePassword}
                    value={data.confirmPassword}
                    messageError={errors.confirmPassword}
                    onChange={handleChangeValue("confirmPassword")}
                  />

                  {!isPartner && (
                    <MultiSelect
                      placeholder="Selecione 3 áreas de interesse *"
                      data={areas.filter((area) => area.status === Status.Active).map((area) => ({ value: area.id, text: area.name }))}
                      values={
                        !!data?.areas?.length && data.areas.map((area) => ({ value: area, label: areas.find((a) => a.id === area)?.name }))
                      }
                      onChange={handleChangeMultiSelectValue("areas")}
                      messageError={errors.areas}
                      limit={3}
                    />
                  )}
                  <div className="correction">
                    <Checkbox
                      checked={data.termsChecked}
                      messageError={errors.termsChecked}
                      onChange={handleChangeCheckValue("termsChecked")}
                    >
                      <span>
                        Aceito os <a onClick={() => setShowTerms(!showTerms)}>Termos de uso</a> e <a>Políticas</a> do Academy 4 Life.
                      </span>
                    </Checkbox>
                  </div>

                  <ButtonArea className="user-register">
                    <Button type="submit" className="botao">
                      Cadastrar-se
                    </Button>
                  </ButtonArea>

                  <SocialLogin />
                </div>
              ) : (
                <Terms contract="user" />
              )}
              <div className="col-xl-12 linhaTermos-centered" hidden={!showTerms}>
                <span>
                  <a onClick={() => setShowTerms(!showTerms)}>Voltar</a>
                </span>
              </div>
            </form>
          ) : (
            <Loading.Small loading={isLoadingGlobalValues || savingUser} />
          )}
        </Box>
      </Modal>
    </>
  );
}
