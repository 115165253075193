import { Link } from 'react-router-dom'
import FooterSVG from '../../../../assets/images/brands/footer.svg'

export default function HomeFooter() {
    return (
        <div id="rodape">
            <div className="container">
                <div className="row linhas">
                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-xl-12 marca">
                                <Link to="#">
                                    <img src={FooterSVG} alt="" />
                                </Link>
                            </div>

                            <div className="col-xl-12 contatos">
                                <div className="item">
                                    <i className="uil uil-phone"></i>
                                    <span>79 12345-6789</span>
                                </div>

                                <div className="item">
                                    <i className="uil uil-envelope"></i>
                                    <span>contato@academy4life.com.br</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}