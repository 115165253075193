import { apiRoutes, api_ } from "config";
import { SubscriptionViewModel } from "models";

export namespace IGetSubscribedClass {
  export type Input = { classId: string };
  export type Output = SubscriptionViewModel;
}

export const getSubscribedClass = async (input: IGetSubscribedClass.Input): Promise<IGetSubscribedClass.Output> => {
    const url = `${apiRoutes.v1.subscriptions}/class/${input.classId}`;

    const response = await api_.get<SubscriptionViewModel>(url);
    return response.data;
}