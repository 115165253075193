import { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Content from "./components/Content";
import Button from "components/Button";
import { Accordion_, Notification } from "components";
import { ObjectRegisterContext } from "contexts";
import { ObjectStatus } from "enums";
import { ContentRegisterContext } from "./components/ContentRegisterContext";
import "./style.css";
import { useDispatch } from "react-redux";
import { nextStep } from "slices/StepsBoxSlice";

export default function Contents() {
  const { object, loadingObject } = useContext(ObjectRegisterContext);
  const { openContentModal } = useContext(ContentRegisterContext);
  const [dontStep, setDontStep] = useState(true);

  const dispatch = useDispatch();

  const LOADING_PAGE = loadingObject;
  const VIEW_ONLY =
    !(ObjectStatus.isCreating(object?.status) || object?.status == undefined) &&
    !ObjectStatus.isDisapproved(object?.status);

  const handleNextStep = () => {
    dispatch(nextStep()); //Para pular o step
  };
  
  useEffect(() =>{
    if (object?.contents?.length === 0) setDontStep(true)
    if (object?.contents?.length >= 1) setDontStep(false)    
  },[object?.contents])

  return (
    <div>
      {!!object?.contents?.length ? (
        <Accordion_.Container size="lg" className="item-area">
          <Accordion_>
            {object?.contents.map((content, index) => (
              <Content data={content} viewOnly={VIEW_ONLY} key={content.id} index={index} />
            ))}
          </Accordion_>
        </Accordion_.Container>
      ) : (
        <>
          <div className="content-message-box">{LOADING_PAGE ? "Carregando..." : "Não há conteúdo cadastrado."}</div>
        </>
      )}

      {!VIEW_ONLY && (
        <div className="content-buttons">
          <Button
            disabled={LOADING_PAGE}
            type="button"
            className="button-add-content"
            onClick={() =>
              openContentModal({
                type: "content",
                action: "create",
              })
            }
          >
            Adicionar Conteúdo
          </Button>
          <Button
            disabled={dontStep || LOADING_PAGE}
            type="button"
            className="button-advance-step"
            onClick={() =>
              handleNextStep()
            }
          >
            Avançar
          </Button>
        </div>
      )}
      <Notification position="bottom-left" />
    </div>
  );
}
