import { apiRoutes, api_ } from "config";
import { Status } from "enums";
import { AreaInputModel, AreaViewModel } from "models";

export namespace IUpdateArea {
    export type Input = Required<Pick<AreaInputModel, 'id'>> & Partial<Omit<AreaInputModel, 'id'>>;
    export type Output = AreaViewModel;
}

export const updateArea = async (info: IUpdateArea.Input): Promise<IUpdateArea.Output> => {
    const url = `${apiRoutes.v1.areas}/${info.id}`;

    const body: Partial<Pick<AreaInputModel, 'name' | 'status'>> = {};
    if (info.name) body.name = info.name;
    if (info.statusBool !== undefined) body.status = info.statusBool ? Status.Active : Status.Inactive;

    const response = await api_.put<AreaViewModel>(url, body);
    return response.data;
}