import { ObjectStatus } from 'enums'
import './style.css'
type Props = {
    type: 'object' | 'user' | 'class' | 'custom'
    title: string
    description: string
    image?: string
    onclick?: () => void
    selected?: boolean //status para card de turma
    status?: ObjectStatus
    icon?: string
}

export default function Item({ type, title, description, image, selected, status, onclick, icon }: Props) {

    const styleStatus: string =
        status != undefined &&
            ObjectStatus.isCreating(status) ? ' creating'
            : ObjectStatus.isPending(status) ? ' pending'
                : ObjectStatus.isApproved(status) ? ' approved'
                    : ObjectStatus.isDisapproved(status) && ' disapproved'

    return (
        <div
            className={'item ' + (type === "object" ? 'itemCurso' : 'itemProfessor ' + (selected ? ' selected-back ' : '')) + styleStatus}
            role='button'
            onClick={onclick}
        >
            <div className='texto'>
                <div className='nome'>
                    {title}
                </div>
                <div className='descricao'>
                    {description}
                </div>
            </div>
            {
                type === 'object' &&
                <div className="foto">
                    <img src={image} alt="" className="capa" />
                </div>
            }
            {
                type === 'user' &&
                <div className="icone">
                    <i className="uil uil-user-circle"></i>
                </div>
            }

            {
                type === 'class' &&
                <div className="icone">
                    <i className={`uil uil-users-alt`}></i>
                </div>
            }
            {
                type === 'custom' &&
                <div className="icone">
                    <i className={icon}></i>
                </div>
            }
        </div>
    )
}