import { Link } from "react-router-dom";
import PartnerImage from "../../../../assets/images/inicioSejaNossoParceiro.png";
import { useAppDispatch } from "hooks";
import { IAuthSteps } from "interfaces";
import { openSteps } from "slices/AuthStepsSlice";
import { UserContext } from "contexts";
import { useContext } from "react";
import { UserTypes } from "enums";
import toast, { Toaster } from "react-hot-toast";

export default function PartnerBanner() {
  const { userInfo, handleBecomePartner } = useContext(UserContext);
  const dispatch = useAppDispatch();

  const handleOpenPartnerRegister = () => {
    if (UserTypes.userIsOnlyApprentice(userInfo?.type) && userInfo) handleBecomePartner();
    else
      dispatch(
        openSteps({
          step: IAuthSteps.Steps.USER_REGISTER,
        })
      );
  };

  return (
    <div className="inicioSejaNossoParceiro">
      <div className="container-fluid area">
        <div className="row colunas">
          <div className="col-xl-6 col-lg-6 texto">
            <div className="titulo">Seja nosso parceiro</div>
            <div className="descricao">
              Publique o seu <strong>curso, palestra, workshop e etc </strong>
              em nossa plataforma, eles serão divulgados por nós, é rápido e fácil.
            </div>
            {(UserTypes.userIsOnlyApprentice(userInfo?.type) || !userInfo) && (
              <Link to="" className="botao" onClick={handleOpenPartnerRegister}>
                {userInfo ? "Virar Parceiro" : "Cadastre-se"}
              </Link>
            )}
          </div>

          <div className="col-xl-6 col-lg-6 imagem">
            <img src={PartnerImage} alt="imagem_parceria" />
          </div>
        </div>
      </div>
      <Toaster position="bottom-left" />
    </div>
  );
}
