import { apiRoutes, api_ } from "config"
import { ObjectViewModel } from "models"

export namespace IGetHomeObjects {
    export type Input = void
    export type Output = { [key in string]: ObjectViewModel[] }
}

export const getHomeObjects = async (input: IGetHomeObjects.Input): Promise<IGetHomeObjects.Output> => {
    const url = apiRoutes.v1.services + '/homeHighlights'
    const response = await api_.get<IGetHomeObjects.Output>(url)
    return response.data
}