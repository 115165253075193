import { apiRoutes, api_ } from "config";
import { SubContentInputModel, SubContentViewModel } from "models";

export namespace ICreateSubContent {
    export type Input = SubContentInputModel;
    export type Output = SubContentViewModel;
}

export const createSubContent = async (input: ICreateSubContent.Input): Promise<ICreateSubContent.Output> => {
    const url = apiRoutes.v1.subContent;
    
    const response = await api_.post<ICreateSubContent.Output>(url, input);
    return response.data;
}