export enum OrderStatus {
    Pending  = "P",
    Finished = "F",
    Canceled = "C",
    Rejected = "R"
}

export const orderStatusMap = {
    [OrderStatus.Pending]: 'Pendente',
    [OrderStatus.Finished]: 'Pago',
    [OrderStatus.Canceled]: 'Cancelado',
    [OrderStatus.Rejected]: 'Rejeitado'
}