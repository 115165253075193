import { Box, Container, HorizontalTable, Loading } from "components";
import { OrderStatus, cardMethodMap, orderStatusMap } from "enums";
import { useMask, useService } from "hooks";
import { PaymentOrderViewModel } from "models";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getOrders } from "services_/Payment";
import './style.css'
import OrderDetails from "./OrderDetails";

export default function Orders() {
	const [orders, setOrders] = useState<PaymentOrderViewModel[]>([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState<PaymentOrderViewModel>(null);

	const {
		handle: handleGetOrders,
		isLoading: loadingOrders,
		error: ordersError
	} = useService({
		service: () => getOrders(),
		onLoading: () => toast.loading('Buscando pedidos...', { duration: null }),
		onSuccess: () => toast.dismiss(),
		onError: (error) => {
			toast.dismiss()
			toast.error('Erro ao buscar os pedidos!', { duration: 15000 , position: 'top-center'})
		}
	})

	const LOADING_PAGE = loadingOrders

	useEffect(() => {
		handleGetOrders().then(res => setOrders(res))
	}, [])

	return (
		<>
			{LOADING_PAGE && <Loading.Big />}
			{
				!LOADING_PAGE && (
					<Container.Fluid>
						<div id="corpo">
							<Box
								title="Minhas Compras"
							>
								<HorizontalTable title="Pedidos">
									{
										orders?.map((order, index) => (
											<HorizontalTable.tr key={index}>
												<HorizontalTable.td> {`Pedido #${++index}`}</HorizontalTable.td>
												<HorizontalTable.td>
													{
														new Date(order.date).toLocaleDateString()
													}
												</HorizontalTable.td>
												<HorizontalTable.td>
													{
														order.value
															.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
													}
												</HorizontalTable.td>
												<HorizontalTable.td>
													{
														`${cardMethodMap[order.payment.card_method]}
														- ${order.payment.card_flag[0].toUpperCase() + order.payment.card_flag.substring(1)} ${order.payment.card_number.substring(10)}`

													}
												</HorizontalTable.td>
												<HorizontalTable.td> {orderStatusMap[order.status]}</HorizontalTable.td>
												<HorizontalTable.td
													style={{ paddingLeft: '30px' }}
												>
													<button 
														className="btn-info-order"
														onClick={() => {
															setSelectedOrder(order)
															setShowModal(true)
														}}
													>
														<i className="uil uil-info-circle"></i>
													</button>
												</HorizontalTable.td>
											</HorizontalTable.tr>
										))
									}
								</HorizontalTable>
							</Box>
						</div>
					</Container.Fluid>
				)
			}
			<OrderDetails 
				show={showModal}
				order={selectedOrder}
				title='Detalhes do Pedido'
				onClose={() => setShowModal(false)}
			/>
		</>
	)
}