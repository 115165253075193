import { apiRoutes, api_ } from "config";
import { TJwt } from "types";

export namespace IAdminLogin {
    export type Input = { email: string; password: string };
    export type Output = TJwt.Token;
}

export const adminLogin = async (input: IAdminLogin.Input): Promise<IAdminLogin.Output> => {
    const url = `${apiRoutes.v1.admin}/login`;
    const response = await api_.post<IAdminLogin.Output>(url, input);

    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('profileSelected', 'ADMIN');

    return response.data;
}