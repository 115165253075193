export const configSlide1 = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: (480 + 1),
        settings: {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1
        }
      },
      {
        breakpoint: (640 + 1),
        settings: {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToScroll: 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: (768 + 1),
        settings: {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToScroll: 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: (1024 + 1),
        settings: {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToScroll: 3,
          slidesToShow: 3
        }
      },
      {
        breakpoint: (1366 + 1),
        settings: {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToScroll: 3,
          slidesToShow: 3
        }
      }
    ]
}
export const configSlide1Small = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 3000,
  dots: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  speed: 500,
  responsive: [
    {
      breakpoint: (480 + 1),
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    },
    {
      breakpoint: (640 + 1),
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: (768 + 1),
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    },
    {
      breakpoint: (1024 + 1),
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToScroll: 4,
        slidesToShow: 4
      }
    },
    {
      breakpoint: (1366 + 1),
      settings: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToScroll: 4,
        slidesToShow: 4
      }
    }
  ]
}


export const configSlide2 = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 3000,
  dots: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 2,
  speed: 500,
  responsive: [
    {
      breakpoint: (480 + 1),
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    },
    {
      breakpoint: (640 + 1),
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: (768 + 1),
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: (1024 + 1),
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    },
    {
      breakpoint: (1366 + 1),
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 2,
        slidesToShow: 2
      }
    }
  ]
}

export const configSlide3 = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
}