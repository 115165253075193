import { useState } from "react"

type Return = {
    showHomeSidebar : boolean
    setHomeSidebar: any
    showHomeSidebarModules: any
    handleHomeSidebarModuleChange: Function
}

export const useSidebar = (): Return => {
    const [showHomeSidebar, setHomeSidebar] = useState(false)
    const [showHomeSidebarModules, setShowHomeSidebarModules] = useState({})

    const handleHomeSidebarModuleChange = (id: number): void => {
        let newShowHomeSidebarModule = { ...showHomeSidebarModules }
        newShowHomeSidebarModule[id] = newShowHomeSidebarModule[id] ? false : true

        setShowHomeSidebarModules(newShowHomeSidebarModule)
    }

    return {showHomeSidebar, setHomeSidebar ,showHomeSidebarModules, handleHomeSidebarModuleChange}
}