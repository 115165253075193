import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Form, Pagination as BootstrapPagination, Spinner } from "react-bootstrap";
import "./style.css";
import { ButtonArea, Loading, Pagination } from "components";
import { PaginationContext, PaginationProvider } from "contexts";
import { ClassViewModel, SubscriptionViewModel } from "models";
import { useService } from "hooks";
import { getObjectClassSubscribers, updateManyProgress } from "services_/Class";
import toast, { Toaster } from "react-hot-toast";
// import {TClass } from 'types/Class'
type Props = {
  setManageSubscriptions: (value: boolean | " ") => void;
  selectedClass: ClassViewModel;
};

type pagination = {
  active: number;
  numPages: number;
};

enum options {
  conclusion = "C",
  certified = "EC",
}
export default function ClassSubscriptions({ setManageSubscriptions, selectedClass }: Props) {
  //variables
  const { active, numPages, setActive, pagesToShow, setPagesToShow, setNumPages } = useContext(PaginationContext);
  const [page, setPage] = useState<pagination>({ active: 1, numPages: 10 });
  const [alunos, setAlunos] = useState<SubscriptionViewModel[]>([]);

  const [selectedAlunos, setSelectedAlunos] = useState<string[]>([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [option, setOption] = useState<options | "">("");

  const filteredAlunos =
    option != ""
      ? option === options.certified
        ? alunos.filter((aluno) => aluno.finished === true && !aluno.certificate)
        : alunos
      : alunos;

  //api
  const {
    isLoading: loadingSubscribers,
    error: subscribersError,
    handle: handleGetSubscribers,
  } = useService({
    service: () =>
      //page: page.active
      getObjectClassSubscribers({
        classId: selectedClass.id,
        hasFinished: option === options.conclusion,
        page: page.active,
        size: 8,
      }),
    onLoading: () => toast.loading("Carregando lista de inscritos...", { duration: null }),
    onSuccess: () => toast.dismiss(),
    onError: () => toast.error("Erro ao carregar lista de inscritos.", { duration: 15000 , position: 'top-center'}),
  });

  const {
    isLoading: updatingProgress,
    error: updateProgressError,
    handle: handleUpdateProgress,
  } = useService({
    service: () => updateManyProgress({ classId: selectedClass.id, userIds: selectedAlunos }),
    onLoading: () => toast.loading("Atualizando progresso dos alunos...", { duration: null }),
    onSuccess: () => {
      toast.dismiss();
      const updatedAlunos = alunos?.map((aluno) => {
        if (option === options.conclusion && selectedAlunos.includes(aluno.userId)) {
          return { ...aluno, finished: true };
        } else if (option === options.certified && selectedAlunos.includes(aluno.userId)) {
          return { ...aluno, certified: true };
        }
        return aluno;
      });

      setAlunos(updatedAlunos);
      setSelectedAlunos([]);
      setIsSelecting(false);
      setOption("");
    },
    onError: () => toast.error("Erro ao atualizar progresso dos alunos.", { duration: 15000 , position: 'top-center'}),
  });

  const PAGE_LOADING = loadingSubscribers || updatingProgress;

  //functions
  const toggleSelecting = () => {
    setIsSelecting(true);
    setSelectedAlunos([]);
  };

  const toggleAlunoSelection = (aluno) => {
    if (selectedAlunos.includes(aluno)) {
      setSelectedAlunos(selectedAlunos.filter((a) => a !== aluno));
    } else {
      setSelectedAlunos([...selectedAlunos, aluno]);
    }
  };

  const cancelSelection = () => {
    setOption("");
    setIsSelecting(false);
    setSelectedAlunos([]);
  };

  // const saveSelection = () => {
  //   const updatedAlunos = alunos?.map((aluno) => {
  //     if (option === options.conclusion && selectedAlunos.includes(aluno.userId)) {
  //       return { ...aluno, finished: true };
  //     } else if (option === options.certified && selectedAlunos.includes(aluno.userId)) {
  //       return { ...aluno, certified: true };
  //     }
  //     return aluno;
  //   });

  //   handleUpdateProgress();

  //   // colocar na resposta da API
  //   setAlunos(updatedAlunos);
  //   setSelectedAlunos([]);
  //   setIsSelecting(false);
  //   setOption("");
  // };

  //loads
  useEffect(() => {
    setActive(7);
    setNumPages(20);
    setPagesToShow(5);
  }, []);

  useEffect(() => {
    //status para render evita chamadas sem necessidade (quando clicar pra ver exibir a turma ao inves de chamar sempre)
    handleGetSubscribers().then((res) => {
      setAlunos(res);
    });
  }, [selectedClass.id]);

  return (
    <>
      <div className="row btn-voltar" onClick={() => setManageSubscriptions(false)}>
        <div className="col-1 uil uil-angle-left arrow" />
        <div className="col-1 voltar">voltar</div>
      </div>

      <div className="row subscription-buttons">
        {!loadingSubscribers && (
          <div className={`class-info-area col${filteredAlunos?.length ? "-sm-8" : ""} row`}>
            <div className="col-sm-4">
              Turma: <span className="turma">{selectedClass.title}</span>
            </div>
            <div className="col-sm-4">
              Status:{" "}
              <span className="turma">
                {selectedClass.expired ? "Encerrada" : selectedClass.statusBool ? "Aberta" : "Fechada"}
              </span>
            </div>
            <div className="col-sm-4">
              Inscrições: <span className="turma">{filteredAlunos?.length}</span>
            </div>
          </div>
        )}
        {!!filteredAlunos.length && !loadingSubscribers && !!!subscribersError && (
          <>
            <div className="col">
              <button
                className={`botaoOption ${option == options.conclusion && "active"}`}
                onClick={() => {
                  toggleSelecting();
                  setOption(options.conclusion);
                }}
              >
                Definir Conclusão
              </button>
            </div>

            {/* <div className="col">
              <button
                className={`botaoOption ${option == options.certified && "active"}`}
                onClick={() => {
                  toggleSelecting();
                  setOption(options.certified);
                }}
              >
                Emitir Certificados
              </button>
            </div> */}
          </>
        )}
      </div>

      {!loadingSubscribers && !!!subscribersError && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {isSelecting && (
                <th>
                  <Form.Check
                    type="checkbox"
                    checked={selectedAlunos.length === filteredAlunos.length}
                    disabled={PAGE_LOADING}
                    onClick={(e) =>
                      selectedAlunos.length !== filteredAlunos.length
                        ? setSelectedAlunos([...filteredAlunos?.map((aluno) => aluno.userId)])
                        : setSelectedAlunos([])
                    }
                    readOnly
                  />
                </th>
              )}
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              {/* <th>Data de Inscrição</th> */}
              <th>Status</th>
              <th>Certificado</th>
              <th>Pagamento</th>
            </tr>
          </thead>
          <tbody>
            {filteredAlunos?.map((aluno: SubscriptionViewModel) => (
              <tr
                key={aluno.userId}
                onClick={() => isSelecting && toggleAlunoSelection(aluno.userId)}
                style={{
                  backgroundColor: selectedAlunos.includes(aluno.userId) ? "lightblue" : "",
                }}
              >
                {isSelecting && (
                  <td>
                    <Form.Check type="checkbox" checked={selectedAlunos.includes(aluno.userId)} readOnly />
                  </td>
                )}
                <td>{aluno?.user?.name}</td>
                <td>{aluno?.user.email}</td>
                <td>{aluno?.user?.userInfo?.phone}</td>
                {/* <td>{aluno?.createdAt?.toDateString()}</td> */}
                <td>{aluno?.finished ? "Concluído" : "Em andamento"}</td>
                <td>
                  {!aluno?.finished && !aluno.certificate && "Não concluído"}
                  {aluno?.finished && !aluno.certificate && "Emissão Pendente"}
                  {aluno?.finished && aluno.certificate && "Emitido"}
                </td>
                <td>{aluno?.paymentStatus === 'P' ? 'Pendente' : 
                     aluno?.paymentStatus === 'F' ? 'Finalizado' :
                     aluno?.paymentStatus === 'C' ? 'Cancelado' :
                     aluno?.paymentStatus === 'R' && 'Rejeitado'}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!filteredAlunos.length && !loadingSubscribers && !!!subscribersError && (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>Não há inscrições nesta turma.</div>
      )}
      {!!subscribersError && (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>Erro ao carregar inscritos</div>
      )}
      {loadingSubscribers && <Loading.Small />}
      {isSelecting && (
        <div className="selection-count">
          {selectedAlunos.length ? selectedAlunos.length + " selecionado(s)" : "Nenhum selecionado"}
        </div>
      )}

      {/* <Pagination /> */}

      {isSelecting && (
        <div className="area-salvar">
          <button
            className={`botaoOption salvar active ${!selectedAlunos.length || PAGE_LOADING ? "disabled" : ""}`}
            disabled={!selectedAlunos.length || PAGE_LOADING}
            onClick={handleUpdateProgress}
          >
            {PAGE_LOADING ? <Spinner animation="border" size="sm" /> : "Salvar"}
          </button>

          <button
            className={`botaoOption ${PAGE_LOADING ? "disabled" : ""}`}
            disabled={PAGE_LOADING}
            onClick={cancelSelection}
          >
            Cancelar
          </button>
        </div>
      )}

      {/* <Toaster position="bottom-left"/> */}
    </>
  );
}
