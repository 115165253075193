import { useAppSelector, useForm, useMask, useService } from "hooks";
import Button from "components/Button";
import ButtonArea from "components/ButtonArea";
import { useNavigate } from "react-router-dom";
import { Accordion_, Loading, Notification } from "components";
import { useEffect, useContext, useState, createContext } from "react";
import toast from "react-hot-toast";
import { removeCurrencyMask, windowConfirm } from "Functions";
import { MaskTypes, ObjectStatus } from "enums";
import { ObjectAnalysisContext, ObjectRegisterContext } from "contexts";
import { ObjectInputModel, ObjectViewModel } from "models";
import { createObject, deleteObject, updateObject } from "services_/Object";
import { Form, SelectServices } from "./components";
import { ISaveCredentials } from "services_/CourseIntegration";
import { SESSION_STORAGE_KEYS } from "constants_";
import ModalDeletionConfirmation from "components/ModalDeletionConfirmation";
import { IAnalyzeObject, analyzeObject } from 'services_/Admin';
import ModalEditConfirmation from "components/ModalEditConfirmation";


type ContextProps = {
  object: ObjectViewModel;
  objectInputData: ObjectInputModel;
  objectInputErrors: Partial<Record<keyof ObjectInputModel, string>>;
  accordionsErrors: Record<number, boolean>;
  openedAccordion: number;
  providerInputData: ISaveCredentials.Input;
  sendingAnalysis      : boolean;

  LOADING_PAGE: boolean;
  VIEW_ONLY: boolean;

  setObjectInputData: (data: ObjectInputModel) => void;
  setObjectInputErrors: (a: Partial<Record<keyof ObjectInputModel, string>>) => void;
  setAccordionsErrors: (hasError: Record<number, boolean>) => void;
  setOpenedAccordion: (index: number) => void;
  setProviderInputData: (data: ISaveCredentials.Input) => void;

  handleChangeValue: (key: keyof ObjectInputModel, action?: any) => (e: any) => void;
  handleChangeMultiSelectValue: (key: keyof ObjectInputModel) => (e: any) => void;
  handleChangeValueWithMask: (key: keyof ObjectInputModel, mask: MaskTypes, action?: any) => (e: any) => void;
  handleChangeFile: (key: keyof ObjectInputModel) => (e: any) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleSubmitAnalysis : (analysis: Partial<IAnalyzeObject.Input>) => void;
};

export const DataContext = createContext<ContextProps | undefined>(undefined);

export var initialState;
type InitialInfosProps = {
  onNext: () => void;
};

export default function InitialInfos({ onNext }: InitialInfosProps) {

  const { isLoading: loadingObjectValues } = useAppSelector((state) => state.globalValues);
  const { object, setObject, loadingObject } = useContext(ObjectRegisterContext);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openedAccordion, setOpenedAccordion] = useState<number>(0);
  const [accordionsErrors, setAccordionsErrors] = useState<Record<number, boolean>>({ 0: false, 1: false });
  const [providerInputData, setProviderInputData] = useState<ISaveCredentials.Input>({} as ISaveCredentials.Input);

  const { handleMask } = useMask();
  const goTo = useNavigate();

  const { handleSubmitAnalysis, sendingAnalysis }  = useContext(ObjectAnalysisContext)

  const {
    data: objectInputData,
    errors: objectInputErrors,

    setData: setObjectInputData,
    setErrors: setObjectInputErrors,

    handleChangeValue,
    handleChangeMultiSelectValue,
    handleChangeValueWithMask,
    handleChangeFile,
    handleSubmit,
  } = useForm<ObjectInputModel>({
    validations: {
      title: { required: true },
      description: { required: true },
      file: { required: !object?.id },
      modality: { required: true },
      typeId: { required: true },
      areaId: { required: true },
      price:  { minValue: 2 }, // Nova validação de preço
    },
    onSubmit: () => {},
  });

  const { isLoading: creatingObject, handle: handleCreateObject } = useService<ObjectViewModel>({
    service: () =>
      createObject({
        ...objectInputData,
        areaId: objectInputData.areaId[0],
        provider: providerInputData?.provider,
        providerData: providerInputData,
      }),
    onLoading: () => toast.loading("Criando recurso...", { duration: 0 }),
    onSuccess: (res) => {
      toast.dismiss();
      toast.success("Recurso criado com sucesso.");

      sessionStorage.removeItem(SESSION_STORAGE_KEYS.COURSE_INTEGRATION_TEMP_PROVIDER_DATA);
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.OBJECT_TEMP_DATA);

      setOpenedAccordion(0);

      goTo(`/cadastro/recurso/${res.id}`);
      onNext();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.response?.data?.message, { duration: 15000 , position: 'bottom-center'});
    },
  });

// Update
  const { isLoading: updatingObject, handle: handleUpdateObject } = useService<ObjectViewModel>({
      service: () =>
          updateObject({
              ...objectInputData, 
              price: String(removeCurrencyMask(objectInputData.price)),
              status: ObjectStatus.Creating
          }),
    onLoading: () => toast.loading("Atualizando recurso...", { duration: 0 }),
    onSuccess: (res) => {
      toast.dismiss();
      toast.success("Recurso atualizado com sucesso.");
      onNext();
      setObject(res);
      
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.response?.data?.message, { duration: 15000 , position: 'bottom-center'});
    },
  }); 

// Delete
  const { isLoading: deletingObject, handle: handleDeleteObject } = useService({
    service: () => deleteObject({ id: object?.id }),
    onLoading: () => toast.loading("Deletando recurso...", { duration: 0 }),
    onSuccess: (res) => {
      toast.dismiss();
      toast.success("Recurso deletado com sucesso.");

      goTo("/home");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.response?.data?.message, { duration: 15000 , position: 'bottom-center'});
    },
  });

  const toggleAccordion = (index: number) => {
    if (index === openedAccordion) setOpenedAccordion(-1);
    else setOpenedAccordion(index);
  };

  const handleSubmitFn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const hasErrors = Object.values(accordionsErrors).some((error) => !!error);
    if (hasErrors) {
      if (accordionsErrors[0]) setOpenedAccordion(0);
      else setOpenedAccordion(Object.values(accordionsErrors).findIndex((error) => !!error));
      return toast.error(
        `Preencha todos os campos obrigatórios ${
          accordionsErrors[0] ? "das Informações Iniciais" : "do Serviço de Integração"
        } .`
        , { duration: 15000 , position: 'top-center'}
      );
    } else !object?.id ? handleCreateObject() : handleUpdateObject();
  };

  const LOADING_PAGE = creatingObject || loadingObjectValues || loadingObject || updatingObject || deletingObject;
  const VIEW_ONLY =
    !!object?.status && !ObjectStatus.isCreating(object?.status) && !ObjectStatus.isDisapproved(object?.status);

  const contextValues: ContextProps = {
    object,
    objectInputData,
    objectInputErrors,
    accordionsErrors,
    openedAccordion,
    providerInputData,
    sendingAnalysis,

    LOADING_PAGE,
    VIEW_ONLY,

    setObjectInputData,
    setObjectInputErrors,
    setAccordionsErrors,
    setOpenedAccordion,
    setProviderInputData,

    handleChangeValue,
    handleChangeMultiSelectValue,
    handleChangeValueWithMask,
    handleChangeFile,
    handleSubmit,
    handleSubmitAnalysis
  };
  /* TODO: Tratar erros caso o recurso não seja do parceiro ou não exista.
   */
  // const handleResponseErrorMessage = (error: any) => {
  //     if (!error) return

  //     let message: string;

  //     const is404 = error.status === 404
  //     const is401 = error.status === 401

  //     if (is404 || is401) {
  //         if (404) message = 'Recurso não Encontrado.'
  //         if (401) message = errorData.data.message
  //         goTo('/cadastro/recurso', { replace: true })
  //     } else message = errorData.data.message

  //     toast.error(message)
  // }

  useEffect(() => {
    if (object) {
      initialState={...object};
      setObjectInputData({
        ...object,
        areaId: object.area?.id,
        typeId: object.type?.id,
        file: null,
        price: handleMask(String(object.price), "currency"),
      });
    }
  }, [object?.id]);

  return (
    <>
      {LOADING_PAGE && <Loading.Small />}

      <DataContext.Provider value={contextValues}>
        <form onSubmit={handleSubmitFn}>
          {!LOADING_PAGE && (
            <Accordion_.Container size="lg">
              <Accordion_>
                {
                  <>
                    <Accordion_.Item key={0} disabled={LOADING_PAGE} opened={openedAccordion === 0}>
                      <Accordion_.Header handleClick={() => toggleAccordion(0)}>Informações Iniciais</Accordion_.Header>
                      <Accordion_.Body>
                        <Form />
                      </Accordion_.Body>
                    </Accordion_.Item>

                    <Accordion_.Item
                      disabled={(!object?.provider && !!object?.id) || LOADING_PAGE}
                      key={1}
                      opened={openedAccordion === 1}
                    >
                      <Accordion_.Header handleClick={() => toggleAccordion(1)}>
                        Integração com serviços Externos (Opcional)
                      </Accordion_.Header>
                      <Accordion_.Body>
                        <SelectServices />
                      </Accordion_.Body>
                    </Accordion_.Item>
                  </>
                }
              </Accordion_>
            </Accordion_.Container>
          )}
          {!!VIEW_ONLY && (
            <ButtonArea>
              <Button
                className="update-object-button"
                type="button"
                loading={updatingObject}
                disabled={LOADING_PAGE || updatingObject}
                onClick={() => setShowEditModal(true)}

              >Editar Recurso
              </Button>
            </ButtonArea>
          )}

          {!VIEW_ONLY && (
            <ButtonArea>
              <Button type="submit" loading={creatingObject || updatingObject} disabled={LOADING_PAGE}>
                {object?.id ? "Salvar" : "Cadastrar"} Recurso
              </Button>
              {object?.id && (
                <Button
                  type="button"
                  className="exclude"
                  loading={deletingObject}
                  disabled={LOADING_PAGE}
                  onClick={() => setShowModal(true)}
                >
                  <i className="uil uil-trash"></i>
                  Excluir Recurso
                </Button>
              )}
            </ButtonArea>
          )}
        </form>
        <ModalDeletionConfirmation 
          itemName={object?.title} 
          showModal={showModal}
          confirmDeletion={handleDeleteObject}
          setShowModal={setShowModal}
        />
        <ModalEditConfirmation
          itemName={object?.title}
          showModal={showEditModal}
          confirmEdition={handleUpdateObject}
          setShowModal={setShowEditModal}
        
        />
        <Notification position="bottom-left" />
      </DataContext.Provider>
    </>
  );
}
