import { apiRoutes, api_ } from "config";
import { ObjectInputModel, ObjectViewModel } from "models";
import { ISaveCredentials, saveCredentials } from "services_/CourseIntegration";
import { deleteObject } from "./DeleteObject";

export namespace ICreateObject {
    export type Input = ObjectInputModel & { providerData?: ISaveCredentials.Input };
    export type Output = ObjectViewModel;
}

export const createObject = async (data: ICreateObject.Input): Promise<ICreateObject.Output> => {
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("file", data.file);
    formData.append("modality", data.modality);
    formData.append("price", String(data.price));
    formData.append("typeId", data.typeId);
    formData.append("areaId", data.areaId);
    formData.append("provider", data.provider);

    const url = apiRoutes.v1.objects;
    const response = await api_.post<ICreateObject.Output>(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    
    if (data.provider) {
        await saveCredentials({ 
            provider: data.provider, ...data.providerData, objectId: response.data.id
        }).catch((error) => { 
            deleteObject({ id: response.data.id })
            throw error;
        });
    }
    
    return response.data;
};