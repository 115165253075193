import '../../../assets/css/loading.css';

type Props = {
    loading?: boolean;
}

export default function SmallLoading ({ loading = true}: Props) {
    if (loading) {
        return (
            <div className="loading-small">
                <div className="loadingio-spinner-rolling-xhghhgigys8">
                    <div className="ldio-83ypp57xcot">
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}