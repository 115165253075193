import { createContext, useEffect, useState } from 'react'
import ContentModal from './ContentModal';
import { ContentViewModel, SubContentViewModel } from 'models';

export type ContentOrSubContent = Required<Pick<ContentViewModel, 'id' | 'title'>>
    & Partial<Pick<ContentViewModel, 'subContents' | 'objectId' | 'description'>>
    & Partial<Pick<SubContentViewModel, 'contentId'>>

type ContentContextProps = {
    type: 'content' | 'subContent'
    action: 'create' | 'edit'
    selectedContent?: Pick<ContentViewModel, 'id' | 'title' | 'description'> &
    Partial<Pick<SubContentViewModel, 'contentId'>>
}

type ContextProps = {
    openContentModal: (props: ContentContextProps) => void;
    contentType?: ContentContextProps['type'];
    contentAction?: ContentContextProps['action'];
    selectedContent?: ContentContextProps['selectedContent'];
}

type ProviderProps = {
    children: React.ReactNode;
}

export const ContentRegisterContext = createContext<ContextProps>({} as ContextProps)
export const ContentRegisterProvider = ({ children }: ProviderProps) => {
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState<ContentContextProps['type']>(null)
    const [action, setAction] = useState<ContentContextProps['action']>(null)
    const [selectedContent, setSelectedContent] = useState<ContentContextProps['selectedContent']>(null)

    const openModal = ({ action, type, selectedContent }: ContentContextProps) => {
        setType(type)
        setAction(action)
        setSelectedContent(selectedContent)
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
        setType(null)
        setAction(null)
        setSelectedContent({} as ContentContextProps['selectedContent'])
    }

    return (
        <ContentRegisterContext.Provider value={{
            openContentModal: openModal,
            contentType: type,
            contentAction: action,
            selectedContent
        }}>
            {children}

            <ContentModal
                onClose={closeModal}
                show={showModal}
            />
        </ContentRegisterContext.Provider>
    )
}