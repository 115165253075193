import { useAppDispatch, useAppSelector } from 'hooks';
import { useState, useEffect, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { loadGlobalValues } from 'slices/GlobalValues';
import { Header_, Sidebar, Steps } from './components';
import { UserContext } from 'contexts';

export default function PublicApp() {
    const [showHomeSidebar, setHomeSidebar] = useState(false)
    const [showHomeSidebarModules, setShowHomeSidebarModules] = useState({})
    const { handleAdditionalInfo } = useContext(UserContext)

    const { areas, types } = useAppSelector((state) => state.globalValues)
    const dispatch = useAppDispatch()
    const { pathname } = useLocation()

    const handleHomeSidebarModuleChange = (id: number) => {
        let newShowHomeSidebarModule = { ...showHomeSidebarModules }
        newShowHomeSidebarModule[id] = newShowHomeSidebarModule[id] ? false : true

        setShowHomeSidebarModules(newShowHomeSidebarModule)
    }

    useEffect(() => {
        const emptyAreas = areas.length === 0
        const emptyTypes = types.length === 0

        if (emptyAreas || emptyTypes) dispatch(loadGlobalValues())
    }, [])

    useEffect(() => { handleAdditionalInfo() }, [pathname])

    return (
        <div className="App">
            <Header_ setHomeSidebar={setHomeSidebar} />
            <Sidebar
                showHomeSidebar={showHomeSidebar}
                setHomeSidebar={setHomeSidebar}
                showHomeSidebarModules={showHomeSidebarModules}
                handleHomeSidebarModuleChange={handleHomeSidebarModuleChange}
            />
            
            <Outlet />
            <Steps.AuthSteps />
        </div>
    );
}

