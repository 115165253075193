import { PD_UsersInputModel, UserInputModel, UserViewModel } from "models";
import { authenticate } from "services_/Auth";
import { saveAdditionalInfo } from "./SaveAdditionalInfo";
import { ISaveUser, saveUser } from "./SaveUser";

export namespace ISavePartner {
    export type Input = { partnerInfo: UserInputModel, additionalInfo: PD_UsersInputModel };
    export type Output = void; // TODO: void temporario
}

export const savePartner = async ({ additionalInfo, partnerInfo }: ISavePartner.Input): Promise<ISavePartner.Output> => {
    const userWasSaved = !!localStorage.getItem('accessToken')
    
    if (!userWasSaved) {
        const userSaved = await saveUser({ userInfo: partnerInfo });
        localStorage.setItem('accessToken', userSaved.accessToken);
    }
    await saveAdditionalInfo(additionalInfo);
}