import { Navigate } from "react-router-dom";
import { UserTypes } from "enums";

type Props = {
    omit?: {
        for: UserTypes[];
        redirect?: { [type in UserTypes]?: string };
    }
    element: JSX.Element;
}

export default function ControlRoute({ element, omit }: Props) {
    const storageToken = localStorage.getItem('accessToken');
    const storageProfileSelected = localStorage.getItem('profileSelected');
    
    const REDIRECT_TO = omit && omit?.redirect ? omit.redirect[omit.for.find(t => t === storageProfileSelected)] : '/';

    let SHOW_ELEMENT = true;
    if (omit && omit.for.includes(storageProfileSelected as UserTypes)) SHOW_ELEMENT = false;

    return SHOW_ELEMENT ? element : <Navigate to={REDIRECT_TO} />
}