import Forma from 'assets/images/formaInicio.png';
import { Card, Depositions, HomeBanner, HomeFooter, Loading, Notification, PartnerBanner, Partners, Slides } from "components";
import ProfileSelect from "components/templates/app/Modals/Login/ProfileSelect";
import { HighlightedObjectsContext, UserContext } from "contexts";
import { UserTypes } from "enums";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getHomeObjects } from "services_/Utils";
import { setHomeObjects } from "slices/GlobalObjects";
import './style.css';
import { openSteps } from 'slices/AuthStepsSlice';
import { IAuthSteps } from 'interfaces';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

type Props = {
    showProfilesSelector?: boolean
}

export default function Home({showProfilesSelector = false}: Props) {
    const { highlightedObjects, homeObjects } = useAppSelector(state => state.globalObjects);
    const { types } = useAppSelector(state => state.globalValues);
    const { loadingData } = useContext(HighlightedObjectsContext)
    const { handleSetUserInfoByToken, userInfo, handleSelectProfile, profileSelected, handleLogout } = useContext(UserContext)
    const [params, setParams] = useSearchParams()
	const [showProfiles, setShowProfiles] = useState(showProfilesSelector);


    const { 
        isLoading: loadingHomeObjects, 
        error: homeObjectsError,
        handle: handleHomeObjects
    } = useService({
        service: getHomeObjects,
        onError: () => console.error('Erro no servidor ao buscar os recursos da home!')
    })

    const dispatch = useAppDispatch()
    const goTo = useNavigate()

    const LOADING_HOME = loadingHomeObjects || loadingData

    useEffect(() => {
        const access_token = params.get('access_token')

        if (Object.keys(homeObjects)?.length === 0) handleHomeObjects().then((res) => dispatch(setHomeObjects(res)))

        /* Caso o usuário venha pelo login social, irá cair neste if.
         * Se o token de acesso existir, será redirecionado para a página de home. 
         */
        if (access_token) {
            if (access_token === 'conflict') {
                toast.error('Já existe um usuário com esse email.', { duration: 15000 , position: 'top-center'})
                return
            }
            params.delete('access_token')
            setParams(params)
            localStorage.setItem('accessToken', access_token)
            console.log(1)
            handleSetUserInfoByToken(access_token)
        }
    }, [])

    useEffect(() => {
		if (userInfo?.type && !profileSelected) {
            if (userInfo?.type === UserTypes.Apprentice_Partner) dispatch(openSteps({ step: IAuthSteps.Steps.LOGIN }))
            else handleSelectProfile(UserTypes.Apprentice, false) 
        } 
	}, [userInfo?.type])

    return (
        <>
            <HomeBanner />

            {
                !LOADING_HOME ? (
                    highlightedObjects?.length > 0 && (
                        <Slides.Large
                            type='normal-4'
                            title='Destaques de Vendas'
                            description='Estes são os recursos que estão em alta.'
                            icon='uil-award'
                        >
                            {
                                highlightedObjects.map((obj, index) => (
                                    <Card
                                        onclick={() => goTo(`/recurso/${obj.id}`)}
                                        title={obj.title}
                                        text={obj.type.name}
                                        image={obj.image}
                                        type='caixa1 comBordas'
                                        key={index}
                                    />
                                ))
                            }

                        </Slides.Large>
                    )
                ) : (
                    <div style={{ minHeight: '500px', display: 'flex', justifyContent: 'center' }}>
                        <Loading.Small />
                    </div> 
                )
            }

            <div className="inicioMaisCaixas">
                <div className="forma">
                    <img src={Forma} alt="" />
                </div>
                {
                    !LOADING_HOME ? (
                        !!homeObjects && Object.keys(homeObjects)?.length > 0 && (
                            types.map((type, index) => !!homeObjects[type.id]?.length && (
                                <Fragment key={index}>
                                    <Slides.Large
                                        type='normal-4'
                                        title={`${type.name}s`}
                                        textPlus={`Mais ${type.name}s`}
                                        icon='uil-graduation-cap'
                                        link={`/recursos?typeId=${type.id}`}
                                    >
                                        {
                                            homeObjects[type.id].map((obj, index) => {
                                                if (type.id === obj.type.id)
                                                    return (
                                                        <Card
                                                            onclick={() => goTo(`/recurso/${obj.id}`)}
                                                            title={obj.title}
                                                            text={obj.type.name}
                                                            image={obj.image}
                                                            type='caixa1 comBordas'
                                                            key={index}
                                                        />
                                                    )

                                            })
                                        }

                                    </Slides.Large>
                                </Fragment>
                            ))
                        )
                    ) : (
                        <div style={{ minHeight: '500px', display: 'flex', justifyContent: 'center' }}>
                            <Loading.Small />
                        </div>
                    )
                }
            </div>

            <PartnerBanner />

            <Depositions
                title='O que diz quem já utilizou a plataforma'
                description='Veja as opiniões de quem já foi usuário da nossa ferramenta.'>
                <Slides.Small
                    type='small'>
                    <Card
                        title='Cecilia dos Santos'
                        text='PaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.lestras'
                        image='https://via.placeholder.com/450x450'
                        type='caixa2'
                    />
                    <Card
                        title='Fábio R. Cardoso'
                        text='PaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.lestras'
                        image='https://via.placeholder.com/450x450'
                        type='caixa2'
                    />
                    <Card
                        title='Gilma Carmo'
                        text='PaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.lestras'
                        image='https://via.placeholder.com/450x450'
                        type='caixa2'
                    />
                    <Card
                        title='Lavínia Cardoso'
                        text='PaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.lestras'
                        image='https://via.placeholder.com/450x450'
                        type='caixa2'
                    />
                </Slides.Small>
            </Depositions>

            <Partners />
            <HomeFooter />
            <Notification position="bottom-left" />
            {/* <Steps.AuthSteps /> */}
            {showProfiles && 
                <>
                    <ProfileSelect />
                    <div className="col-xl-12 linhaLinks-centered button-voltar-profiles" onClick={()=> {setShowProfiles(!showProfiles); handleLogout()}}>
                        <strong>Voltar</strong>
                    </div>
                </>
            }
        </>
    )
}
