import { ObjectViewModel } from "models"
import { api_ } from "config"

export namespace IGetHighlightsObjects {
    export type Input = void
    export type Output = ObjectViewModel[]
}

export const getObjectHighlights = async (input: IGetHighlightsObjects.Input): Promise<IGetHighlightsObjects.Output> => {
    const url = '/v1/objectsHighlights'
    const response = await api_.get<IGetHighlightsObjects.Output>(url)
    return response.data
}