import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Provider } from 'react-redux';
import { store } from './Store';
import './assets/css/site.css'
import './assets/css/home.css'
import './assets/css/animate.css'
import './assets/css/internas.css'
import { AdminProvider, UserProvider } from 'contexts';
import Routes from './Routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <UserProvider>
      <AdminProvider>
        <Routes />
      </AdminProvider>
    </UserProvider>
  </Provider>
);