import Modal from "components/Modal";
import ButtonArea from "components/ButtonArea";
import Button from "components/Button";

interface IModalEditConfirmation {
    itemName: string,
    confirmEdition: () => void
    showModal: boolean,
    setShowModal: (show: boolean) => void,
}

const ModalEditConfirmation = ({ itemName, confirmEdition, showModal, setShowModal }: IModalEditConfirmation) => {
    const handleConfirmDeletion = () => {
        confirmEdition();
        setShowModal(false);
    }

    return (
        <Modal show={showModal} onClose={() => setShowModal(false)} isLoading={false}>
            <p>Confirmar edição do recurso <strong>{itemName}</strong>?</p>
            <p>Ao confirmar a edição, o conteúdo será sujeito a uma nova análise pelo sistema.</p>
            <ButtonArea className="text-center">
                <Button className="admin vermelho" type="submit" onClick={() => setShowModal(false)}>
                    <i className="uil uil-times-circle"></i> Cancelar
                </Button>
                <Button className="admin azul" type="button" onClick={handleConfirmDeletion}>
                    <i className="uil uil-check-circle"></i> Confirmar
                </Button>
            </ButtonArea>
        </Modal>
    );
};

export default ModalEditConfirmation;
