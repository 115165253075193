import { apiRoutes, api_ } from "config";

export namespace IDeleteClass {
    export type Input = { id: string };
    export type Output = void;
}

export const deleteClass = async (input: IDeleteClass.Input): Promise<IDeleteClass.Output> => {
    const url = `${apiRoutes.v1.class}/${input.id}`;
    await api_.delete(url);
}