import { useEffect, useState } from "react"
import { ObjectStatus } from "enums"
import { ApprovalItem } from "types"
import './style.css'

export default function ItemApprovalStatus({ analysisDate, observation, status }: Partial<ApprovalItem>) {

    const [title, setTile] = useState<string>('')
    const [color, setColor] = useState<string>('')
    const [icon, setIcon] = useState<string>('')

    const setItems = (title1, ico1, colo1) => {
        setTile(title1)
        setIcon(ico1)
        setColor(colo1)
    }

    useEffect(() => {
        ObjectStatus.isApproved(status) ? setItems("Recurso Aprovado.", 'check-circle', "approved")
            : ObjectStatus.isPending(status) ? setItems("Enviado para análise.", 'clock', "pending")
                : setItems("Recurso não aprovado após análise.", 'times-circle', "rejected")
    }, [status])

    return (
        <div className="col-xl-12">
            <div className="cardRecursos">
                <div className={"areaIcone " + color}>
                    <i className={"uil uil-" + icon}></i>
                </div>
                <div className={"areaDados " + (!ObjectStatus.isDisapproved(status) && 'noDescription')}>
                    <span>{title}</span>

                    {
                        observation && ObjectStatus.isDisapproved(status) &&
                        <div className='descricao'>
                            {observation}
                        </div>

                    }
                </div>

                <div className={"areaBotao " + (ObjectStatus.isDisapproved(status) && 'rejected')}>
                    { status !== ObjectStatus.Pending && `${new Date(analysisDate).toLocaleDateString('pt-br')} ${new Date(analysisDate).toLocaleTimeString('pt-br')}` }
                </div>
            </div>

        </div>
    )
}