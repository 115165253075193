import { Button, ButtonArea, Checkbox, Input } from "components"
import { EnumRegisterTypes, ObjectOptionsInput, ObjectRegisterOptionsContext } from "contexts"
import { Status } from "enums"
import { useForm } from "hooks"
import { useContext, useEffect, useState } from "react"
import './style.css'
import ModalDeletionConfirmation from "components/ModalDeletionConfirmation"


export default function RegisterArea() {
    const [showModal, setShowModal] = useState(false);
    const { registerType, selectedItem, handleSaveOption, handleUpdateOption, handleDeleteOption } = useContext(ObjectRegisterOptionsContext)

    const { data, errors, handleChangeValue, handleChangeCheckValue, handleSubmit, handleCleanForm, setData } = useForm<ObjectOptionsInput>({
        validations: {
            name: { required: true }
        },
        onSubmit: () => {
            if (selectedItem.id.match('tempId')) handleSaveOption(data)
            else handleUpdateOption({ ...data, id: selectedItem.id })
        }
    })

    const confirmDeletion = () => {
        handleDeleteOption(selectedItem?.id);
        handleCleanForm();
    }

    useEffect(() => {
        if (selectedItem.id !== '') setData({ name: selectedItem.name, statusBool: selectedItem.status === Status.Active, status: selectedItem.status })
    }, [selectedItem.id])

    return (
        <>
            <form className='col-xl-5 register-area' onSubmit={handleSubmit}>

                <div className='register-area-title'>
                    {
                        selectedItem.id === '' ? 'Nenhum item selecionado ou adicionado.' :
                            registerType === EnumRegisterTypes.AREA ? 'Informações da área de ensino.' : 'Informações do tipo de recurso.'
                    }
                </div>

                { selectedItem.id !== '' &&
                    <>
                        <div className="row">
                            <Input
                                name="title"
                                label={'Nome' + (registerType === EnumRegisterTypes.AREA ? ' da área.' : ' do tipo')}
                                placeholder={(registerType === EnumRegisterTypes.AREA ? 'Ex: Direito, Programação, etc.' : 'Ex: Curso, Palestra, etc.')}
                                type="text"
                                onChange={handleChangeValue('name')}
                                value={data.name}
                                messageError={errors.name}
                                col="12"
                            />
                        </div>

                        <div className="switch">
                            <Checkbox 
                                type="switch" 
                                label={'Ativar ' + (registerType === EnumRegisterTypes.AREA ? 'área' : 'tipo')}
                                checked={data.statusBool}
                                onChange={handleChangeCheckValue('statusBool')}
                            />
                        </div>

                        <ButtonArea className='linhaBotoes  user-register save-buttons-area'>
                            <Button
                                type="submit"
                                className="botao"
                            >
                                {!selectedItem?.id.match('tempId') ? 'Salvar' : 'Adicionar ' + (registerType === EnumRegisterTypes.AREA ? 'área' : 'tipo')}
                            </Button>
                            {
                                selectedItem?.id.match('tempId') && (
                                    <Button
                                        type="button"
                                        className="botao"
                                        onClick={handleCleanForm}
                                    >
                                        Cancelar
                                    </Button>
                                )
                            }

                            {
                                !selectedItem?.id.match('tempId') &&

                                <Button
                                    type="button"
                                    className="botao exclude"
                                    onClick={() => setShowModal(true)}>
                                    <i className="uil uil-trash"></i>
                                    Excluir
                                </Button>
                            }
                        </ButtonArea>
                    </>
                }
            <ModalDeletionConfirmation 
                itemName={selectedItem.name} 
                confirmDeletion={confirmDeletion}
                showModal={showModal}
                setShowModal={setShowModal}/>
            </form>
        </>
    )
}