import { Link } from 'react-router-dom'
import CaixaForma from '../../assets/images/caixa_forma_1.png'


type Props = {
    title: string
    text: string
    image: string
    type: 'caixa1' | 'caixa1 comBordas' | 'caixa2' | 'destaque'
    onclick?    : () => void
}

export default function Card({ title, text, image, type, onclick }: Props) {
    return (
        <div 
        className={['caixa1', 'caixa1 comBordas'].includes(type) ? "col-xl-12 px-3" : "col-xl-12 px-3 linha"}
        onClick={onclick}>
            <a href={void(0)} role='button' className={type}>
                <div className="foto">
                    <img className="capa" src={image} alt="imagem_cartao" />
                    {
                        ['caixa1', 'caixa1 comBordas'].includes(type) &&
                        <img className="forma" src={CaixaForma} alt="" />
                    }
                </div>
                <div className="textos">
                    <div className={['caixa1', 'caixa1 comBordas'].includes(type) ? 'area1' : "nome"}>
                        {title}
                    </div>
                    <div className={['caixa1', 'caixa1 comBordas'].includes(type) ? 'area2' : "texto"}>
                        {text}
                    </div>
                </div>
            </a>
        </div>
    )
}