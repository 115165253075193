import Select, {OnChangeValue, ActionMeta } from 'react-select';
import { useEffect, useState } from 'react'
import { TField } from "types";
import './style.css'

export type MultiSelectActions = 'remove' | 'append' | 'clear'
export type MultiSelectType = { value: string, label: string }
export type MultiSelectChange = { item: MultiSelectType, action?: MultiSelectActions }

type Props = {
    placeholder   : string
    data          : TField[]
    defaultValue? : TField[]
    messageError? : string
    values?       : MultiSelectType[]
    label?        : string
    limit?        : number
    isLoading?    : boolean
    disabled?     : boolean
    onChange?     : ({ item, action }: MultiSelectChange) => void
}

export default function MultiSelect ({ data, placeholder, defaultValue, values, limit=1, messageError, label, isLoading, disabled=false, onChange }: Props) {
    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    const handleMultiSelectChange = (
        selectedOptions: OnChangeValue<any, true>,
        actionMeta: ActionMeta<any>,
    ) => {
        if (actionMeta.action === 'remove-value') onChange({ item: actionMeta.removedValue, action: 'remove' })
        else if (actionMeta.action === 'select-option') onChange({ item: actionMeta.option, action: 'append' })
        else if (actionMeta.action === 'clear') onChange({ item: undefined, action: 'clear' })
        
        setSelectedOptions(selectedOptions as MultiSelectType[]);
    };

    const handleSelectChange = ({ value, label }: Omit<TField, 'text'>) => onChange({ item: { value, label }})

    useEffect(() => { if (values) setSelectedOptions(selectedOptions as MultiSelectType[]) }, [])

    return (
        <div className={`col-xl-12 linhaCampo ${ messageError ? 'withError' : '' }`}>
            {
                label &&
                <label className={`form-label ${ messageError && 'withError' }`}>{label}</label>
            }
            <Select
                isMulti={limit > 1}
                options={data.map((item, index) => {
                    return { value: item.value, label: item.text }
                })}
                defaultValue={defaultValue && defaultValue.map((item, index) => {
                    return { value: item.value, label: item.text }
                })}
                value={values}
                isOptionDisabled={() => (!!values?.length && values.length >= limit)}
                placeholder={placeholder}
                isLoading={isLoading}
                className={`multi-select ${limit === 1 ? 'single' : '' }`}
                onChange={limit > 1 ? handleMultiSelectChange : handleSelectChange}
                isDisabled={disabled}
            />
            <div className="message-error">
                { messageError }
            </div>
        </div>
    )
}