import { ReactNode } from 'react'

type Props = {
    title: string
    children: ReactNode
}

export default function Box({ title, children }: Props) {
    return (
        <div className="col-xl-12 areaCaixa">
            <div className="caixa">
                <div className="row">
                    <div className="col-xl-12 titulo">{ title }</div>

                    <div className="col-xl-12">
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )
}