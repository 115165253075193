import { apiRoutes, api_ } from "config"
import { SubscriptionViewModel } from "models"
import { Status } from "enums"
import {TClass} from "types/Class"
export namespace IGetObjectClassSubscribers {
    export type Input = { classId: string; hasFinished : boolean; size: number; page: number }
    export type Output= SubscriptionViewModel[]
}
export const getObjectClassSubscribers = async (data: IGetObjectClassSubscribers.Input): Promise<IGetObjectClassSubscribers.Output> => {
    const url = `${apiRoutes.v1.class}/${data.classId}/subscribers`
    const response = await api_.get<any>(url)

    const paymentStatus = response.data.ordersWithClasses.map((orders) => orders.status);
    const subscriptionViewModel = response.data.subscriptionsViewModel.map((subscription, index) => ({
        ...subscription,
        paymentStatus: paymentStatus[index]
    })); 

    return subscriptionViewModel;
}
