import { apiRoutes, api_ } from "config";
import { TJwt } from "types"

export namespace ILogin {
    export type Input = { email: string, password: string };
    export type Output = TJwt.Token;
}

export const login = async (input: ILogin.Input): Promise<ILogin.Output> => {
    const url = apiRoutes.v1.login;

    const response = await api_.post<ILogin.Output>(url, input);

    //TODO: REFATORAR QUANDO HOUVER MAIS DE UM LOGIN SOCIAL
    if (response.data?.social === 'loginSocialGoogle') {
        return response.data
    }

    localStorage.setItem('accessToken', response.data.accessToken);
    return response.data;
}