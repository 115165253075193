import Forma from "../../../../assets/images/forma.png"

export default function Banner() {
    return (
        <>
            <div id="bannerInternas" className="menor">
                <div className="forma">
                    <img src={Forma} alt="" />
                </div>
            </div>
        </>
    )
}