import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IAuthSteps } from "interfaces"

type IAuthStepsState = {
    step   : IAuthSteps.Step,
    props? : any
}
const initialState: IAuthStepsState = {
    step  : null,
    props : {}
}

export const authStepsSlice = createSlice({
    name : 'authSteps',
    initialState,
    reducers : {
        openSteps  : (state, actions: PayloadAction<IAuthStepsState>) => {
            state.step  = actions.payload.step
            state.props = { ...actions.payload.props }
        },
        closeSteps : (state) => {
            state.step  = null
            state.props = {}
        }
    } 
})

export const { closeSteps, openSteps } = authStepsSlice.actions
export default authStepsSlice.reducer