import { apiRoutes, api_ } from "config";
import { UserTypes } from "enums";
import { LoginViewModel, UserInputModel, UserViewModel } from "models";

export namespace ISaveUser {
    export type Input  = { userInfo: UserInputModel, chosenAreas?: string[] };
    export type Output = UserViewModel & LoginViewModel;
}

export const saveUser = async ({ userInfo, chosenAreas }: ISaveUser.Input): Promise<ISaveUser.Output> => {
    delete userInfo['areas'];

    let url = apiRoutes.v1.users;
    const savedUser = await api_.post<ISaveUser.Output>(url, userInfo);
    
    localStorage.setItem("accessToken", savedUser.data.accessToken);
    
    if (UserTypes.userIsOnlyApprentice(userInfo.type)) {
        url = url + '/areas';
        await api_.post(url, { userId: savedUser.data.id, chosenAreas })
    }
    
    return savedUser.data;
}