import { ObjectBanner, ObjectBody, ObjectClass, ObjectInfo } from "./components"
import { Accordion_, Container, Loading, Notification, ObjectAccordion } from "components"
import { getClassesByObject, getSubscribedClass, IGetClassesByObject } from "services_/Class"
import { useNavigate, useParams } from "react-router-dom"
import { getObject, IGetObject } from "services_/Object"
import { useEffect, useState, useContext } from "react"
import NoResult from "components/NoResult"
import { toast } from "react-hot-toast"
import { useService } from "hooks"
import './style.css'
import { SubscriptionViewModel } from "models"

export default function Object() {
    const [object, setObject] = useState<IGetObject.Output>(null);
    const [classes, setClasses] = useState<IGetClassesByObject.Output>([]);
    const [subscription, setSubscription] = useState<SubscriptionViewModel>(null);

    const params = useParams<{ objectId: string, classId: string }>()
    const goTo = useNavigate()

    const {
        isLoading: loadingObject,
        error: objectError,
        handle: handleGetObject,
    } = useService({
        service: () => getObject({ id: params.objectId }),
        onLoading: () => toast.loading('Carregando informações do recurso...', { duration: null }),
        onSuccess: () => toast.dismiss(),
    })

    const {
        isLoading: loadingClasses,
        error: classesError,
        handle: handleGetClasses,
    } = useService({
        service: () => getClassesByObject({ objectId: params.objectId }),
        onLoading: () => toast.loading('Carregando turmas do recurso...', { duration: null }),
        onSuccess: () => toast.dismiss()
    })

    const {
        isLoading: loadingSubscribedClass,
        error: subscribedClassError,
        handle: handleGetSubscribedClass,
    } = useService({
        service: () => getSubscribedClass({ classId: params.classId }),
        onLoading: () => toast.loading('Carregando informações da turma...', { duration: null }),
        onSuccess: () => toast.dismiss(),
        onError: () => toast.dismiss()
    })

    const LOADING_PAGE = loadingClasses || loadingObject || loadingSubscribedClass;
    const PAGE_HAS_ERROR = objectError || classesError;

    useEffect(() => {
        if (!object) handleGetObject().then((res) => setObject(res));
    }, [params.objectId])

    useEffect(() => {
        if (!object) return;
        if (!classes.length && !objectError) handleGetClasses().then((res) => {
            const activeClasses = res.filter(c => !!c.statusBool)
            setClasses(activeClasses)
        });
    }, [object?.id, params.classId])

    useEffect(() => {
        if (classes.length === 1) goTo(`/recurso/${params.objectId}/turma/${classes[0].id}`)
    }, [classes.length])

    useEffect(() => {
        toast.dismiss()
        if (objectError) toast.error(objectError?.response?.data?.message, { duration: 15000 , position: 'bottom-center'})
        if (classesError) toast.error(classesError?.response?.data?.message, { duration: 15000 , position: 'bottom-center'})
    }, [objectError, classesError])

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) return
        if (params.classId) handleGetSubscribedClass().then((res) => { setSubscription(res) }) 
    }, [params.classId])

    return (
        <>
            {
                !LOADING_PAGE ? (
                    !!object && (
                        <>                                
                            <ObjectBanner
                                data={{
                                    object: object,
                                    class: classes.filter(c => c.id === params.classId)[0],
                                    subscription: subscription,
                                }}
                                isClass={!!params.classId}
                            />

                            <ObjectBody>
                                <ObjectInfo
                                    data={{
                                        class  : classes.filter(c => c.id === params.classId)[0],
                                        object : object,
                                        subscription: subscription
                                    }}
                                    isClass={!!params.classId}
                                />

                                <Accordion_ title="Conteúdos">
                                    {
                                        object.contents.map((content, index) => (
                                            <Accordion_.Item key={index}>
                                                <Accordion_.Header>
                                                    { content.title }
                                                </Accordion_.Header>
                                                <Accordion_.Body>
                                                    <p>{ content.description }</p>

                                                    {
                                                        !!content?.subContents?.length && (
                                                            <Accordion_ secondary>
                                                                {
                                                                    content.subContents.map((subContent, index) => (
                                                                        <Accordion_.Item key={index}>
                                                                            <Accordion_.Header secondary> 
                                                                                { subContent.title } 
                                                                            </Accordion_.Header>
                                                                            <Accordion_.Body secondary>
                                                                                <p>{ subContent.description }</p>
                                                                            </Accordion_.Body>
                                                                        </Accordion_.Item>
                                                                    ))
                                                                }
                                                            </Accordion_>
                                                        )
                                                    }
                                                </Accordion_.Body>
                                            </Accordion_.Item>
                                        ))
                                    }
                                </Accordion_>
                                {
                                    (!params.classId) &&
                                    <ObjectClass list={classes} />
                                }
                            </ObjectBody>
                        </>
                    )
                ) : <Loading.Big />
            }
            {
                PAGE_HAS_ERROR &&
                <Container.Internals>
                    <div className='row lista ultimo recurso'>
                        <NoResult firstTitle="Recurso" midTitle=" inválido ou indisponível!" endTitle="" />
                    </div>
                </Container.Internals>
            }

            <Notification position="bottom-left" />
        </>
    )
}