import '../../../assets/css/loading.css';

type Props = {
    loading?: boolean;
}

export default function BigLoading ({ loading = true}: Props) {
    if (loading) {
        return (
            <div className="loading-big">
                <div className="loading">
                    <svg className="android-loading" width="400" height="400" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="200" cy="200" r="65" />
                    </svg>
                </div>
            </div>
        )
    }
}