import { useAppDispatch, useForm, useService } from "hooks";
import { IAuthSteps } from "interfaces";
import { closeSteps, openSteps } from "slices/AuthStepsSlice";
import Input from "../../../../../Input";
import Modal from "../../../../../Modal";
import Box from "../../../../../Box";
import ButtonArea from "../../../../../ButtonArea";
import Button from "../../../../../Button";
import toast from 'react-hot-toast'
import { Loading } from "../../../../..";
import { sendValidationCode } from "services_/Auth";

type Props = {
    previousStep? : IAuthSteps.Step
}

export default function PasswordRecovery ({ previousStep }: Props) {
    const dispatch = useAppDispatch();

    const { data, errors, handleChangeValue, handleSubmit } = useForm<{ email: string }>({
        validations: {
            email: {
                required: true,
                isEmail: true
            }
        },
        onSubmit: () => handleSendCode()
    })

    const {
        isLoading: sendingCode,
        handle: handleSendCode,
    } = useService({
        service: () => toast.promise(
            sendValidationCode({
                codeType: 'password',
                sendType: 'email',
                email: data.email,
                isAdmin: false
            }),
            {
                loading: 'Enviando link de recuperação de senha.',
                success: 'Link de recuperação de senha enviado com sucesso.',
                error: 'Erro ao enviar link de recuperação de senha.'
            }
        ),
        onSuccess: () => handleOpenEmailConfirmation()
    })

    
    const handleOpenEmailConfirmation = () => dispatch(openSteps({
        step  : IAuthSteps.Steps.EMAIL_CONFIRMATION,
        props : {
            title: 'Recuperar Acesso',
            email: data.email,
            type: 'password',
            previousStep: { step: IAuthSteps.Steps.LOGIN }
        }
    }))

    const handleCloseModal = () => dispatch(closeSteps())
    const handleBackToPreviousStep = () => {
        if (previousStep) dispatch(openSteps({ step: previousStep }))
        else dispatch(closeSteps())
    }

    return (
        <Modal className="password-recovery-modal" show={true} onClose={handleCloseModal} isLoading={false}>
            <Box title="Recuperar Senha">
               {
                     !sendingCode ?
                     <form onSubmit={handleSubmit}>
                        Insira o email da conta que deseja recuperar.
                        <div className="row" style={{marginTop:'20px'}}>
                            <Input
                                type="email"
                                icon="uil-envelope"
                                placeholder="Insira seu E-mail"
                                name="cp_email"
                                value={data.email}
                                onChange={handleChangeValue('email')}
                                messageError={errors.email}
                            />
    
                            <ButtonArea className="password-recovery">
                                <Button
                                    type="submit"
                                >
                                    Continuar
                                </Button>
                                <Button
                                    type="button"
                                    className="cadastrese"
                                    onClick={handleBackToPreviousStep}
                                >
                                    Voltar
                                </Button>
                            </ButtonArea>
                        </div>
                    </form> :
                    <Loading.Small loading={sendingCode} />
               }
            </Box>
        </Modal>
    )
}