import { apiRoutes, api_ } from "config";

export namespace IPasswordReset {
    export type Input = {
        password: string
        confirmPassword: string
        token: string
    }
    export type Output = void;
}

export const passwordReset = async (info: IPasswordReset.Input): Promise<IPasswordReset.Output> => {
    const url = `${apiRoutes.v1.users}/passwordReset`;
    await api_.patch(url, info, { headers: { 'Token': info.token }})
}