import { closeSteps, openSteps } from "slices/AuthStepsSlice";
import { useAppDispatch, useForm, usePasswordState, useService } from "hooks";
import { Box, Modal, Input, SocialLogin, ButtonArea, Button } from "../../../../index";
import { IAuthSteps } from "interfaces";
import { useContext, useState, useEffect } from "react";
import { Loading } from "components";
import { useNavigate } from "react-router-dom";
import ProfileSelect from "./ProfileSelect";
import { toast } from "react-hot-toast";
import { UserTypes } from "enums";
import { UserContext } from "contexts";
import { ILogin, login } from "services_/Auth";

type Props = {
  showProfilesSelector?: boolean;
};
export default function LoginModal({ showProfilesSelector = false }: Props) {
  const { userInfo, profileSelected, handleSelectProfile, handleLogout, setAuthenticated, handleSetUserInfoByToken } =
    useContext(UserContext);
  const [showProfiles, setShowProfiles] = useState(showProfilesSelector);

  const { passwordState, handleChangeStatePassword } = usePasswordState();

  const goTo = useNavigate();
  const dispatch = useAppDispatch();

  const { data, errors, handleChangeValue, handleSubmit } = useForm<ILogin.Input>({
    validations: {
      email: {
        required: true,
        isEmail: true,
      },
      password: {
        required: true,
      },
    },
    onSubmit: () => handleLogin(),
  });

  const {
    isLoading: logginUser,
    error: errorLogginUser,
    handle: handleLogin,
  } = useService({
    service: () => login(data),
    onSuccess: (data) => handleNextStepAfterLogin(data),
    onError: (error) => toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'}),
  });

  const handleOpenApprenticeRegister = () => dispatch(openSteps({ step: IAuthSteps.Steps.USER_REGISTER }));
  const handleOpenPasswordRecovery = () =>
    dispatch(openSteps({ step: IAuthSteps.Steps.PASSWORD_RECOVERY, props: { previousStep: IAuthSteps.Steps.LOGIN } }));

  const handleCloseModal = () => {
    if (showProfiles) handleLogout();
    dispatch(closeSteps());
  };

  const handleNextStepAfterLogin = (data?) => {

    //TODO: REFATORAR QUANDO HOUVER MAIS DE UM LOGIN SOCIAL
    if(data?.social === 'loginSocialGoogle'){ 
      toast.loading('Redirecionando...', {duration: 15000 , position: 'top-center'})
      toast.success('Login social pelo Google encontrado', {duration: 15000 , position: 'top-center'})
      window.location.href = `${process.env.REACT_APP_API_URL}/v1/auth/google`

      return
    }

    handleSetUserInfoByToken(localStorage.getItem("accessToken"));

    if (UserTypes.userIsApprenticeAndPartner(userInfo?.type) && !profileSelected) setShowProfiles(true);
    else if (UserTypes.userIsOnlyPartner(userInfo?.type)) {
      dispatch(closeSteps());
      handleSelectProfile(UserTypes.Partner, true);
    } else if (UserTypes.userIsOnlyApprentice(userInfo?.type)) {
      dispatch(closeSteps());
      handleSelectProfile(UserTypes.Apprentice, true);
      if (window.location.pathname === "/") window.location.href = "/home";
    }
  };

  useEffect(() => {
    if (userInfo?.type && !profileSelected) handleNextStepAfterLogin();
  }, [userInfo?.type]);

  return (
    <Modal className="login-modal" show={true} isLoading={logginUser} onClose={handleCloseModal}>
      <Box title={!showProfiles && "Login"}>
        {!logginUser && (
          <form onSubmit={handleSubmit}>
            {!showProfiles && (
              <>
                <div className="row">
                  <Input
                    type="email"
                    icon="uil-envelope"
                    placeholder="E-mail *"
                    name="cp_email"
                    value={data.email}
                    messageError={errors.email}
                    onChange={handleChangeValue("email")}
                  />

                  <Input
                    type="password"
                    icon="uil-lock"
                    placeholder="Senha *"
                    name="cp_senha"
                    passwordState={passwordState}
                    changePasswordState={handleChangeStatePassword}
                    value={data.password}
                    messageError={errors.password}
                    onChange={handleChangeValue("password")}
                  />

                  <ButtonArea>
                    <Button type="submit">Entrar</Button>
                    <Button type="button" className="cadastrese" onClick={handleOpenApprenticeRegister}>
                      Cadastre-se
                    </Button>
                  </ButtonArea>

                  <div className="col-xl-12 linhaLinks-centered" onClick={handleOpenPasswordRecovery}>
                    <strong>Esqueci minha senha</strong>
                  </div>

                  <SocialLogin />
                </div>
              </>
            )}
            {showProfiles && (
              <>
                <ProfileSelect />
                <div
                  className="col-xl-12 linhaLinks-centered button-voltar-profiles"
                  onClick={() => {
                    setShowProfiles(!showProfiles);
                    handleLogout();
                  }}
                >
                  <strong>Voltar</strong>
                </div>
              </>
            )}
          </form>
        )}
        {logginUser && <Loading.Small loading={logginUser} />}
      </Box>
    </Modal>
  );
}
