import PublicHeader from "./PublicHeader"
import UserHeader from "./UserHeader"
import { useContext, useEffect, useState } from 'react'
import { UserContext } from "contexts"
import { UserTypes } from "enums"

type Props = {
    setHomeSidebar: any
    isAdmin?: boolean
}

export default function Header_({ setHomeSidebar, isAdmin=false }: Props) {
    const { userInfo, profileSelected } = useContext(UserContext);
    const [showUserHeader, setShowUserHeader] = useState(false);

    useEffect(() => {
        if (!!userInfo?.userId && [UserTypes.Apprentice, UserTypes.Partner].includes(profileSelected)) setShowUserHeader(true);
    }, [userInfo?.userId, profileSelected])

    return (
        <>
            { showUserHeader ? <UserHeader isAdmin={isAdmin} /> : <PublicHeader setHomeSidebar={setHomeSidebar} /> }
        </>
    )
}