import { apiRoutes, api_ } from "config";
import { TypeViewModel } from "models";

export namespace IGetTypes {
    export type Input  = void;
    export type Output = TypeViewModel[];
}

export const getTypes = async (): Promise<IGetTypes.Output> => {
    const url = apiRoutes.v1.types

    const response = await api_.get<IGetTypes.Output>(url)
    return response.data
}