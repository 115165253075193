import './style.css'
type Props ={
    title: string,
    link: Function,
}
export default function AdminHomeCard({title, link} : Props) {
    return (

        <div className="col-xl-12">
            <div className="admin cardRecursos">
                <div className="areaIcone">
                    <i className="uil uil-graduation-cap"></i>
                </div>
                <div className="areaDados">
                    <span>{title}</span>
                </div>

                <div className="areaBotao">
                    <button onClick={() => link()}>Detalhes</button>
                </div>

            </div>
        </div>
    )
}