import { ChangeEventHandler } from "react"
import { IField } from "interfaces"
import './style.css'

type Props = {
    placeholder   : string
    data          : IField[]
    value?        : string
    label?        : string
    messageError? : string
    disabled?     : boolean
    onChange?     : ChangeEventHandler<HTMLSelectElement>
}

export default function Select ({ data, placeholder, value, messageError, label, disabled=false, onChange }: Props) {
    return (
        <div className={`col-xl-12 linhaCampo ${ messageError ? 'withError' : '' }`}>
            {
                label &&
                <label className={`form-label ${ messageError && 'withError' }`}>{label}</label>
            }
            <select className="campo select" value={value || ''} onChange={onChange} disabled={disabled}>
                <option value="">{ placeholder }</option>
                {
                    data.map((item, index) => {
                        return <option key={index} value={item.value}>{ item.text }</option>
                    })
                }
            </select>
            <div className="message-error">
                { messageError }
            </div>
        </div>
    )
}