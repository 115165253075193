import { IAuthSteps } from "interfaces"
import Modal from "../../../../Modal"
import Box from "../../../../Box"
import ButtonArea from "../../../../ButtonArea"
import { useAppDispatch, useForm, useService } from "hooks"
import { closeSteps, openSteps } from "slices/AuthStepsSlice"
import Input from "../../../../Input"
import Button from "../../../../Button"
import toast from 'react-hot-toast'
import { Loading } from "../../../.."
import { authenticate, emailConfirmation, IEmailConfirmation, sendValidationCode } from "services_/Auth"
import { useContext } from "react"
import { UserContext } from "contexts"
import { UserTypes } from "enums"

type Props = {
    email         : string
    title         : string
    previousStep? : {
        step   : IAuthSteps.Step,
        props? : any
    }
    nextStep?     : {
        step   : IAuthSteps.Step,
        props? : any
    } 
    type          : 'password' | 'email'
    sendEmail?    : boolean
}

export default function EmailConfirmation({ email, title, previousStep, nextStep, type, sendEmail=false }: Props) {
    const dispatch = useAppDispatch()
    const { handleSetUserInfoByToken, profileSelected } = useContext(UserContext)

    const { data, handleChangeValue, handleSubmit, errors } = useForm<IEmailConfirmation.Input>({
        validations: {
            code: {
                required: true,
            }
        },
        onSubmit: () => handleConfirmCode()
    })

    const { 
        isLoading: confirmingCode,
        handle: handleConfirmCode
    } = useService({
        service: () => emailConfirmation({ code: data?.code }),
        onSuccess: async () => {
            toast.success('E-mail confirmado com sucesso!')
            authenticate().then(() => {
                handleSetUserInfoByToken(localStorage.getItem('accessToken'))
                handleOpenNextStep()
            })
        },
        onError: (error) => toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'})
    })


    const handleBackToPreviousStep = () => {
        if (previousStep) dispatch(openSteps({ step: previousStep.step, props: previousStep.props }))
        else dispatch(closeSteps())
    }

    const handleOpenNextStep = () => {
        if (nextStep) dispatch(openSteps({
            step: nextStep.step,
            props: nextStep.props
        }))
        else dispatch(closeSteps())
    }

    const sendEmailFn = () => {
        if (type === 'password') handleSendPasswordRecovery();
        else handleSendEmailConfirmation();
    }

    const handleSendEmailConfirmation = () => {
        toast.promise(
            sendValidationCode({ codeType: type, email, sendType: 'email', isAdmin: false }),
            {
                loading: 'Enviando código de confirmação de e-mail.',
                success: 'Código de confirmação de e-mail enviado com sucesso.',
                error: 'Erro ao enviar código de confirmação de e-mail.'
            }
        )
    }

    const handleSendPasswordRecovery = () => {
        toast.promise(
            sendValidationCode({ codeType: type, email, sendType: 'email', isAdmin: false }),
            {
                loading: 'Enviando link de recuperação de senha.',
                success: 'Link de recuperação de senha enviado com sucesso.',
                error: 'Erro ao enviar link de recuperação de senha.'
            }
        )
    }

    const handleCloseModal = () => {
        dispatch(closeSteps())

        if (profileSelected === UserTypes.Partner && window.location.pathname !== '/home') window.location.reload()
    }

    return (
        <Modal className="email-confirmation-modal" show={true} isLoading={confirmingCode} onClose={handleCloseModal}>
            <Box title={title}>
                {
                    !confirmingCode ?
                    <form onSubmit={handleSubmit}>
                        <div className="row">

                            <div className="col-xl-12 linhaIcones">
                                <i className="uil uil-envelope-check"></i>
                            </div>

                            <div className="col-xl-12">
                                {
                                    type == 'email' ?  
                                    'Para concluir o cadastro, insira o código que enviamos para o e-mail: ' + email 
                                    :
                                    'Sua solicitação de recuperação de senha foi enviada com sucesso para o endereço de e-mail cadastrado em nosso sistema. Por favor, verifique sua caixa de entrada e siga as instruções fornecidas para redefinir sua senha. Se você não receber o e-mail dentro de alguns minutos, verifique sua pasta de spam ou tente reenviar uma solicitação. Obrigado!'
                                }
                                
                            </div>

                            {
                                type === 'email'?
                                <div className="col-xl-12 inputEmailCode" >
                            
                                <Input
                                    name="cp_code"
                                    placeholder="Código de confirmação"
                                    type="text"
                                    icon="uil-key-skeleton"
                                    value={data.code}
                                    messageError={errors.code}
                                    onChange={handleChangeValue('code')}
                                />
                                <ButtonArea className="email-confirmation">
                                    <Button
                                        type="submit"
                                    >
                                        Confirmar
                                    </Button>
                                    {
                                        previousStep &&
                                        <Button
                                            type="button"
                                            className="cadastrese"
                                            onClick={handleBackToPreviousStep}
                                        >
                                            Voltar
                                        </Button>
                                    }
                                    
                                </ButtonArea>
                        </div>
                        
                        :
                        <></>

                            }

                            <div className="col-xl-12 rodape">
                                <div 
                                    className="col-xl-12 linhaLinks-centered " 
                                    onClick={sendEmailFn}
                                >
                                    <strong>Clique aqui</strong> para reenviar a solicitação
                                </div>
                            </div>
                        </div>
                    </form> :
                    <Loading.Small loading={confirmingCode} />
                }
            </Box>
        </Modal>
    )
}