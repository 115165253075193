export enum UserTypes {
    Apprentice         = "A",
    Partner            = "P",
    Apprentice_Partner = "AP",
    Admin              = "ADMIN"
}

export namespace UserTypes {
    export const userIsPartner = (userType: UserTypes) => {
        return [UserTypes.Partner, UserTypes.Apprentice_Partner].includes(userType)
    }
    export const userIsApprentice = (userType: UserTypes) => {
        return [UserTypes.Apprentice, UserTypes.Apprentice_Partner].includes(userType)
    }

    export const userIsOnlyApprentice = (userType: UserTypes) => {
        return userType === UserTypes.Apprentice
    }

    export const userIsOnlyPartner = (userType: UserTypes) => {
        return userType === UserTypes.Partner
    }

    export const userIsApprenticeAndPartner = (userType: UserTypes) => {
        return userType === UserTypes.Apprentice_Partner
    }

    export const userIsAdmin = (userType: UserTypes) => {
        return userType === UserTypes.Admin
    }

    export const getUserType = (value: string) => {
        const indexOfS = Object.values(UserTypes).indexOf(value as unknown as UserTypes);
        const key = Object.keys(UserTypes)[indexOfS];
        return key as UserTypes
    }
}