import { Toaster, ToastPosition } from "react-hot-toast";

type Props = {
    position? : ToastPosition
}

export default function Notification ({ position='top-right' }: Props) {
    return (
        <div className='notification'>
            <Toaster position={position} toastOptions={ { duration: 6000 }} reverseOrder={false} />
        </div>
    )
}