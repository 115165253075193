import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OBJECT_MODALITIES, OBJECT_STATUS, PAYMENT_TYPES } from "constants_";
import { AreaModel, AreaViewModel, TypeModel, TypeViewModel } from "models";
import { getAreas } from "services_/Area";
import { getTypes } from "services_/Type";
import { IStates } from "interfaces";
import { TField } from "types";

/* Dados globais que são usados em mais de uma página ou que não precisam ser carregados 
 * toda vez que uma página é acessada.
 *
 * Obs: Todos os dados aqui precisam ser carregados de uma vez.
 */
interface IGlobalValues extends IStates {
    types: TypeModel[];
    areas: AreaModel[];
    modalities: TField[];
    objectStatus: TField[];
    paymentTypes: TField[];
}

const initialState: IGlobalValues = {
    types: [],
    areas: [],
    modalities: OBJECT_MODALITIES,
    objectStatus: OBJECT_STATUS,
    paymentTypes: PAYMENT_TYPES,
    isError: false,
    isLoading: false,
    isSuccess: false,
    errorData: null,
}

export const loadGlobalValues = createAsyncThunk(
    "globalValues/loadGlobalValues",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const [types, areas] = await Promise.all([getTypes(), getAreas()]);

            dispatch(setTypes(types));
            dispatch(setAreas(areas));
        } catch (error) {
            console.error('Erro ao carregar os valores globais.')
            return rejectWithValue(error);
        }
    }
)

export const globalValuesSlice = createSlice({
    name: "globalValues",
    initialState,
    reducers: {
        setTypes: (state, action: PayloadAction<TypeViewModel[]>) => {
            state.types = action.payload;
        },
        setAreas: (state, action: PayloadAction<AreaViewModel[]>) => {
            state.areas = action.payload;
        },
        addArea: (state, action: PayloadAction<AreaViewModel>) => {
            state.areas.push(action.payload);
        },
        removeArea: (state, action: PayloadAction<string>) => {
            const index = state.areas.findIndex(x => x.id === action.payload);
            state.areas.splice(index, 1);
        },
        addType: (state, action: PayloadAction<TypeViewModel>) => {
            state.types.push(action.payload);
        },
        removeType: (state, action: PayloadAction<string>) => {
            const index = state.types.findIndex(x => x.id === action.payload);
            state.types.splice(index, 1);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadGlobalValues.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loadGlobalValues.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(loadGlobalValues.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorData = action.payload;
            })
    }
})

export const { setTypes, setAreas, addArea, addType, removeArea, removeType } = globalValuesSlice.actions;
export default globalValuesSlice.reducer;