import TxtOr from '../../assets/images/login/txtOu.png'
import GoogleLogin from '../../assets/images/brands/google_logo.svg'
import FacebookLogo from '../../assets/images/brands/facebook_logo.svg'
import ButtonArea from '../ButtonArea'
import './style.css'

export default function SocialLogin() {
    return (
        <div className="col-xl-12 linhaOu">
            <div className="row">
                <div className="col-xl-12 text-center">
                    <img src={TxtOr} alt="" />
                </div>

                <ButtonArea className='text-center column-direction'>
                    <div className="btnOutros" onClick={() => {
                        window.location.href = `${process.env.REACT_APP_API_URL}/v1/auth/google`
                    }}>
                        <img src={GoogleLogin} alt="" />
                        <span>Entrar com Google</span>
                    </div>
                    {/* <div className="btnOutros">
                        <img src={FacebookLogo} alt="" width={27}/>
                        <span>Entrar com Facebook</span>
                    </div> */}
                </ButtonArea>
            </div>
        </div>
    )
}