import { ChangeEventHandler } from "react";
import { Form } from "react-bootstrap";
import './style.css'

type Props = {
    label?        : string
    size          : 'sm' | 'lg'
    messageError? : string
    disabled?     : boolean
    onChange?     : ChangeEventHandler<HTMLInputElement>
}

export default function InputFile({ label, size, messageError, disabled=false, onChange }: Props) {
    return (
        <div className="inputFile">
            <Form.Group controlId="formFileLg" className={`mb-3 ${ messageError ? 'withError' : ''}`}>
                { label && <Form.Label>{label}</Form.Label> }
                <Form.Control 
                    type="file" 
                    size={size}
                    disabled={disabled}
                    onChange={onChange}/>
            </Form.Group>
            <div className="message-error">
                { messageError }
            </div>
        </div>
    )
}