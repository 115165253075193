import Slider from "react-slick";
import { configSlide2, configSlide3 } from "../Config";

type Props = {
  children: any;
  type: "small" | "large";
};

export default function SmallSlides({ children, type }: Props) {
  let slides_: any;

  return (
    <div>
      <Slider
        adaptiveHeight
        ref={(c) => (slides_ = c)}
        className="row caixas graduacao"
        {...(type == "small" ? configSlide2 : configSlide3)}
      >
        {children}
      </Slider>

      <div className="row setas">
        <div className="col-xl-12 linha">
          <div className="botao esq" onClick={() => slides_.slickPrev()}>
            <i className="uil uil-angle-left"></i>
          </div>
          <div className="botao dir" onClick={() => slides_.slickNext()}>
            <i className="uil uil-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
