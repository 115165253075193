import { useService } from "hooks";
import { AnalyzesHistoryViewModel, ClassViewModel, ObjectViewModel } from "models";
import { createContext, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getObject } from "services_/Object";
import { addressToObject } from "types";

type ContextProps = {
  object: ObjectViewModel;
  setObject: (object: ObjectViewModel) => void;
  analysisHistory: AnalyzesHistoryViewModel[];
  setAnalysisHistory: (history: AnalyzesHistoryViewModel[]) => void;
  classes: ClassViewModel[];
  setClasses: (classes: ClassViewModel[]) => void;
  updateClass: (class_: ClassViewModel) => void;
  removeClass: (id: string) => void;
  loadingObject: boolean;
};

type ProviderProps = {
  children: React.ReactNode;
};

export const ObjectRegisterContext = createContext<ContextProps>({} as ContextProps);
export const ObjectRegisterProvider = ({ children }: ProviderProps) => {
  const [analysisHistory, setAnalysisHistory] = useState<AnalyzesHistoryViewModel[]>([]);
  const [classes, setClasses] = useState<ClassViewModel[]>([]);
  const [object, setObject] = useState<ObjectViewModel>();
  const params = useParams<{ objectId: string }>();

  const { isLoading: loadingObject, handle: handleGetObject } = useService<ObjectViewModel>({
    service: () => getObject({ id: params.objectId }),
    onLoading: () => toast.loading("Carregando recurso...", { duration: null }),
    onSuccess: (res) => {
      toast.dismiss();
      setObject(res);
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.message || "Erro ao carregar recurso", { duration: 15000 , position: 'top-center'});
    },
  });

  const removeClass = (id: string): void => {
    const newClasses = classes.filter((class_) => class_.id !== id);
    setClasses(newClasses);
  };

  const updateClass = (class_: ClassViewModel): void => {
    const newClasses = classes.map((classTemp) => {
      if (classTemp.id === class_.id)
        return { ...class_, address: class_?.place ? addressToObject(class_?.place) : null };
      return classTemp;
    });
    setClasses(newClasses);
  };

  useEffect(() => {
    if (params.objectId && !object) handleGetObject();
  }, [params.objectId]);

  return (
    <ObjectRegisterContext.Provider
      value={{
        object,
        setObject,
        analysisHistory,
        setAnalysisHistory,
        loadingObject,
        classes,
        setClasses,
        removeClass,
        updateClass,
      }}
    >
      {children}
    </ObjectRegisterContext.Provider>
  );
};
