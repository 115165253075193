import { useContext, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { ObjectAnalysisContext } from 'contexts';

import './style.css'
import { useForm } from 'hooks';
import { TObject } from 'types';
import { ObjectStatus } from 'enums';
import { Box, Button, ButtonArea, Modal, TextArea } from 'components';

type Props = {
    show: boolean
    onClose: () => void
}
export default function ApprobationModal({show, onClose} : Props) {
    const params = useParams<{ objectId: string }>()

    const { handleSubmitAnalysis, sendingAnalysis } = useContext(ObjectAnalysisContext)

    const { data, errors, handleChangeValue, handleSubmit } = useForm<Pick<TObject.AnalyzeObject, 'observation'>>({
        validations: {
            observation: {
                required: true,
            }
        },
        onSubmit: () => handleSubmitAnalysis({
            status      : ObjectStatus.Disapproved,
            observation : data.observation,
            objectId    : params.objectId
        })
    })

    return (
        <Modal show={show} size='lg' onClose={onClose} isLoading={false}>
            <form
                onSubmit={handleSubmit}
            >
                <Box title='Justificativa para reprovação de recurso.'>
                    <div className="row">
                        <TextArea
                            name='Justify'
                            placeholder='Insira a justificativa.'
                            rows={3}
                            maxHeight={700}
                            maxLength={1000}
                            showTextLimit
                            messageError={errors.observation}
                            value={data.observation}
                            onChange={handleChangeValue('observation')}
                        />
                    </div>
                </Box>

                <ButtonArea className='text-center'>
                    <Button className='admin azul' type='submit' loading={sendingAnalysis}>
                        <i className="uil uil-check-circle"></i> Enviar
                    </Button>
                    <Button className='admin vermelho' type='button' onClick={onClose} disabled = {sendingAnalysis}>
                        <i className="uil uil-times-circle"></i> Cancelar
                    </Button>
                </ButtonArea>
            </form>
        </Modal>
    )
}