import { Box, BoxList, Container, Item, Loading, Notification } from "components";
import { EnumRegisterTypes, ObjectRegisterOptionsContext } from "contexts";
import { useAppSelector } from "hooks";
import { useContext } from "react";
import RegisterArea from "./components/RegisterArea";
import './style.css'

export default function ObjectRegisterOptions() {
    const { types, areas, isLoading: loadingGlobalValues } = useAppSelector(state => state.globalValues);
    const { items, registerType, selectedItem, setSelectedItem, handleAddNewOption } = useContext(ObjectRegisterOptionsContext)

    const title = registerType === EnumRegisterTypes.AREA ? 'Áreas de Ensino' : 'Tipos de Recurso'

    const LOADING_PAGE = loadingGlobalValues

    return (
        <>
            <Container.Fluid>
                {
                    !LOADING_PAGE ? (
                        <Box title={`Cadastrar ${ title }`}>
                            <div className='row'>
                                <BoxList
                                    title={title}
                                    size='7'
                                    showSearchBar={false}
                                    buttonFunction={handleAddNewOption}
                                    emptyListMessage='Nenhum item cadastrado.'
                                >
                                    {
                                        items.map(item => (
                                            <div key={item.id}>
                                                <div className='item' onClick={() => { setSelectedItem(item) }}>
                                                    <Item
                                                        title={item.name}	
                                                        key={item.id}
                                                        description={item.status === 'A'? 'Ativo' : 'Inativo'}
                                                        type='custom'
                                                        icon="uil uil-book-alt"
                                                        selected={!!selectedItem.id && selectedItem.id == item.id}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </BoxList>
                                <RegisterArea />
                            </div>
                        </Box>
                    ) : (
                        <Loading.Big />
                    )
                }
                <Notification position="bottom-left"/>
            </Container.Fluid>
        </>
    )
}