import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { IAuthSteps } from "interfaces";
import { openSteps } from "slices/AuthStepsSlice";
import SidebarModule from "./Modules";
import { Status, UserTypes } from "enums";
import "../../../../assets/css/headerUser_.css";
import { useContext } from "react";
import { UserContext } from "contexts";

type Props = {
  showHomeSidebar: boolean;
  setHomeSidebar: Function;
  showHomeSidebarModules: object;
  handleHomeSidebarModuleChange: Function;
  isAdmin?: boolean;
};

export default function Sidebar({ showHomeSidebar, setHomeSidebar, showHomeSidebarModules, handleHomeSidebarModuleChange, isAdmin = false }: Props) {
  const { userInfo, profileSelected, handleSelectProfile, handleBecomePartner, handleLogout } = useContext(UserContext);

  const { areas, types } = useAppSelector((state) => state.globalValues);

  const dispatch = useAppDispatch();
  const goTo = useNavigate();

  const handleOpenLogin = () => dispatch(openSteps({ step: IAuthSteps.Steps.LOGIN }));
  const handleOpenApprenticeRegister = () => dispatch(openSteps({ step: IAuthSteps.Steps.USER_REGISTER }));
  const handleLogout_ = () => {
    handleLogout();
    window.location.reload();
  };

  return (
    <div id="menuLateral" className={`${showHomeSidebar ? "aberto" : ""}`}>
      <div className="lateral">
        <div className="centro">
          {!isAdmin && (
            <div className="outros">
              <div className="areaBuscar">
                <form encType="multipart/form-data" id="formBuscarLateral" lang="pt" method="get" name="formBuscarLateral">
                  <input type="text" name="cp_buscar" placeholder="Buscar por..." />
                  <button type="submit">
                    <i className="uil uil-search"></i>
                  </button>
                </form>
              </div>
            </div>
          )}

          <div className="menu">
            <div className="outros">
              {profileSelected == null ? (
                !isAdmin && (
                  <>
                    <div className="btnLogin" onClick={handleOpenLogin}>
                      {" "}
                      Login
                    </div>
                    <div className="btnInscrevase" onClick={handleOpenApprenticeRegister}>
                      Inscrever-se
                    </div>
                  </>
                )
              ) : (
                <>
                  <div className="menuDireita">
                    {/* TODO: Implementar no próximo MVP */}
                    {/* <a className="notificacoes">Notificações</a>
                                            <a className="carrinho">Meu Carrinho</a> */}

                    {profileSelected === UserTypes.Apprentice && (
                      <>
                        <a className="link icoPessoa" onClick={() => goTo("/minhas-turmas")}>
                          Minhas Turmas
                        </a>
                        <a className="link icoPessoa" onClick={() => goTo("/minhas-compras")}>
                          Minhas Compras
                        </a>
                        {/* <a className="link icoPessoa" onClick={() => goTo("/minhas-carteira")}>
                          Carteira
                        </a> */}
                      </>
                    )}
                    {UserTypes.userIsOnlyApprentice(userInfo?.type) && (
                      <a className="link icoPessoa" onClick={() => handleBecomePartner()}>
                        Virar Parceiro
                      </a>
                    )}
                    {UserTypes.userIsApprenticeAndPartner(userInfo?.type) && (
                      <a
                        className="link icoPessoa"
                        onClick={() => handleSelectProfile(profileSelected == "A" ? UserTypes.Partner : UserTypes.Apprentice, true)}
                      >
                        Alternar para {profileSelected === "A" ? "parceiro" : "aprendiz"}
                      </a>
                    )}

                    {<a onClick={() => goTo(isAdmin ? "admin/home" : "home")}>Ir para Home</a>}
                  </div>
                  <div className="btnInscrevase" onClick={handleLogout_}>
                    Sair
                  </div>
                </>
              )}
            </div>
            {areas.length > 0 && !isAdmin && (
              <SidebarModule
                key={"area-module"}
                id={"area-module"}
                title="Áreas de Conhecimento"
                links={areas
                  .filter((area) => area.status === Status.Active)
                  .map((area) => ({ title: area.name, path: `/recursos?areaId=${area.id}` }))}
                onClick={() => handleHomeSidebarModuleChange("area-module")}
                showHomeSidebarModules={showHomeSidebarModules}
              />
            )}
            {types.length > 0 && !isAdmin && (
              <SidebarModule
                key={"type-module"}
                id={"type-module"}
                title="Tipos de Recurso"
                links={types
                  .filter((type) => type.status === Status.Active)
                  .map((type) => ({ title: type.name, path: `/recursos?typeId=${type.id}` }))}
                onClick={() => handleHomeSidebarModuleChange("type-module")}
                showHomeSidebarModules={showHomeSidebarModules}
              />
            )}
            {isAdmin && (
              <SidebarModule
                key={"admin-module"}
                id={"admin-module"}
                title="Cadastros"
                links={[
                  { name: "Tipos de Recurso", path: "/admin/cadastros/tipo" },
                  { name: "Areas de Recurso", path: "/admin/cadastros/area" },
                ].map((a) => ({ title: a.name, path: a.path }))}
                onClick={() => handleHomeSidebarModuleChange("admin-module")}
                showHomeSidebarModules={showHomeSidebarModules}
              />
            )}
          </div>
        </div>
      </div>

      <div className="sombra" onMouseEnter={() => setHomeSidebar(false)}>
        <div className="icoFechar">
          <i className="uil uil-times"></i>
        </div>
      </div>
    </div>
  );
}
