import React from "react"
import AnimateHeight from "react-animate-height"
import { ContentState } from ".."
import './style.css'

type Props = {
    index         : number
    data          : ContentState
    isOpen        : boolean
    isSubContent? : boolean
    onOpen?       : (id: string, isSubContent: boolean) => void
    children?     : React.ReactNode
}

export default function AccordionContent({ index, data, isOpen, isSubContent=false, onOpen, children}: Props) {
    return (
        <div className="card" key={index}>

            <div 
                className={`card-header ${isSubContent ? 'interno': ''}`} 
                id={`cabecalho_${isSubContent ? `ctd_${index}` : index}`}
            >
                <h2>
                    <button 
                        className="btn btn-link btn-block text-left" 
                        type="button"
                        aria-expanded={isOpen}
                        onClick={() => onOpen(data.id, isSubContent)}
                    >
                        { children != undefined && <input className="btnRadio" type="radio" name="pgt_forma" id="pgt_cartao" value="cartao" checked = {isOpen}/>}
                        <span>{ data.title }</span>
                        <i className="uil uil-angle-down"></i>
                    </button>
                </h2>
            </div>

            <AnimateHeight
                duration={ 500 }
                height={ isOpen ? 'auto' : 0 }
            >
                <div 
                    id={`corpo_${isSubContent ? `ctd_${index}` : index}`} 
                    className='collapse show' 
                    data-parent="#accordionRecurso"
                >
                    <div className={`card-body ${isSubContent ? 'interno': ''}`} >
                        <p>{ data.description }</p>
                    
                        {
                            !!data.subContents?.length && (
                                <div className="accordion mb-0" id="accordionRecursoConteudo">
                                    {
                                        data.subContents.map((subContent, index) => {
                                            return (
                                                <AccordionContent
                                                    index={index}
                                                    data={{ ...subContent, objectId: data.objectId }}
                                                    isSubContent
                                                    isOpen={subContent.isOpen}
                                                    onOpen={onOpen}
                                                    key={index}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }

                        {
                            children != undefined && 
                            children
                        }

                    </div>
                </div>
            </AnimateHeight>

        </div>

    )
}