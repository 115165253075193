import { TStep } from "types";
import { useState } from "react";
import "./style.css";
import { useAppDispatch, useAppSelector } from "hooks";
import { setStep } from "slices/StepsBoxSlice";

type Props = {
  steps: TStep[];
  isOrdinal?: boolean;
  isLoading: boolean;
};

export default function StepsController({
  steps,
  isOrdinal = false,
  isLoading,
}: Props) {
  const { currentStep } = useAppSelector((state) => state.stepsBox);

  const dispatch = useAppDispatch();

  return (
    <div className="stepsController">
      <ul className="steps">
        {steps.map(
          (step, index) =>
            !step.hidden && (
              <div
                className={`stepComponent ${
                  step.index === currentStep ? "active" : ""
                }`}
                key={`step-${index}`}
              >
                <div className="stepContent">
                  <li
                    className={`step ${
                      isLoading ? "disabled" : !step.isActive && "disabled"
                    }`}
                    role="button"
                    onClick={() =>
                      !isLoading && step.isActive && dispatch(setStep(index))
                    }
                  >
                    {isOrdinal ? index + 1 : <i className={step.icon} />}
                  </li>
                </div>
              </div>
            )
        )}
      </ul>

      <ul className="titles">
        {steps.map(
          (step, index) =>
            !step.hidden && (
              <div
                className={`titleComponent ${
                  step.index === currentStep ? "active" : ""
                }`}
                key={`title-${index}`}
              >
                <div className="titleContent">
                  <li className="title">{step.title}</li>
                </div>
              </div>
            )
        )}
      </ul>
    </div>
  );
}
