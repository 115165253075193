import { apiRoutes, api_ } from "config";
import { ObjectViewModel } from "models";
import { TObjectSearchInput } from "types";

export namespace IGetObjectsRecommendation {
    export type Input = Partial<TObjectSearchInput>;
    export type Output = ObjectViewModel[];
}

export const getObjectsRecommendation = async (search: IGetObjectsRecommendation.Input): Promise<IGetObjectsRecommendation.Output> => {
    const url = '/v1/objectsRecommendation';
    const response = await api_.get<IGetObjectsRecommendation.Output>(url, { params: search });
    return response.data;
}

