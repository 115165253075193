import { ClassInputModel } from "models"
import { useNavigate, useParams } from "react-router-dom"

type Props = Partial<ClassInputModel> & {
    name : string
}
export default function ClassCard(data:Props) {
    const goTo = useNavigate ()
    const params = useParams<{ objectId: string }>()

    const startDate = !!data.startDate ? new Date(data.startDate).toLocaleDateString('pt-br') : '-'
    const endDate   = !!data.endDate   ? new Date(data.endDate).toLocaleDateString('pt-br') : '-'
    const startTime = !!data.startDate ? new Date(data.startDate).toLocaleTimeString('pt-br').substring(0,5) : '-'
    const endTime   = !!data.endDate   ? new Date(data.endDate).toLocaleTimeString('pt-br').substring(0,5) : '-'

    return (
        <>
            <div className="cardTurma">

                <div className="icone">
                    <i className="uil uil-users-alt"></i>
                </div>

                <div className="dados">
                    <div className="row partes">
                        <div className="col-xl-2 col-lg-12 col-md-12 coluna">
                            <div className="turma">{data.name}</div>
                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-4 coluna">
                            <div className="etiqueta">Data de Inicio</div>
                            <div className="valor">{startDate}</div>
                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-4 coluna">
                            <div className="etiqueta">Data de Fim</div>
                            <div className="valor">{endDate}</div>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-4 coluna">
                            <div className="etiqueta">Horário</div>
                            <div className="valor">Das {startTime} às {endTime}</div>
                        </div>

                        <div className="col-xl-3 col-lg-12 col-md-12 coluna">
                            <div 
                                role='button'
                                className="botao"
                                onClick = {() => goTo(`/recurso/${params.objectId}/turma/${data.id}`)} 
                            >
                                DETALHES
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
