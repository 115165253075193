import StepsController from "./StepsController";
import { Container } from "../templates/app";
import { TStep } from "types";
import Box from "../Box";
import "./style.css";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { resetSteps } from "slices/StepsBoxSlice"; // Não precisa de setStep aqui

type Props = {
  steps: TStep[];
  title: string;
  isOrdinal?: boolean;
  isLoading: boolean;
  isActive?: boolean;
  hidden?: boolean;
  onNext?: boolean;
};

export default function StepsBox({
  steps,
  title,
  isOrdinal = false,
  isLoading,
  isActive = true,
}: Props) {
  const { currentStep } = useAppSelector((state) => state.stepsBox);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetSteps());
  }, [dispatch]);

  return (
    <div className="stepsBox">
      <Container.Fluid>
        <Box title={title}>
          <StepsController
            steps={steps}
            isOrdinal={isOrdinal}
            isLoading={isLoading}
          />
          {!isLoading && steps[currentStep].element}
        </Box>
      </Container.Fluid>
    </div>
  );
}
