import { Checkbox, Input } from 'components'
import { UserContext } from 'contexts'
import { PaymentMethods } from 'enums'
import { useAppDispatch, useAppSelector, useForm } from 'hooks'
import { setPaymentProfile } from 'slices/OrderSlice'
import { TPayment } from 'types'
import { useContext } from 'react'
import './style.css'

type Props = {
     onUseSavedCards: Function,
}

export default function PaymentForm({ onUseSavedCards } : Props) {
     const { userInfo } = useContext(UserContext)
     const { paymentProfiles } = useAppSelector(state => state.order)

     const { data, errors, handleChangeValue, handleChangeCheckValue, handleSubmit, handleChangeValueWithMask } = useForm<TPayment.Card & Pick<TPayment.Input, 'save_profile'>>({
          validations: {
              cpf_cnpj             : { required: true },
              card_name            : { required: true },
              card_number          : { required: true, requireLength: 19 },
              card_cvv             : { required: true, requireLength: 3 },
              card_expiration_date : { required: true },
          },
          onSubmit: () => null
     })

     const dispatch = useAppDispatch()

     const handleChangeForm = (e: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(e);
          dispatch(setPaymentProfile({
               payment_method: PaymentMethods.CREDIT_CARD,
               card: {
                    user_name            : userInfo?.name,
                    cpf_cnpj             : data.cpf_cnpj,
                    card_cvv             : data.card_cvv,
                    card_expiration_date : data.card_expiration_date,
                    card_method          : PaymentMethods.CREDIT_CARD,
                    card_name            : data.card_name,
                    card_number          : data.card_number,
               },
               save_profile: data.save_profile
          }))
     }

     return (
          <div className='payment-profile-form'>
               <form onMouseLeave={(e) => handleChangeForm(e)}>
                    <div className="row">

                         <Input
                              name='card_name'
                              type='text'
                              label='Nome no cartão'
                              placeholder='Obrigatório'
                              className='form-control'
                              col='6'

                              value={data.card_name}
                              onChange={handleChangeValue('card_name')}
                              messageError={errors.card_name}
                         />

                         <Input
                              name='cpf_cnpj'
                              type="text"
                              label='CPF / CNPJ'
                              placeholder='Obrigatório'
                              className='form-control'
                              col='6'
                              maxLength={18}

                              value={data.cpf_cnpj}
                              messageError={errors.cpf_cnpj}
                              onChange={handleChangeValueWithMask('cpf_cnpj', 'cpf_cnpj')}
                         />

                         <Input
                              name='card_number'
                              type="text"
                              label='Número do cartão'
                              placeholder='Obrigatório'
                              className='form-control'
                              col='12'
                              maxLength={19}

                              value={data.card_number}
                              messageError={errors.card_number}
                              onChange={handleChangeValueWithMask('card_number', 'cardNumber')}
                         />

                         <Input
                              name='card_cvv'
                              type="text"
                              label='CVC / CVV'
                              placeholder='Obrigatório'
                              className='form-control'
                              col='6'

                              value={data.card_cvv}
                              messageError={errors.card_cvv}
                              maxLength={3}
                              onChange={handleChangeValue('card_cvv')}
                         />

                         <Input
                              name='card_expiration_date'
                              type="text"
                              label='Data de Expiração'
                              placeholder='Mês/Ano'
                              className='form-control'
                              maxLength={5}
                              col='6'

                              value={data.card_expiration_date}
                              messageError={errors.card_expiration_date}
                              onChange={handleChangeValueWithMask('card_expiration_date', 'cardExpiration')}
                         />

                         <div className="col-xl-12 mt-xl-3 mt-lg-3 mt-md-3 mt-2">
                              <div className='checkbox'>
                                   <Checkbox
                                        name='save_profile'
                                        checked={data.save_profile}
                                        onChange={handleChangeCheckValue('save_profile')}
                                   />
                              </div>
                              <div className='checkbox-label'>
                                   <span>Salvar este cartão com segurança para minhas próximas compras</span>
                              </div>
                         </div>

                         {
                              !!paymentProfiles.length &&
                              <div className='add-card' onClick = {()=> onUseSavedCards()}>        
                                   voltar
                              </div>
                         }
                    </div>
               </form>
          </div>

     )
}