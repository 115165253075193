import { apiRoutes, api_ } from "config";
import { ObjectViewModel } from "models";

export namespace IGetObject {
    export type Input = { id: string };
    export type Output = ObjectViewModel;
}

export const getObject = async (input: IGetObject.Input): Promise<IGetObject.Output> => {
    const url = `${apiRoutes.v1.objects}/${input.id}`;
    const response = await api_.get<IGetObject.Output>(url);
    return response.data;
}