import { apiRoutes, api_ } from "config";
import { PaymentInputModel, PixInputModel } from "models/Order";

export namespace IClassSubscription {
    export type Input = { 
        classId: string, 
        paymentInfo?: PaymentInputModel,
        pixInfo?: PixInputModel,
    };
    export type Output = void
}

export const classSubscription = async (data: IClassSubscription.Input): Promise<IClassSubscription.Output> => {
    const url = `${apiRoutes.v1.subscriptions}/class/${data.classId}`;

    const response = await api_.post(url, data);
    return response.data;
}