import { Modal as BootstrapModal } from "react-bootstrap";
import { Container } from "../templates/app";
import { ReactNode, useEffect, useState } from "react";
import '../../assets/css/modal.css'
import Notification from "../Notification";
import './style.css'

type Props = {
	show     : boolean
	children : ReactNode
	className?: string
	onClose: () => void
	size?	: 'lg' | 'xl' | 'sm' 
	isLoading: boolean
}

export default function Modal ({ show, children, className, onClose, size, isLoading }: Props) {
	const [show_, setShow_] = useState<boolean>(show)
	const [block, setBlock] = useState<boolean>(false)
	
	const handleClose = () => {
		if (onClose && !isLoading) {
			onClose()
			setShow_(false)
		}
	}

	useEffect(()=>{ setBlock(isLoading)}, [isLoading])
	useEffect(() => { if (!show) handleClose(); else setShow_(show) }, [show])

	return (
		<BootstrapModal
			size={size}
			show={show_}
			centered
			onHide={handleClose}
			className={className}
		>
			<BootstrapModal.Header>
                <div className={`btn-close ${block && 'blocked'}`} onClick={handleClose}>
					<i className="uil uil-times"></i>
				</div>
            </BootstrapModal.Header>
			<BootstrapModal.Body>
				<Container.Modal>
                    { children }	
                </Container.Modal>
			</BootstrapModal.Body>
			<Notification />
		</BootstrapModal>
	)
}