import { apiRoutes, api_ } from "config";
import { AnalyzesHistoryViewModel } from "models";

export namespace IGetObjectAnalysisHistory {
    export type Input = { objectId: string };
    export type Output = AnalyzesHistoryViewModel[];
}

export const getObjectAnalysisHistory = async (input: IGetObjectAnalysisHistory.Input): Promise<IGetObjectAnalysisHistory.Output> => {
    const url = `${apiRoutes.v1.objects}/${input.objectId}/analysisHistory`;

    const response = await api_.get<IGetObjectAnalysisHistory.Output>(url);
    return response.data;
}