import { apiRoutes, api_ } from "config";
import { Status } from "enums";
import { TypeInputModel, TypeViewModel } from "models";

export namespace IUpdateType {
    export type Input = Required<Pick<TypeInputModel, 'id'>> & Partial<Omit<TypeInputModel, 'id'>>;
    export type Output = TypeViewModel;
}

export const updateType = async (info: IUpdateType.Input): Promise<IUpdateType.Output> => {
    const url = `${apiRoutes.v1.types}/${info.id}`;

    const body: Partial<Pick<TypeInputModel, 'name' | 'status'>> = {};
    if (info.name) body.name = info.name;
    if (info.statusBool !== undefined) body.status = info.statusBool ? Status.Active : Status.Inactive;

    const response = await api_.put<TypeViewModel>(url, body);
    return response.data;
}