import { Link } from 'react-router-dom'
export default function Partners() {
    const partners: any[] = [
        { name: 'Innovation Center', image: require(`../../../../assets/images/partners/innovation_center.png`) },
        { name: 'Grupo Tiradentes', image: require(`../../../../assets/images/partners/grupo_tiradentes.png`) },
        { name: 'UNIT', image: require(`../../../../assets/images/partners/unit_se.png`) },
        { name: 'São Luís de França', image: require(`../../../../assets/images/partners/sao_luis_franca.png`) },
        { name: 'FITS', image: require(`../../../../assets/images/partners/fits.png`) },
    ]

    return (
        <div className="colaboradores">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 titulo">Parceiros</div>
                    <div className="col-xl-12 linha">
                        {
                            partners.map((partner, index) => (
                                <Link to="#" className="marcas" key={index}>
                                    <img
                                        src={partner.image}
                                        alt={partner.name}
                                        title={partner.name}
                                    />
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}