import { createContext, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import jwtDecode from "jwt-decode";
import { UserTypes } from "enums";
import { TJwt } from "types";
import { openSteps } from "slices/AuthStepsSlice";
import { userInfo } from "os";
import { IAuthSteps } from "interfaces";

type ContextProps = {
    profileSelected: UserTypes.Apprentice | UserTypes.Partner | null;
    userInfo: TJwt.Content | null;
    authenticated: boolean;
    setAuthenticated: (authenticated: boolean) => void;
    handleSelectProfile: (profile: UserTypes.Apprentice | UserTypes.Partner, reload: boolean) => void;
    handleSetUserInfoByToken: (token: string) => void;
    handleLogout: () => void;
    handleBecomePartner: () => void;
    handleAdditionalInfo: () => void;
}

type ProviderProps = {
    children: React.ReactNode;
}

export const UserContext = createContext<ContextProps>({} as ContextProps);
export const UserProvider = ({ children }: ProviderProps) => {
    const [profileSelected, setProfileSelected] = useState<UserTypes.Apprentice | UserTypes.Partner | null>(null);
    const [info, setInfo] = useState<TJwt.Content | null>(null);
    const [authenticated, setAuthenticated] = useState(false);
    const { step: currentAuthStep } = useAppSelector(state => state.authSteps)

    const dispatch = useAppDispatch();

    useEffect(() => {
        const storageProfileSelected = localStorage.getItem('profileSelected');
        const storageToken = localStorage.getItem('accessToken');

        if (storageProfileSelected) setProfileSelected(storageProfileSelected as UserTypes.Apprentice | UserTypes.Partner);
        if (storageToken) setInfo(jwtDecode<TJwt.Content>(storageToken));
    }, [])


    const handleSelectProfile = (profile: UserTypes.Apprentice | UserTypes.Partner, reload: boolean) => {
        setProfileSelected(profile);
        localStorage.setItem('profileSelected', profile);
        if (reload && window.location.pathname !== '/') document.location.reload()
        if (reload && profile === UserTypes.Partner) window.location.href = '/home'
    }

    const handleSetInfoByToken = (token: string) => {
        setInfo(jwtDecode<TJwt.Content>(token))
    };
    const handleLogout = () => {
        localStorage.clear();
        setProfileSelected(null);
        setInfo(null);
        setAuthenticated(false);
    }

    const handleBecomePartner = () => {
        if (!info?.emailVerified) dispatch(openSteps({
            step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
            props: {
                title: 'Confirmação de E-mail',
                email: info?.email,
                type: 'email',
                nextStep: {
                    step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
                    props: {
                        userData: info,
                        nextStep: {
                            step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
                            props: { showContract: true }
                        }
                    }
                }
            }
        }));
        else if (!info?.document) dispatch(openSteps({
            step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
            props: {
                userData: info,
                nextStep: {
                    step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
                    props: { showContract: true }
                }
            }
        }));
        else dispatch(openSteps({
            step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
            props: { showContract: true }
        }));
    }

    const handleAdditionalInfo = () => {
        if (!info?.emailVerified && !currentAuthStep && !!info?.userId) {
            dispatch(
                openSteps({
                    step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
                    props: {
                        title: "Confirmação de E-mail",
                        email: info?.email,
                        type: "email",
                        nextStep: {
                            step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
                            props: { userData: info },
                        },
                    },
                })
            );
        } else if (!info?.document && !currentAuthStep && !!info?.userId) {
            dispatch(
                openSteps({
                    step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
                    props: { userData: info },
                })
            );
        }
    };

    return (
        <UserContext.Provider value={{
            profileSelected,
            userInfo: info,
            handleSelectProfile,
            handleSetUserInfoByToken: handleSetInfoByToken,
            authenticated,
            setAuthenticated,
            handleLogout,
            handleBecomePartner,
            handleAdditionalInfo
        }}
        >
            {children}
        </UserContext.Provider>
    )
}