import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useSearchForm = () => {
    const [search, setSearch] = useState<string>('')
    const goTo = useNavigate()

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)

    const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        goTo(`/recursos?search=${search}`)
    }

    return {handleSubmitSearch, onSearch }
}