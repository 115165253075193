import { apiRoutes, api_ } from "config"
import { ClassViewModel } from "models"
import { Status } from "enums"
import { isDeadlinePassed } from "Functions"

export namespace IGetClassesByObject {
    export type Input = { objectId: string }
    export type Output = ClassViewModel[]
}

export const getClassesByObject = async (data: IGetClassesByObject.Input): Promise<IGetClassesByObject.Output> => {
    const url = `${apiRoutes.v1.classes}/object/${data.objectId}`
    const response = await api_.get<IGetClassesByObject.Output>(url)
    return response.data.map((class_) => {
        return {
            ...class_,
            statusBool: Status.toBoolean(class_.status),
            expired: isDeadlinePassed(class_.endDate)
        }
    })
}
