import { IValidationCode } from 'interfaces';
import { api, deleteAuthToken, routes, setAuthToken } from 'config/Api';
import { IAuth } from 'interfaces';
import { TAdmin } from 'types';

export const login = async (login: IAuth.InputLogin) => {
  const response = await api.post<IAuth.OutputLogin>(routes.login, login)
  localStorage.setItem('accessToken', response.data.accessToken)
  return response.data
}

export const emailConf = async ({ code }: IAuth.EmailConfCode) => {
  setAuthToken()
  await api.post(`${routes.emailConf}/${code}`)
}

export const sendValidationCode = async (info: IValidationCode) => {
  await api.post(routes.sendValidationCode, info)
}

export const autenticate = async (data: IAuth.User) => {
  setAuthToken()
  const response = await api.get<IAuth.OutputLogin>(routes.auth)
  localStorage.setItem('accessToken', response.data.accessToken)
  return response.data
}

// -------------- Admin Services ----------------
const adminLogin = async (input: TAdmin.InputLogin): Promise<TAdmin.OutputLogin> => {
  const response = await api.post<TAdmin.OutputLogin>(`${routes.admin}/login`, input);
  localStorage.setItem('accessToken', response.data.accessToken);
  return response.data;
}

const adminAutenticate = async (): Promise<TAdmin.OutputLogin> => {
  setAuthToken()
  const response = await api.get<TAdmin.OutputLogin>(`${routes.admin}/auth`)
  localStorage.setItem('accessToken', response.data.accessToken)
  return response.data
}

export const authServices = {
  login,
  emailConf,
  sendValidationCode,
  autenticate,
  adminLogin,
  adminAutenticate,
}

export default authServices