import { apiRoutes, api_ } from "config";
import { SubscribedClassesViewModel } from "models";

export namespace IGetSubscribedClasses {
  export type Input = void;
  export type Output = SubscribedClassesViewModel[]
}

export const getSubscribedClasses = async (input: IGetSubscribedClasses.Input): Promise<IGetSubscribedClasses.Output> => {
    const url = apiRoutes.v1.subscriptions;

    const response = await api_.get<SubscribedClassesViewModel[]>(url);
    return response.data;
}