import { MaskTypes } from "enums";

export const useMask = () => {
    const handleCPFMask = (value: string) => {
        let cpf = value.replace(/\D/g, '')
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/(\d{3})(\d{2})$/, "$1-$2")
        return cpf
    }

    const handleCNPJMask = (value: string) => {
        let cnpj = value.replace(/\D/g, '')
        cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        return cnpj
    }

    const handleCEPMask = (value: string) => {
        const cep = value.replace(/\D/g, '')
        return cep.replace(/(\d{5})(\d)/, '$1-$2')
    }

    const handlePhoneMask = (value: string) => {
        let phone = value.replace(/\D/g, '')
        phone = phone.replace(/(\d{1})/, "($1");
        phone = phone.replace(/(\d{2})(\d)/, "$1) $2");
        phone = phone.replace(/([\d, '']{2})(\d)/, "$1 $2");
        phone = phone.replace(/(\d{4})(\d)/, "$1-$2");
        phone = phone.replace(/^(\d{4})(\d)/, '');
        return phone
    }

    const handleCurrencyMask = (value: string) => {
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d)(\d{2})$/, "$1,$2");
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
        value = value.replace(/^0+(?=\d)/, "");

        return value;
    }

    const handleCardNumberMask = (value: string) => {
        let cardNumber = value.replace(/\D/g, '')
        cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
        cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
        cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
        cardNumber = cardNumber.replace(/(\d{4})(\d)/, "$1 $2");
        return cardNumber
    }

    const handleCardExpirationMask = (value: string) => {
        let cardExpiration = value.replace(/\D/g, '')
        cardExpiration = cardExpiration.replace(/(\d{2})(\d)/, "$1/$2");
        return cardExpiration
    }

    const handleAutoCompleteCPForCNPJMask = (value: string) => {
        if (value.length > 14) return handleCNPJMask(value)
        return handleCPFMask(value)
    }

    const handleMask = (value: string, mask: MaskTypes) => {
        const masks = {
            'cpf'            : handleCPFMask,
            'cnpj'           : handleCNPJMask,
            'cep'            : handleCEPMask,
            'phone'          : handlePhoneMask,
            'currency'       : handleCurrencyMask,
            'cardNumber'     : handleCardNumberMask,
            'cardExpiration' : handleCardExpirationMask,
            'cpf_cnpj'       : handleAutoCompleteCPForCNPJMask
        }
        
        if (value) return masks[mask](value)
    }

    return { handleMask } 
}