
import { Banner, Button, Notification, ObjectAccordion } from "components";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { PaymentBody, PaymentPix, PaymentResume } from "./components";
import { useEffect, useState } from "react";    
import { useLocation, useNavigate } from "react-router-dom";
import { getPaymentProfiles } from "slices/OrderSlice";
import PaymentProfiles from "./components/PaymentProfiles";
import { ISubscription } from "types";
import { toast } from "react-hot-toast";
import { classSubscription } from "services_/Class";

export default function Payment(){
    const { order } = useAppSelector(state => state.order);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

    const dispatch = useAppDispatch();
    const location = useLocation();
    const goTo     = useNavigate();
    const cart = useAppSelector(state => state.cart);

    const { 
        isLoading: subscribingClass,
        error: subscribingClassError,
        handle: handleClassSubscription
    } = useService({
        service: () => Promise.all(order.classes.map(classItem => 
            classSubscription({
                classId: classItem.class.id,
                paymentInfo: {
                    payment_method: order.payment?.payment_method,
                    payment_profile_id: order.payment?.payment_profile_id,
                    card: order.payment?.card,
                    save_profile: order.payment?.save_profile
                }
            })
        )),
        onLoading: () => toast.loading('Processando pagamento...'),
        onSuccess: () => { goTo('/minhas-compras') },
        onError: () => toast.error('Erro ao processar pagamento.')
    });

    const handleFinishOrder = () => {
        // TODO: Refatorar essa função
        const subscriptionOrder: ISubscription.Input = {
            classId: order.classes[0].class.id,
            payment: {
                payment_method     : order.payment?.payment_method,
                payment_profile_id : order.payment?.payment_profile_id,
                card               : order.payment?.card,
                save_profile       : order.payment?.save_profile
            }
        };

        if (!subscriptionOrder.payment?.payment_profile_id && !subscriptionOrder.payment?.card) {
            toast.error('Selecione um método de pagamento.', { duration: 15000, position: 'top-center' });
            return
        }

        handleClassSubscription();
    };

    useEffect(() => {
        if(!cart.cartList.length && !order.classes.length) goTo('/');
    }, [cart]);    

    useEffect(() => {
        // Caso o usuário tente acessar essa página diretamente, ele será redirecionado para a página inicial.
        if (!location.state || !order.classes.length ) goTo('/')

        dispatch(getPaymentProfiles())
    }, [])

    const handlePaymentMethodSelect = (method) => {
        setSelectedPaymentMethod(method);
    };

    return (
        <>
            <Banner />
            <PaymentBody title='Método de pagamento'>
                <ObjectAccordion
                    data={[
                        {
                            id: '1',
                            title: 'Cartão de Crédito',
                            description: '',
                            objectId: '',
                            isOpen: true,
                            // TODO: Corrigir o erro de tipagem
                            elementContent: <PaymentProfiles />
                        },
                        {
                            id: '2',
                            title: 'Pix',
                            description: '',
                            objectId: '',
                            isOpen: true,
                            elementContent: <PaymentPix />
                        },
                    ]}
                    onPaymentMethodSelect={handlePaymentMethodSelect}
                />
                <PaymentResume />
                <div className="col-xl-12 text-center">
                    <Button
                        className="resumoCompraBotaoPagar"
                        type="submit"
                        disabled={subscribingClass || selectedPaymentMethod === 'Pix'}
                        loading={subscribingClass}
                        onClick={handleFinishOrder}
                    >
                        <i className="uil uil-check"></i>
                        <span>Finalizar Compra</span>
                    </Button>
                </div>
            </PaymentBody>
            <Notification position="bottom-left"/>
        </>
    );
}