import { useAppSelector, useForm, useMask } from "hooks";
import Input from "components/Input";
import MultiSelect from "components/MultiSelect";
import Select from "components/Select";
import TextArea from "components/TextArea";
import { InputFile } from "components";
import { useEffect, useContext } from "react";
import { ObjectStatus, Status } from "enums";
import { ObjectInputModel } from "models";
import { DataContext } from "../..";
import { SESSION_STORAGE_KEYS } from "constants_";

export default function Form() {
  const TEMP_DATA = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.OBJECT_TEMP_DATA)) as ObjectInputModel;

  const {
    object,
    objectInputData,
    objectInputErrors,
    accordionsErrors,

    LOADING_PAGE,
    VIEW_ONLY,

    setObjectInputData,
    setAccordionsErrors,

    handleChangeValue,
    handleChangeMultiSelectValue,
    handleChangeValueWithMask,
    handleChangeFile,
    handleSubmit,
  } = useContext(DataContext);

  const { areas, types, modalities, isLoading: loadingObjectValues } = useAppSelector((state) => state.globalValues);

  const { handleMask } = useMask();

  const handleSaveTempData = () => {
    if (!object?.id)
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.OBJECT_TEMP_DATA,
        JSON.stringify({
          ...objectInputData,
          // areaId: objectInputData?.areaId ? objectInputData.areaId[0] : "",
          status: ObjectStatus.Creating,
        })
      );
      //console.log(TEMP_DATA.areaId);
  };

  /* TODO: Tratar erros caso o recurso não seja do parceiro ou não exista.
   */
  // const handleResponseErrorMessage = (error: any) => {
  //     if (!error) return

  //     let message: string;

  //     const is404 = error.status === 404
  //     const is401 = error.status === 401

  //     if (is404 || is401) {
  //         if (404) message = 'Recurso não Encontrado.'
  //         if (401) message = errorData.data.message
  //         goTo('/cadastro/recurso', { replace: true })
  //     } else message = errorData.data.message

  //     toast.error(message)

  useEffect(() => {
    if (object)
      setObjectInputData({
        ...object,
        areaId: object.area?.id,
        typeId: object.type?.id,
        file: null,
        price: handleMask(String(object.price), "currency"),
      });
  }, [object?.id]);

  useEffect(() => {
    if (TEMP_DATA) {
      setObjectInputData({
        title: TEMP_DATA?.title || "",
        description: TEMP_DATA?.description || "",
        modality: (TEMP_DATA?.modality as any) || "",
        typeId: TEMP_DATA?.typeId || "",
        areaId: TEMP_DATA?.areaId || "",
        file: null,
        price: TEMP_DATA?.price || "",
      });
    }
  }, []);

  useEffect(() => {
    if (!!Object.keys(objectInputErrors).length) {
      setAccordionsErrors({ ...accordionsErrors, 0: true });
    } else setAccordionsErrors({ ...accordionsErrors, 0: false });
  }, [objectInputErrors]);
 
  useEffect(() => {
    if (objectInputData.price === undefined){
      objectInputData.price = "0,00"
    }
  },[objectInputData.price])

  return (
    <form
      onMouseLeave={(e) => {
        handleSaveTempData();
        handleSubmit(e);
      }}
    >
      <div className="row">
        <div className="col-xl-6">
          <Select
            placeholder="Selecione um Tipo de Recurso..."
            data={types.filter((type) => type.status === 'A').map((type) => ({
              text: type.name,
              value: type.id,
            }))} 
            label="Tipo de Recurso *"
            value={objectInputData.typeId}
            messageError={objectInputErrors.typeId}
            onChange={handleChangeValue("typeId")}
            disabled={LOADING_PAGE || VIEW_ONLY}
          />
        </div>
        <div className="col-xl-6">
          <Select
            placeholder="Selecione uma Modalidade..."
            data={modalities}
            label="Modalidade *"
            value={objectInputData.modality}
            messageError={objectInputErrors.modality}
            onChange={handleChangeValue("modality")}
            disabled={LOADING_PAGE || VIEW_ONLY}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <MultiSelect
            placeholder="Selecione uma Área..."
            data={areas.filter((area) => area.status === Status.Active).map((area) => ({ value: area.id, text: area.name }))}
            values={
              // Se AreaId existir, então o recurso já foi cadastrado e deve ser exibido o valor do recurso
              !!object?.id && object?.area?.id
                ? areas.filter((area) => area.id === object.area.id).map((area) => ({ value: area.id, label: area.name }))
                : // Se não, então o recurso está sendo cadastrado e deve ser exibido o valor do TEMP_DATA (se existir)
                TEMP_DATA?.areaId
                ? areas.filter((area) => TEMP_DATA?.areaId.includes(area.id)).map((area) => ({ value: area.id, label: area.name }))
                : undefined
            }
            label="Área do Recurso *"
            onChange={handleChangeMultiSelectValue("areaId")}
            messageError={objectInputErrors.areaId}
            isLoading={loadingObjectValues}
            disabled={LOADING_PAGE || VIEW_ONLY}
          />
        </div>

        <div className="col-xl-6">
          <Input
            name="cp_preco"
            placeholder="R$0.00"
            type="text"
            value={objectInputData.price}
            messageError={objectInputErrors.price}
            onChange={handleChangeValueWithMask("price", "currency")}
            label="Preço"
            disabled={LOADING_PAGE || VIEW_ONLY}
          />
        </div>
      </div>

      <Input
        name="cp_name"
        placeholder="Insira o Nome do Recurso..."
        type="text"
        value={objectInputData.title}
        messageError={objectInputErrors.title}
        onChange={handleChangeValue("title")}
        label="Título *"
        disabled={LOADING_PAGE || VIEW_ONLY}
      />
      <TextArea
        name="cp_description"
        placeholder="Insira a Descrição do Recurso..."
        label="Descrição *"
        rows={3}
        maxLength={600}
        showTextLimit={true}
        value={objectInputData.description}
        messageError={objectInputErrors.description}
        onChange={handleChangeValue("description")}
        disabled={LOADING_PAGE || VIEW_ONLY}
      />

      <InputFile
        label="Imagem *"
        size="sm"
        messageError={objectInputErrors.file}
        onChange={handleChangeFile("file")}
        disabled={LOADING_PAGE || VIEW_ONLY}
      />
    </form>
  );
}
