import axios from "axios";

export const api_ = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

api_.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('accessToken')
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
})

api_.interceptors.response.use((response) => {
    return response
}, (error) => {
    if ([401].includes(error.response.status)) {
        localStorage.clear()
        window.location.href = '/'
    } else throw error
})

export const apiRoutes = {
    v1: {
        users: '/v1/users',
        services: '/v1/services',
        courseIntegration: '/v1/services/courseIntegration',
        objects: '/v1/objects',
        classes: '/v1/classes',
        class: '/v1/class', // TODO: Tranformar em uma unica rota, "classes" e "class".
        areas: '/v1/areas',
        types: '/v1/types',
        auth: '/v1/auth',
        partners: '/v1/partners',
        login: '/v1/login',
        content: '/v1/content',
        contents: '/v1/contents', // TODO: Tranformar em uma unica rota, "contents" e "content".
        subContent: '/v1/subContent',
        admin: '/v1/admin',
        subscriptions: '/v1/subscriptions',
        paymentProfiles: '/v1/paymentProfiles',
        orders: '/v1/orders',
        cart: '/v1/cartItems'
    }
}