import { useState, useEffect, useContext } from "react";
import { Button, Item, Notification } from "components";
import BoxList from "components/BoxList";
import { useAppSelector, useService } from "hooks";
import toast from "react-hot-toast";
import ClassInfos from "./ClassInfos";
import { ObjectRegisterContext, PaginationProvider } from "contexts";
import { getClassesByObject } from "services_/Class";
import { ClassViewModel } from "models";
import "./style.css";
import ClassSubscriptions from "./Subscriptions";
import { useDispatch } from "react-redux";
import { setShowOnlyClass } from "slices/StepsBoxSlice";
import { isDeadlinePassed } from "Functions";
import { TAddress } from "types";

export default function Classes() {
  const { object, classes, setClasses } = useContext(ObjectRegisterContext);
  const [selectedClass, setSelectedClass] = useState<ClassViewModel>(null);
  const goToInfos = () => {
    const anchor = document.querySelector("#classInfos");
    anchor.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };
  const handleAddClass = (title, info, limit, link, address: TAddress) => {
    const newClass: ClassViewModel = {
      id: `classTemp-${classes.length + 1}`,
      objectId: object?.id,
      statusBool: false,

      //caso adicione pela tela de turma
      title: title || "",
      info: info || "",
      limitOfUsers: limit || 0,
      link: link || "",
      address: address || null,
      // expired: false,
    };
    setSelectedClass({ ...newClass });
    // setManageSubscriptions(!manageSubscriptions)
  };

  useEffect(() => {
    //timout corrige bug de aguardar a classe ser setada
    selectedClass?.id && setTimeout(() => goToInfos(), 10);
  }, [selectedClass]);
  const { isLoading: loadingClasses, handle: handleGetClasses } = useService({
    service: () => getClassesByObject({ objectId: object?.id }),
    onLoading: () => toast.loading("Carregando turmas...", { duration: null }),
    onSuccess: (res) => {
      toast.dismiss();
      setClasses(res);
    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.message || "Erro ao carregar turmas", { duration: 15000 , position: 'top-center'});
    },
  });

  const LOADING_PAGE = loadingClasses;

  useEffect(() => {
    if (!classes.length) handleGetClasses();
  }, []);

  const [manageSubscriptions, setManageSubscriptions] = useState<boolean | " ">(" ");
  const dispatch = useDispatch();
  useEffect(() => {
    !manageSubscriptions || manageSubscriptions == " "
      ? dispatch(setShowOnlyClass(false))
      : dispatch(setShowOnlyClass(true));
  }, [manageSubscriptions]);

  return (
    <>
      <div className={`row registerClassContent`}>
        <div
          className={` row ${
            manageSubscriptions && manageSubscriptions != " " ? "hidden" : !manageSubscriptions && "slide-right"
          }`}
        >
          <BoxList
            title="Turmas"
            size="6"
            showSearchBar={false}
            buttonFunction={handleAddClass}
            emptyListMessage={LOADING_PAGE ? "Carregando turmas..." : "Nenhuma turma cadastrada"}
          >
            {!!classes?.length &&
              classes.map((class_, index) => (
                <div key={class_.id}>
                  <div
                    className="item"
                    onClick={() => {
                      setSelectedClass({ ...class_ });
                      // goToInfos();
                    }}
                  >
                    <Item
                      title={class_.title}
                      description={!class_.expired ? (class_.statusBool ? "Aberta" : "Fechada") : "Turma Encerrada"}
                      type="class"
                      selected={selectedClass && selectedClass.id == class_.id}
                    />
                  </div>
                </div>
              ))}
          </BoxList>

          {!!object?.contents?.length && (
            <>
              <ClassInfos
                // handleGoToInfos={goToInfos}
                data={selectedClass}
                handleSetClass={setSelectedClass}
                setManageSubscriptions={setManageSubscriptions}
                handleAddClass={handleAddClass}
              />
            </>
          )}
        </div>
        
        <div
          className={`col-xl-12 ${
            manageSubscriptions == " " ? "hidden" : manageSubscriptions ? "slide-left" : "slide-right"
          }`}
          style={{
            marginTop: "0px",
            minHeight: "600px",
          }}
          // width: '100%'
        >
          {manageSubscriptions == true && selectedClass && (
            <PaginationProvider>
              <ClassSubscriptions setManageSubscriptions={setManageSubscriptions} selectedClass={selectedClass} />
            </PaginationProvider>
          )}
        </div>
        <Notification position="bottom-left" />
      </div>
      
      {/* <button
        style={{ position: "absolute", width: "50px" }}
        onClick={() => setManageSubscriptions(manageSubscriptions == " " ? true : !manageSubscriptions)}
      >
        asd
      </button> */}
    </>
  );
}
