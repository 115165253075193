import { apiRoutes, api_ } from "config";

export namespace IAddItemsToCart {
    export type Input = {
        classId: string;
        userId: string;
    }
    export type Output = {
        listCartItems: string[];
    }
}

export const addItemsToCart = async (data: IAddItemsToCart.Input) : Promise<IAddItemsToCart.Output> => {
    const url = `${apiRoutes.v1.cart}/class/${data.classId}`

    const response = await api_.post(url, data);
    return response.data
}
