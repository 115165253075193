import {  apiRoutes, api_ } from "config";

export namespace IDeleteCartItens {
    export type Input = {
        userId: string;
        listOfIds: string[];
    }
    export type Output = void
}

export const deleteCartItens = async (data: IDeleteCartItens.Input): Promise<IDeleteCartItens.Output> => {
    const url = `${apiRoutes.v1.cart}`

    const response = await api_.patch(url, data);
    return response.data
}