import { Navigate } from "react-router-dom";
import { UserTypes } from "enums";

type Props = {
    element: JSX.Element;
    authLevel?: UserTypes;
}

export default function PrivateRoute({ element, authLevel }: Props) {
    const storageToken = localStorage.getItem('accessToken');
    const storageProfileSelected = localStorage.getItem('profileSelected');
    
    const handleAuthLevel = (): boolean => {
        let canAccess = false;
        if (authLevel === storageProfileSelected) {
            /* Caso o usuário seja um aprendiz e parceiro, e o nível de acesso seja um aprendiz ou parceiro, e ele estiver com um desses 
            * dois perfis selecionados, ele pode acessar.
            */
           if (UserTypes.userIsApprenticeAndPartner(storageProfileSelected) &&
           [UserTypes.Apprentice, UserTypes.Partner].includes(authLevel)) canAccess = true;
           else if (storageProfileSelected === authLevel) canAccess = true;
        }
        return canAccess;
    }
    
    const SHOW_ELEMENT = storageProfileSelected && storageToken && handleAuthLevel();

    return SHOW_ELEMENT ? element : <Navigate to={'/'} />
}