
export const fixDateTime = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    const toInput = (): string => {
        const fix0 = (value: number) => value < 10 ? `0${value}` : value

        return `${fix0(year)}-${fix0(month)}-${fix0(day)}T${fix0(hours)}:${fix0(minutes)}`
    }
    const toDate = (): Date => { return new Date(year, month, day, hours, minutes, seconds) }

    return { toInput, toDate }
}

export const calculateTime = (date: Date): string => {
    date = new Date(date);
    const today = new Date();
    const diff = (today.getFullYear() - date.getFullYear()) * 12 + (today.getMonth() - date.getMonth());

    if (diff < 1) return "Menos de um mês";
    else if (diff < 12) return diff > 1 ? `${diff} meses` : `${diff} mês`;
    else {
        const years = Math.floor(diff / 12);
        const months = diff % 12;

        if (months === 0) return years > 1 ? `${years} anos` : `${years} ano`;
        else return `${years > 1 ? `${years} anos` : `${years} ano`} e ${months > 1 ? `${months} meses` : `${months} mês`}`;
    }
}

export const cancellablePromise = (promise: Promise<any>) => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
            error => reject({ isCanceled, error }),
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => { isCanceled = true }
    }
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))