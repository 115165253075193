import { ClassViewModel } from "models";
import ClassCard from "./Card";
import { isDeadlinePassed } from "Functions";

type Props = {
  list: Omit<ClassViewModel[], "status">;
};
export default function ObjectClass({ list }: Props) {
  return (
    <>
      <h4 className="titulo" id="turmas">
        Turmas Disponíveis
      </h4>

      {list.map(
        (class_, index) =>
          (
            !isDeadlinePassed(class_.subscriptionDeadline) &&
            !isDeadlinePassed(class_.endDate)
          ) && (
            <ClassCard
              key={index}
              name={class_.title}
              startDate={
                class_.startDate ?
                  String(class_.startDate)
                  : null
              }
              endDate={
                class_.endDate ?
                  String(class_.endDate)
                  : null
              }
              id={class_.id} 
            />
          )
      )}
    </>
  );
}
