import D2LService from "./D2L";
import { Select } from "components";
import { IField } from "interfaces";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../..";
import { CourseIntegrationProviders } from "enums";
import { SESSION_STORAGE_KEYS } from "constants_";
import { CourseIntegrationInputModel } from "types";
import "./style.css";

const services = {
  [""]: <h5 style={{ paddingTop: "25px" }}>Nenhum serviço selecionado.</h5>,
  [CourseIntegrationProviders.D2L]: <D2LService />,
};

export default function SelectServices() {
  const { setProviderInputData, setAccordionsErrors, accordionsErrors } = useContext(DataContext);

  const { LOADING_PAGE, VIEW_ONLY } = useContext(DataContext);

  const TEMP_DATA = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.COURSE_INTEGRATION_TEMP_PROVIDER_DATA)) as Pick<
    CourseIntegrationInputModel,
    "provider"
  >;
  const [selected, setSelected] = useState<string>(TEMP_DATA?.provider || "");

  const PROVIDERS: IField[] = Object.values(CourseIntegrationProviders).map((provider) => ({ text: provider, value: provider }));
  useEffect(() => {
    if (selected == "") {
      setProviderInputData({} as any);
      setAccordionsErrors({ ...accordionsErrors, 1: false });
    }
  }, [selected]);
  return (
    <>
      <Select
        data={PROVIDERS}
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
        placeholder="Selecione um serviço de integração..."
        label="Serviço de Integração"
        disabled={VIEW_ONLY || LOADING_PAGE}
      />
      <div className=""> {services[selected]} </div>
    </>
  );
}
