import { BrowserRouter, Routes as RoutesList, Route, Navigate } from "react-router-dom";
import { ApprenticeHome, Home, PartnerHome, ChangePassword, ObjectRegister, Object, Search, ApprenticeResource, Payment, Wallet, Orders } from "pages/public";
import App from "./App";
import PublicApp from "./PublicApp";
import { UserTypes } from "./enums";
import PrivateRoute from "./components/templates/app/PrivateRoute";
import AdminApp from "AdminApp";
import { AdminChangePassword, AdminHome, AdminLogin, ObjectApprobation, ObjectRegisterOptions } from "pages/admin";
import { HighlightedObjectsProvider ,ObjectAnalysisProvider,ObjectRegisterOptionsProvider, ObjectRegisterProvider, ObjectsSearchProvider } from "contexts";
import ControlRoute from "components/templates/app/RouteControl";

export default function Routes() {
    const storageProfileSelected = localStorage.getItem('profileSelected')

    const handleSetHome = (): JSX.Element => {
        if (!storageProfileSelected) return <Navigate to="/" replace/>
        if (storageProfileSelected === UserTypes.Apprentice) return (
            <PrivateRoute 
                element={<HighlightedObjectsProvider children={<ApprenticeHome />} />} 
                authLevel={UserTypes.Apprentice}
            />
        )
        else if (storageProfileSelected === UserTypes.Partner) return (
            <PrivateRoute 
                element={ <ObjectsSearchProvider children={<PartnerHome />}/> } 
                authLevel={UserTypes.Partner}
            />
        )
        else return (
            <Navigate to="/" />
        )
        
    }

    return (
        <BrowserRouter>
            <RoutesList>

                {/* ------------- Rotas Públicas ------------------------------------------------- */}
                <Route element={<PublicApp />}>
                    <Route 
                        path="/" 
                        element={
                            <ControlRoute 
                                omit={{ 
                                    for: [UserTypes.Partner, UserTypes.Admin], 
                                    redirect: {
                                        P: '/home',
                                        ADMIN: '/admin/home'
                                    }
                                }} 
                                element={<HighlightedObjectsProvider children={<Home />} />}
                            />
                        }
                    />
                    <Route 
                        path="recursos" 
                        element={
                            <ControlRoute 
                                omit={{ 
                                    for: [UserTypes.Partner, UserTypes.Admin], 
                                    redirect: {
                                        P: '/home',
                                        ADMIN: '/admin/home'
                                    }
                                }} 
                                element={<ObjectsSearchProvider children={<Search />} />}
                            />
                        }
                    />
                    <Route 
                        path="/recurso/:objectId" 
                        element={
                            <ControlRoute 
                                omit={{ 
                                    for: [UserTypes.Partner, UserTypes.Admin], 
                                    redirect: {
                                        P: '/home',
                                        ADMIN: '/admin/home'
                                    }
                                }} 
                                element={<Object />}
                            />
                        }
                    />
                    <Route 
                        path="/recurso/:objectId/turma/:classId" 
                        element={
                            <ControlRoute 
                                omit={{ 
                                    for: [UserTypes.Partner, UserTypes.Admin], 
                                    redirect: {
                                        P: '/home',
                                        ADMIN: '/admin/home'
                                    }
                                }}  
                                element={<Object />}
                            />
                        }
                    />
                    <Route 
                        path="/recuperarAcesso" 
                        element={
                            <ControlRoute 
                                omit={{ 
                                    for: [UserTypes.Apprentice,UserTypes.Partner, UserTypes.Admin], 
                                    redirect: {
                                        A: '/pages/public/Home',
                                        P: '/home',
                                        ADMIN: '/admin/home'
                                    }
                                }}  
                                element={<ChangePassword />}
                            />
                        } 
                    />
                </Route>
                {/* ------------------------------------------------------------------------------ */}
                
                {/* -------------------- Rotas Privada ------------------------------------------- */}
                <Route element={<App />}>
                    <Route 
                        path="/home" 
                        element={handleSetHome()}
                    />
                    <Route 
                        path="/minhas-turmas" 
                        element={
                            <PrivateRoute 
                                element={<ApprenticeResource />} 
                                authLevel={UserTypes.Apprentice} 
                            />
                        }
                    />
                    <Route 
                        path="/cadastro/recurso" 
                        element={
                            <PrivateRoute 
                                element={
                                    <ObjectRegisterProvider children={<ObjectRegister />} />
                                } 
                                authLevel={UserTypes.Partner} 
                            />
                        } 
                    />
                    <Route 
                        path="/cadastro/recurso" 
                        element={
                            <PrivateRoute 
                                element={
                                    <ObjectAnalysisProvider children={<ObjectApprobation />} />
                                } 
                                authLevel={UserTypes.Partner} 
                            />
                        }
                    />
                    <Route 
                        path="/cadastro/recurso/:objectId" 
                        element={
                            <PrivateRoute 
                                element={
                                    <ObjectRegisterProvider children={<ObjectRegister />} />
                                } 
                                authLevel={UserTypes.Partner} 
                            />
                        } 
                    />
                    <Route 
                        path="/pagamento" 
                        element={
                            <PrivateRoute 
                                element={<Payment />} 
                                authLevel={UserTypes.Apprentice} 
                            />
                        } 
                    />
                    {/* <Route 
                        path="/carteira" 
                        element={
                            <PrivateRoute 
                                element={<Wallet />} 
                                authLevel={UserTypes.Apprentice} 
                            />
                        } 
                    /> */}
                    <Route 
                        path="/minhas-compras" 
                        element={
                            <PrivateRoute 
                                element={<Orders />} 
                                authLevel={UserTypes.Apprentice} 
                            />
                        } 
                    />
                </Route>
                {/* ------------------------------------------------------------------------------ */}

                <Route 
                    path="/admin/login" 
                    element={
                        <ControlRoute
                            omit={{ for: [UserTypes.Apprentice, UserTypes.Partner] }}
                            element={<AdminLogin />}
                        />
                    } 
                />
                <Route path="/admin/recuperarAcesso" element={<AdminChangePassword />} />
                <Route element={<AdminApp />}>
                    <Route 
                        path="/admin/home" 
                        element={
                            <PrivateRoute
                                element={<AdminHome />}
                                authLevel={UserTypes.Admin}
                            />
                        } 
                    />
                    <Route 
                        path="/admin/recurso/:objectId" 
                        element={
                            <PrivateRoute
                                element={
                                    <ObjectAnalysisProvider children={
                                            <ObjectApprobation />
                                        }
                                    />
                                }
                                authLevel={UserTypes.Admin}
                            />
                        } 
                    />
                    <Route 
                        path='/admin/cadastros/:type' 
                        element={
                            <PrivateRoute
                                element={
                                    <ObjectRegisterOptionsProvider 
                                        children={<ObjectRegisterOptions />}
                                    />
                                }
                                authLevel={UserTypes.Admin}
                            />
                        }
                    />
                </Route>

                <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
            </RoutesList>
        </BrowserRouter>
    )
}
