import { apiRoutes, api_ } from "config";
import { Status } from "enums";
import { ClassInputModel, ClassViewModel } from "models";
import { TAddress } from "types";

export namespace ICreateClass {
    export type Input = 
        Omit<ClassInputModel, 'address'> 
        & TAddress
    export type Output = ClassViewModel;
}

export const createClass = async (data: ICreateClass.Input): Promise<ICreateClass.Output> => {
    const body: ClassInputModel = {
        ...data,
        status: data.statusBool ? Status.Active : Status.Inactive,
        limitOfUsers: Number(data.limitOfUsers),
        startDate: data.startDate,
        endDate: data.endDate,
        subscriptionDeadline: data.subscriptionDeadline,
        address: {
            address: data.address,
            city: data.city,
            cep: data.cep,
            state: data.state,
            neighborhood: data.neighborhood,
            number: data.number,
        }
    }

    const url = apiRoutes.v1.class;
    const response = await api_.post<ICreateClass.Output>(url, body);
    return response.data;
}