import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, ButtonArea, Container, Input, Loading, Modal, Notification } from "components";
import { useAppDispatch, useAppSelector, useForm, usePasswordState, useService } from "hooks";
import { IAuth, IAuthSteps } from "interfaces";
import { openSteps } from "slices/AuthStepsSlice";
import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import "./style.css";
import { IPasswordReset, passwordReset } from "services_/User";
import { UserContext } from "contexts";

enum Status {
  Success = 0,
  Error = 1,
  PasswordChanged = 2,
  Loading = 3,
}

const returnMessage = (status: Status, action: () => void) => {
  const messages = {
    [Status.PasswordChanged]: { main: "Senha alterada com sucesso!", secondary: "Realizar login." },
    [Status.Error]: { main: "Link inválido ou expirado.", secondary: "Reenviar uma solicitação." },
  };

  const icons = {
    [Status.PasswordChanged]: "uil uil-check-circle",
    [Status.Error]: "uil uil-exclamation-circle",
  };

  return (
    <div className="col-xl-12">
      <div className={`${icons[status]} icon ${status === Status.PasswordChanged ? "success" : "error"}`} />
      <div className="col-xl-12 label">{messages[status].main}</div>
      <div className="col-xl-12 linhaLinks-centered" onClick={action}>
        <strong>{messages[status].secondary}</strong>
      </div>
    </div>
  );
};

export default function PasswordChange() {
  const [params] = useSearchParams();
  const [status, setStatus] = useState(Status.Error);
  const [token, setToken] = useState("");

  const goTo = useNavigate();
  const { authenticated } = useContext(UserContext);
  const { passwordState, handleChangeStatePassword } = usePasswordState();
  const { data, errors, handleChangeValue, handleSubmit } = useForm<IPasswordReset.Input>({
    validations: {
      password: {
        required: true,
      },
      confirmPassword: {
        required: true,
      },
    },
    onSubmit: () => submitPassword(),
  });

  const dispatch = useAppDispatch();

  const handleOpenPasswordRecovery = () => dispatch(openSteps({ step: IAuthSteps.Steps.PASSWORD_RECOVERY }));
  const handleOpenLogin = () => dispatch(openSteps({ step: IAuthSteps.Steps.LOGIN }));

  const { isLoading: changingPassword, handle: submitPassword } = useService({
    service: () =>
      toast.promise(passwordReset({ ...data, token: token }), {
        loading: "Alterando senha...",
        success: "Senha alterada com sucesso!",
        error: (err) => err?.response?.data?.message || "Erro ao alterar senha.",
      }),
    onSuccess: () => setStatus(Status.PasswordChanged),
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    const token = params.get("token");
    const decodedToken = token ? jwtDecode<IAuth.ResetPasswordToken>(token) : null;
    if (token) {
      const isTokenValid = decodedToken && decodedToken.exp > Date.now() / 1000;
      if (isTokenValid) {
        setToken(token);
        setStatus(Status.Success);
      }
    } else setStatus(Status.Error);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") && authenticated) goTo("/", { replace: true });
  }, [localStorage.getItem("token"), authenticated]);
  return (
    <>
      <Container.Normal>
        <Box title="Recuperação de Senha">
          {!changingPassword ? (
            <form onSubmit={handleSubmit}>
              <div className="row recovery-container">
                {status === Status.Success && (
                  <>
                    <Input
                      type="password"
                      // icon="uil-lock"
                      placeholder="Nova senha *"
                      name="cp_senha"
                      passwordState={passwordState}
                      changePasswordState={handleChangeStatePassword}
                      value={data.password}
                      messageError={errors.password}
                      onChange={handleChangeValue("password")}
                    />
                    <Input
                      type="password"
                      // icon="uil-lock"
                      placeholder="Confirmação de senha *"
                      name="cp_confimacaoSenha"
                      passwordState={passwordState}
                      changePasswordState={handleChangeStatePassword}
                      value={data.confirmPassword}
                      messageError={errors.confirmPassword}
                      onChange={handleChangeValue("confirmPassword")}
                    />
                    <ButtonArea className="login">
                      <Button type="submit">Confirmar</Button>
                    </ButtonArea>
                  </>
                )}
                {status === Status.Error && returnMessage(Status.Error, handleOpenPasswordRecovery)}
                {status === Status.PasswordChanged && returnMessage(Status.PasswordChanged, handleOpenLogin)}
                {status === Status.Loading && (
                  <div className="col-xl-12 recovery-container-loading">
                    <Loading.Small loading={true} />
                  </div>
                )}
              </div>
            </form>
          ) : (
            <Loading.Small loading={changingPassword} />
          )}
          <Notification position={"bottom-right"} />
        </Box>
      </Container.Normal>
    </>
  );
}
