import { apiRoutes, api_ } from "config";


export namespace IGetCertificate {
    export type Input = {
        classId: string;
        userId: string;
    }
    export type Output = {
        certificateURL: string;
    }
}

export const getCertificate = async (data: IGetCertificate.Input): Promise<IGetCertificate.Output> => {
    const url = `${apiRoutes.v1.subscriptions}/class/${data.classId}/certificate`;

    const response = await api_.get(url);
    return response.data;
}