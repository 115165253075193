import { useState } from "react";

type Error = {
    response: {
        status: number;
        data: { message: string };
    };
};
type CallbackFn = () => void;

type Props<T> = {
    service: () => Promise<T>;
    onLoading?: CallbackFn;
    onSuccess?: (response: T) => void;
    onError?: (error: Error | null) => void;
}

type Response<T> = {
    isLoading: boolean;
    error: Error | null;
    handle: () => Promise<T>;
}


export const useService = <T> ({ service, onError, onLoading, onSuccess }: Props<T>): Response<T> => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any | null>(null);

    const handle = async (): Promise<T> => {
        setIsLoading(true);
        setError(null);

        try {
            if (onLoading) onLoading();

            const data = await service();
            if (onSuccess) onSuccess(data);

            return data;
        } catch (error) {
            setError(error);
            if (onError) onError(error);
        } finally { setIsLoading(false); }
    }

    return { isLoading, error, handle };
}