import { Box, Button, ButtonArea, Input, Modal, TextArea } from "components"
import { useContext, useEffect } from 'react'
import { ContentOrSubContent, ContentRegisterContext } from "../ContentRegisterContext"
import { useForm, useService } from "hooks"
import { ContentInputModel, ContentViewModel, SubContentViewModel } from "models"
import { ObjectRegisterContext } from "contexts"
import { toast } from "react-hot-toast"
import { createContent, updateContent } from "services_/Content"
import { createSubContent, updateSubContent } from "services_/SubContent"
import './style.css'

type Props = {
    show: boolean
    onClose: () => void
}
export default function ContentModal({ show = false, onClose }: Props) {
    const { contentAction, contentType, selectedContent } = useContext(ContentRegisterContext)
    const { object, setObject } = useContext(ObjectRegisterContext)

    const TYPE = contentType === 'content' ? 'Conteúdo' : 'SubConteúdo'
    const TITLE = contentAction === 'create' ? `Cadastro de ${TYPE}` : `Edição de ${TYPE}`

    const {
        data,
        errors,
        handleChangeValue,
        handleSubmit,
        handleCleanForm,
        setData
    } = useForm<Pick<ContentInputModel, 'title' | 'description'>>({
        validations: {
            title: { required: contentAction === 'create' },
            description: { required: contentAction === 'create' }
        },
        onSubmit: () => {
            if (contentAction === 'create') handleCreateContentOrSubContent()
            else handleUpdateContentOrSubContent()
        }
    })

    const {
        handle: handleCreateContentOrSubContent,
        isLoading: creatingContentOrSubContent
    } = useService<ContentOrSubContent>({
        service: () => {
            if (contentType === 'content') return createContent({ ...data, objectId: object.id })
            else return createSubContent({ ...data, contentId: selectedContent?.id })
        },
        onLoading: () => toast.loading(`Criando ${TYPE}...`),
        onSuccess: (res) => {
            toast.dismiss()
            toast.success(`${TYPE} criado com sucesso!`)

            if (contentType === 'content') {
                if (!object?.contents?.length) setObject({ ...object, contents: [res] })
                else setObject({ ...object, contents: [...object?.contents, { ...res }] })

            } else {
                const updatedContents = object?.contents?.map((content) => {
                    if (content.id === selectedContent?.id) {
                        const subContents = content.subContents || [];
                        return { ...content, subContents: [...subContents, res] };
                    }
                    return content;
                }) as ContentViewModel[];

                setObject({ ...object, contents: updatedContents });
            }

            handleCleanForm()
            onClose()
        },
        onError: (err) => {
            toast.dismiss()
            toast.error(err?.response?.data?.message, { duration: 15000 , position: 'top-center'})
        },
    })

    const {
        handle: handleUpdateContentOrSubContent,
        isLoading: updatingContentOrSubContent
    } = useService<ContentOrSubContent>({
        service: () => {
            if (contentType === 'content') return updateContent({ ...data, id: selectedContent?.id })
            else return updateSubContent({ ...data, id: selectedContent?.id })
        },
        onLoading: () => toast.loading(`Atualizando ${TYPE}...`),
        onSuccess: (res) => {
            toast.dismiss()
            toast.success(`${TYPE} atualizado com sucesso!`)

            if (contentType === 'content') {
                const updatedContents = object?.contents?.map((content) => {
                    if (content.id === res.id) return res
                    return content
                }) as ContentViewModel[]

                setObject({ ...object, contents: updatedContents })
            } else {
                const updatedContents = object?.contents?.map((content) => {
                    if (content.id === res.contentId) {
                        console.log('content', content)
                        const subContents = content.subContents?.map((subContent) => {
                            if (subContent.id === res.id) {
                                console.log('subContent', subContent)
                                return res
                            }
                            return subContent
                        }) as SubContentViewModel[]
                        return { ...content, subContents }
                    }
                    return content
                }) as ContentViewModel[]

                setObject({ ...object, contents: updatedContents })
            }

            handleCleanForm()
            onClose()
        },
        onError: (err) => {
            toast.dismiss()
            toast.error(err?.response?.data?.message, { duration: 15000 , position: 'top-center'})
        }
    })

    const LOADING = creatingContentOrSubContent || updatingContentOrSubContent

    useEffect(() => {
        if (selectedContent?.id && contentAction === 'edit') setData({
            title: selectedContent?.title,
            description: selectedContent?.description
        })
    }, [selectedContent?.id])

    return (
        <Modal
            show={show}
            onClose={() => {
                handleCleanForm()
                onClose()
            }}
            isLoading={LOADING}
            size="lg"
            className="content-modal"
        >
            <form onSubmit={handleSubmit}>
                <Box title={TITLE}>
                    <div className="row">
                        <Input
                            type="text"
                            name="cp_title"
                            label="Título"
                            disabled={LOADING}
                            placeholder="Ex: Módulo 1 - Introdução"
                            className="content-input-title"
                            onChange={handleChangeValue('title')}
                            value={data.title}
                            messageError={errors.title}
                        />
                    </div>
                    <div className="row">
                        <TextArea
                            name="cp_description"
                            label="Descrição"
                            placeholder="Ex: Neste módulo você aprenderá sobre..."
                            disabled={LOADING}
                            maxLength={600}
                            rows={5}
                            maxHeight={700}
                            showTextLimit
                            onChange={handleChangeValue('description')}
                            value={data.description}
                            messageError={errors.description}
                        />
                    </div>
                </Box>

                <ButtonArea className="content-modal-action">
                    <Button
                        className="content-modal-button"
                        type="submit"
                        disabled={LOADING}
                        loading={LOADING}
                    >
                        {contentAction === 'create' ? 'Cadastrar' : 'Salvar'}
                    </Button>
                </ButtonArea>
            </form>
        </Modal>
    )
}