import BannerP from '../../../../assets/images/banner_01_p.jpg'
import BannerM from '../../../../assets/images/banner_01_m.jpg'
import BannerG from '../../../../assets/images/banner_01_g.jpg'
import Forma from '../../../../assets/images/forma.png'
import './style.css'


export default function HomeBanner() {
    return (
        <div id="banner">
            <picture>
                <source media="(max-width: 360px)" srcSet={BannerP}/>
                <source media="(max-width: 1024px)" srcSet={BannerM}/>
                <img src={BannerG} alt="banner" />
            </picture>

            <div className='forma'>
                <img src={Forma} alt="forma" />
            </div>
        </div>
    )
}