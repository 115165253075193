import { Box, BoxList, Container, ListItems, Notification, SearchFilters } from "components";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { closeSteps, openSteps } from "slices/AuthStepsSlice";
import { getObjects, IGetObjects } from "services_/Object";
import { useContext, useEffect, useState } from 'react'
import { ObjectsSearchContext, UserContext } from "contexts";
import { useNavigate } from "react-router-dom";
import { IAuthSteps } from "interfaces";
import { toast } from "react-hot-toast";
import './style.css'
import { AreaModel, TypeModel } from "models";

export default function PartnerHome() {
    const [foundObjects, setFoundObjects] = useState<IGetObjects.Output>([]);
    const { objectStatus, types, areas } = useAppSelector((state) => state.globalValues)
    
    const { userInfo } = useContext(UserContext)
    
    const { handleSetObjectFilter, getSearchValues, page, searchParams } = useContext(ObjectsSearchContext);

    const ITEMS_PER_PAGE = 16

    const AREAS_FROM_OBJECTS: AreaModel[] = foundObjects?.map((object) => {
        const { area } = object;

        const area_ = areas.find((area_) => area_.id === area.id)
        return area_
    }).filter((area, index, self) => self.findIndex((a) => a?.id === area?.id) === index)
    
    const TYPES_FROM_OBJECTS: TypeModel[] = foundObjects?.map((object) => {
        const { type } = object;
        
        const type_ = types.find((type_) => type_.id === type.id)
        return type_
    }).filter((type, index, self) => self.findIndex((t) => t?.id === type?.id) === index)

    const goTo     = useNavigate()
    const dispatch = useAppDispatch()

    const { 
        isLoading: loadingObjects, 
        error: objectsError,
        handle: handleGetObjects
    } = useService({
        service: () => getObjects({ search: getSearchValues({ size: ITEMS_PER_PAGE + 1, partnerId: userInfo?.userId })}),
        onLoading: () => toast.loading('Buscando recursos...', { duration: null }),
        onSuccess: () => toast.dismiss(),
        onError: () => {
            toast.dismiss()
            console.error('Erro ao buscar os recursos!')
        }
    })

    const handleRedirect      = () => {
        if (!userInfo?.emailVerified) {
            dispatch(openSteps({
                step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
                props: {
                    title: 'Confirmação de E-mail',
                    email: userInfo.email,
                    type: 'email',
                }
            }))
        } else goTo('/cadastro/recurso')
    }

    useEffect(() => {
        if (userInfo?.userId) handleGetObjects().then((res) => setFoundObjects(res)) 
    }, [searchParams, userInfo?.userId])

    useEffect(() => {
        if (page > 1) handleGetObjects().then((res) => setFoundObjects([...foundObjects, ...res]))
    }, [page])
    
    useEffect(() => { dispatch(closeSteps()) }, [])
    
  
    return (
        <Container.Fluid>
            <Box title="Painel do Parceiro">
                <div className="row">
                    <div className = 'partner-filters'>
                        <SearchFilters
                            types={TYPES_FROM_OBJECTS}
                            areas={AREAS_FROM_OBJECTS}
                            status={objectStatus}
                        />

                    </div>
                    
                    <BoxList 
                        title="Meus Recursos" 
                        size="12"  
                        showSearchBar={true} 
                        buttonFunction={handleRedirect}
                        onSearch={(value) => handleSetObjectFilter({ param: 'search', value, action: value ? 'append' : 'clear' })}
                        emptyListMessage={loadingObjects ? "Carregando recursos..." : "Você ainda não possui nenhum recurso cadastrado!"}
                    >
                        
                        {
                            !!foundObjects?.length &&
                                <ListItems
                                    data={{ objects: foundObjects }}
                                    listType="objects"
                                    maxItemShow={ITEMS_PER_PAGE}
                                    titleType="false"
                                    partnerObject= {true}
                                />
                          
                        }         
                    </BoxList>
                </div>
            </Box>
            <Notification position="bottom-left"/> 
        </Container.Fluid>
    )
}