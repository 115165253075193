import { useSearchParams } from "react-router-dom";
import { ObjectStatus, PaymentType } from "enums";
import { TObjectSearchInput } from "types";
import { createContext, useState } from "react";

type DefaultValues = Pick<TObjectSearchInput, 'size'> & Partial<Omit<TObjectSearchInput, 'size' | 'page'>>;

type Filter = {
    param: keyof Omit<TObjectSearchInput, 'size' | 'page'>;
    value: string;
    action: 'append' | 'remove' | 'clear'
}

type ContextProps = {
    page: number;
    setPage: (page: number) => void;
    handleSetObjectFilter: (filter: Filter) => void;
    getSearchValues: (defaultValues: DefaultValues) => TObjectSearchInput;
    searchParams: URLSearchParams;
}

type ProviderProps = {
    children: React.ReactNode;
}

export const ObjectsSearchContext = createContext<ContextProps>({} as ContextProps);
export const ObjectsSearchProvider = ({ children }: ProviderProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState<number>(1);

    const appendValue = ({ param, value }: Omit<Filter, 'action'>) => {
        const params = new URLSearchParams(searchParams);
        const currentValues = params.getAll(param);

        const valueAlreadyExists = currentValues.includes(value);

        if (!valueAlreadyExists) params.append(param, value);
        else params.set(param, value);
        
        setSearchParams(params);
    }

    const removeValue = ({ param, value }: Omit<Filter, 'action'>) => {
        const params = new URLSearchParams(searchParams);
        const currentValues = params.getAll(param);

        params.delete(param);

        const updatedValues = currentValues.filter(v => v !== value);
        updatedValues.forEach(v => params.append(param, v));

        setSearchParams(params);
    }

    const clearValue = ({ param }: Omit<Filter, 'action' | 'value'>) => { 
        const params = new URLSearchParams(searchParams);

        params.delete(param);  
        setSearchParams(params);
    }

    const handleSetObjectFilter = ({ param, action, value }: Filter) => {
        setPage(1); // Reinicia a paginação quando um filtro é alterado.

        const runAction = {
            append: appendValue,
            remove: removeValue,
            clear : clearValue
        }

        runAction[action]({ param, value });
    }

    const getSearchValues = (defaultValues?: DefaultValues): TObjectSearchInput => {
        return {
            areaId: defaultValues?.areaId || searchParams.getAll('areaId'),
            modality: defaultValues?.modality || searchParams.getAll('modality'),
            status: defaultValues?.status || searchParams.get('status') as ObjectStatus,
            typeId: defaultValues?.typeId || searchParams.getAll('typeId'),
            page: page,
            partnerId: defaultValues?.partnerId || searchParams.get('partnerId'),
            search: defaultValues?.search || searchParams.get('search'),
            paymentType: defaultValues?.paymentType || searchParams.get('paymentType') as PaymentType,
            size: defaultValues.size
        }
    }

    return (
        <ObjectsSearchContext.Provider value={{ handleSetObjectFilter, getSearchValues, page, setPage, searchParams }}>
            { children }
        </ObjectsSearchContext.Provider>
    )
}