import { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Card, Item } from ".."
import './style.css'
import { ObjectsSearchContext } from "contexts"
import { ObjectViewModel, SubscribedClassesViewModel } from "models"

type Props = {
    data          : { objects?: ObjectViewModel[], classes?: SubscribedClassesViewModel[] }
    listType      : 'objects' | 'classes'
    maxItemShow   : number //numero de items q serão listados, 0 = sem limite
    titleType     : 'search' | 'homeObjects' | 'false'
    leftTitle?    : string
    middleTitle?  : string //highlighted
    rightTitle?   : string
    description?  : string
    partnerObject : boolean

}

export default function ListItems({ maxItemShow, data, listType, titleType, description, leftTitle, middleTitle, rightTitle, partnerObject }: Props) {
    const [countItemsShow, setCountItemsShow] = useState<number>(maxItemShow)
    const { page, setPage, searchParams } = useContext(ObjectsSearchContext);

    const goTo = useNavigate()

    const handleLoadMore = () => {
        setCountItemsShow(countItemsShow + maxItemShow);
        setPage(page + 1);
    }

    const handleRedirect = (item: any) => {
        if (!partnerObject) {
            if (listType === 'classes') goTo(`/recurso/${item.class?.objectId}/turma/${item.class?.id}`)
            else goTo(`/recurso/${item.id}`)
        } else goTo(`/cadastro/recurso/${item.id}`)
    }

    useEffect(() => { setCountItemsShow(maxItemShow * page) }, [page])

    return (
        <div className="listItems">

            {
                titleType == 'homeObjects' &&
                <div className="col-xl-12">
                    <div className="tituloArea">{leftTitle} {middleTitle != undefined && <em>{middleTitle}</em>}{rightTitle}</div>
                    <div className="descricaoArea">{description}</div>
                </div>
            }
            <div className= {`${!partnerObject && 'col-xl-12'}`}>
                <div className={` ${!partnerObject && 'row lista recursosContainer'} ${titleType == 'search' && 'ultimo'}`}>
                    {
                        !partnerObject ? (
                            data[listType].map((item , index) => {
                                if (maxItemShow == 0 || index + 1 <= countItemsShow) {
                                    return (
                                        <div className="cards col-xl-3 col-gl-3 col-md-4" key={`card-${index}`}>
                                            <Card
                                                onclick={() => handleRedirect(item)}
                                                title={item.title}
                                                text={item.type.name}
                                                image={item?.image ? item?.image : item?.object?.image}
                                                type='caixa1'
                                            />
                                        </div>
                                    )
                                }
                            })
                        ) :
                        data[listType].map((item , index) => {
                                if (maxItemShow == 0 || index + 1 <= countItemsShow) {
                                    return (
                                        <Item
                                            title={item.title}
                                            description={item.description}
                                            type="object"
                                            image={item.image}
                                            onclick={() => handleRedirect(item)}
                                            key={`item-${index}`}
                                            status={item.status}
                                        />
                                    )
                                }
                            })
                    }
                </div>
            </div>

            {
                maxItemShow != 0 && countItemsShow < data[listType].length ?
                <div className = 'areaCaixas'>
                    
                    <div
                        className="col-xl-12 areBotaoCarregarMais"
                        onClick={handleLoadMore}
                    >
                        <div className="botao">
                            <i className='uil uil-sync'></i>
                            <span>Carregar mais</span>
                        </div>
                    </div>
                    </div>
                    :
                    titleType == 'search' && Number(searchParams.get('page')) > 1 &&
                    <div className='search-result-label'>
                        Isto é tudo.
                    </div>
            }
        </div>
    )
}