export enum ObjectStatus {
    Creating    = "C",
    Pending     = "P",
    Approved    = "A",
    Disapproved = "D"
  }

  export namespace ObjectStatus {
    export const isCreating = (status: ObjectStatus) => status === ObjectStatus.Creating
    export const isPending = (status: ObjectStatus) => status === ObjectStatus.Pending
    export const isApproved = (status: ObjectStatus) => status === ObjectStatus.Approved
    export const isDisapproved = (status: ObjectStatus) => status === ObjectStatus.Disapproved

    export const strC = (status: ObjectStatus) => isCreating(status) ? 'Criando' : ''
    export const strP = (status: ObjectStatus) => isPending(status) ? 'Pendente' : ''
    export const strA = (status: ObjectStatus) => isApproved(status) ? 'Aprovado' : ''
    export const strD = (status: ObjectStatus) => isDisapproved(status) ? 'Não Aprovado' : ''

}