import React, { createContext, useContext, useState } from "react";

type PaginationContextType = {
  active: number;
  numPages: number;
  pagesToShow: number;
  setActive: (active: number) => void;
  setPagesToShow: (pagesToShow: number) => void;
  setNumPages: (numPages: number) => void;
};
type PaginationProviderProps = {
  children: React.ReactNode;
};

export const PaginationContext = createContext<PaginationContextType>({
  active: 1,
  numPages: 1,
  pagesToShow: 1,
  setActive: () => {},
  setNumPages: () => {},
  setPagesToShow: () => {},
});

export const PaginationProvider = ({ children }: PaginationProviderProps) => {
  const [active, setActive] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [pagesToShow, setPagesToShow] = useState(1);
  return (
    <PaginationContext.Provider
      value={{
        active,
        numPages,
        pagesToShow,
        setActive,
        setNumPages,
        setPagesToShow,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};
