import { apiRoutes, api_ } from "config";
import { SubContentInputModel, SubContentViewModel } from "models";

export namespace IUpdateSubContent {
    export type Input = Partial<SubContentInputModel>;
    export type Output = SubContentViewModel;
}

export const updateSubContent = async (input: IUpdateSubContent.Input): Promise<IUpdateSubContent.Output> => {
    const url = `${apiRoutes.v1.subContent}/${input.id}`;
    
    const response = await api_.put<IUpdateSubContent.Output>(url, input);
    return response.data;
}