import { getObjectHighlights, IGetHighlightsObjects } from "services_/Object";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { setHighlightedObjects } from "slices/GlobalObjects";
import { createContext, useEffect } from "react";

type ContextProps = {
    loadingData?: boolean;
}

type ProviderProps = {
    children: React.ReactNode;
}

export const HighlightedObjectsContext = createContext<ContextProps>({} as ContextProps)
export const HighlightedObjectsProvider = ({ children }: ProviderProps) => {
    const { highlightedObjects } = useAppSelector(state => state.globalObjects)

    const { 
        isLoading: loadingObjectHighlights, 
        error: objectHighlightsError, 
        handle: handleObjectHighlights 
    } = useService({
        service: getObjectHighlights,
    })

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (highlightedObjects.length === 0) handleObjectHighlights().then((res) =>  
            dispatch(setHighlightedObjects(res)))
    }, [])

    return (
        <HighlightedObjectsContext.Provider value={{ loadingData: loadingObjectHighlights }}>
            { children }
        </HighlightedObjectsContext.Provider>
    )
}