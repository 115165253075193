import { ChangeEventHandler, useEffect, useState } from "react"
import './style.css'

type Props = {
    placeholder?: string
    icon?: string
    value?: string
    name: string
    messageError?: string
    col?: string
    maxLength?: number
    label?: string
    onChange?: ChangeEventHandler<HTMLTextAreaElement>
    maxHeight?: number
    rows?: number
    showTextLimit?: boolean
    disabled?: boolean
}

export default function TextArea({ placeholder, icon, name, value, messageError, onChange, col, maxLength, maxHeight, rows, showTextLimit, label, disabled=false }: Props) {
    const [count, setCount] = useState(0);
    useEffect(() => {
        value == undefined ? setCount(0) : setCount(value.length);
    },[value])
    return (
        <div className={`col-xl-${col} linhaCampo ${messageError ? 'withError' : ''}`}>
            {
                label &&
                <label className={`form-label ${ messageError && 'withError' }`}>{label}</label>
            }
            {
                icon &&
                <div className="ico">
                    <i className={`uil ${icon}`}></i>
                </div>
            }
            <textarea
                className="Campo textArea"
                value={value || ''}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                rows={rows}
                style={{ minHeight: '55px', maxHeight: maxHeight != null ? maxHeight + 'px' : '350px' }}
                maxLength={maxLength}
                disabled={disabled}
            />

            <div className="footer">
                <div className="message-error">
                    {messageError}
                </div>
                
                {
                    showTextLimit &&
                    <div className='textLimit'>
                        {count + '/' + maxLength}
                    </div>
                }
            </div>
        </div>
    )
}