import { useState, createContext, useContext, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import './style.css';
import { useClickPreventionOnDoubleClick } from "hooks";

type ContextProps = {
    isOpen: boolean;
    isDisabled: boolean;
    setIsOpen: (value: boolean) => void;
}
const AccordionContext = createContext<ContextProps>({
    isOpen: false,
    isDisabled: false,
    setIsOpen: () => { }
})

type AccordionProps = {
    id?: string;
    title?: string;
    children: React.ReactNode;
    secondary?: boolean;

}
export function Accordion_({ id, children, title, secondary }: AccordionProps) {
    return (
        <>
            {!!title && <h3 className="titulo">{title}</h3>}
            <div className={`accordion ${secondary ? "secondary" : ""}`} id={id}>
                {children}
            </div>
        </>
    )
}

export namespace Accordion_ {
    type ContainerProps = {
        children: React.ReactNode
        className?: string;
        size?: 'lg' | 'md' | 'sm'
    }
    export function Container({ children, className, size = 'lg' }: ContainerProps) {
        return (
            <div id="corpo" className={size === 'lg' && 'lg' + ` ${className}` }>
                <div className={`
                    caixa  ${size === 'md' ? 'menor' : size === 'sm' ? 'minimo' : 'large'}`} 
                    style={{ maxWidth: size === 'sm' ? '768px' : '' }}
                >
                    {children}
                </div>
            </div>
        )
    }

    type ItemProps = {
        children: React.ReactNode;
        opened?: boolean;
        className?: string;
        disabled?: boolean;
    }

    export function Item({ children, opened = false, className, disabled = false }: ItemProps) {
        const [isOpen, setIsOpen] = useState(opened);
        const [isDisabled, setDisabled] = useState(disabled);

        useEffect(() => {
            setIsOpen(opened)
            setDisabled(disabled)
        }, [opened, disabled])

        return (
            <AccordionContext.Provider value={{ isDisabled, isOpen, setIsOpen }}>
                <div className={`card ${className}`}>
                    {children}
                </div>
            </AccordionContext.Provider>
        )
    }

    type HeaderProps = {
        id?: string;
        secondary?: boolean;
        className?: string;
        children: React.ReactNode;
        handleClick?: () => void;
    }
    export function Header({ id, secondary, className, children, handleClick }: HeaderProps) {
        const { isOpen, setIsOpen, isDisabled } = useContext(AccordionContext);

        const handleOnClick = () => {
            if (!isDisabled) setIsOpen(!isOpen);
            if (handleClick && !isDisabled) handleClick();
        }

        return (
            <div
                id={id}
                className={`card-header ${className} ${secondary ? 'interno' : ''} ${isDisabled && 'disabled'}`}
            >
                <h2>
                    <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        aria-expanded={isOpen ? 'true' : 'false'}
                        onClick={handleOnClick}
                    >
                        {children}
                        <i className="uil uil-angle-down"></i>
                    </button>
                </h2>
            </div>
        )
    }

    type BodyProps = {
        children: React.ReactNode;
        className?: string;
        secondary?: boolean;
    }
    export function Body({ children, className, secondary }: BodyProps) {
        const { isOpen } = useContext(AccordionContext);

        return (
            <AnimateHeight
                duration={350}
                height={isOpen ? 'auto' : 0}
            >
                <div
                    id="corpo_ctd_1"
                    className="collapse show"
                    data-parent="#accordionRecursoConteudo"
                >
                    <div className={`card-body ${className} ${secondary ? "interno" : ""}`}>
                        {children}
                    </div>
                </div>
            </AnimateHeight>
        )
    }
}