import { apiRoutes, api_ } from "config";
import { TJwt } from "types";

export namespace IAuthenticate {
    export type Input = void;
    export type Output = void;
}

export const authenticate = async (): Promise<IAuthenticate.Output> => {
    const url = apiRoutes.v1.auth;
    const response = await api_.get<TJwt.Token>(url);

    localStorage.setItem("accessToken", response.data.accessToken);
}