import { Button, FieldSet, Loading } from "components";
import { useService } from "hooks";
import { objectServices } from "services";
import ItemApprovalStatus from "./Item";
import { useEffect, useContext, useState } from "react";
import { ObjectStatus } from "enums";
import { toast } from "react-hot-toast";
import { Notification } from "components";
import { getObjectAnalysisHistory } from "services_/Object";
import { ObjectRegisterContext } from "contexts";
import { AnalyzesHistoryViewModel } from "models";
import { initialState } from "../InitialInfos";
import { useDispatch } from "react-redux";
import { previous } from "slices/StepsBoxSlice";
export var changedState

//TODO: CRIAR FUNNÇÃO PARA COMPARAÇÃO DE MUDANÇAS
// function objectHasChanged(initialState, changedState, prefix = '') {
//   let hasDifferences = false; // Variável para rastrear se há diferenças

//   Object.keys({ ...initialState, ...changedState }).forEach(key => {
//     if (key === 'user' || key === "createdAt" || key === "status") return; // Ignora 'user' e 'createdAt'

//     const fullPath = prefix ? `${prefix}.${key}` : key;
//     if (typeof initialState[key] === 'object' && initialState[key] !== null && typeof changedState[key] === 'object' && changedState[key] !== null) {
//       // Se ambos os valores são objetos, faz uma comparação recursiva
//       if (objectHasChanged(initialState[key], changedState[key], fullPath)) {
//         hasDifferences = true; // Atualiza se encontrou diferenças
//       }
//     } else if (initialState[key] !== changedState[key]) {
//       hasDifferences = true; // Atualiza se encontrou diferenças
//     }
//   });

//   console.log(hasDifferences)
//   return hasDifferences; 
// }


export default function Approval() {
  const { object, setObject, analysisHistory, setAnalysisHistory } = useContext(ObjectRegisterContext);
  const [history, setHistory] = useState([]);
  const dispatch = useDispatch();

  const pendingMessage: AnalyzesHistoryViewModel[] =
    object?.status === ObjectStatus.Pending
      ? [
          {
            analysisDate: new Date(),
            status: ObjectStatus.Pending,
            observation: undefined,
            id: undefined,
            object: undefined,
          },
        ]
      : null;

  const { isLoading: loadingHistory, handle: handleGetHistory } = useService({
    service: () => getObjectAnalysisHistory({ objectId: object?.id }),
    onSuccess: (res) => setAnalysisHistory(res),
  });

  const handleValidation = () =>{
    if (object?.contents.length === 0) dispatch(previous())
    if (object?.contents.length >= 1) handleSendToAnalysis()
  }
  const { isLoading: sendingToAnalysis, handle: handleSendToAnalysis } = useService({
    service: async () => {
      //Caso passe o preço em branco
      if (object.price === undefined) object.price = 0

      await objectServices.updateObject({
            id: object?.id,
            status: ObjectStatus.Pending,
          })
        return ObjectStatus.Pending;

      // if(history.length === 0){      
      
      //   await objectServices.updateObject({
      //     id: object?.id,
      //     status: ObjectStatus.Pending,
      //   })
      //   return ObjectStatus.Pending;

      // } 
      
      // if (history.length > 0 && history[0].status === 'D') {
      //   await objectServices.updateObject({
      //     id: object?.id,
      //     status: ObjectStatus.Pending,
      //   })
      //   return ObjectStatus.Pending;
        
      // } else {

      //   if (objectHasChanged(initialState, changedState)) {

      //     await objectServices.updateObject({
      //       id: object?.id,
      //       status: ObjectStatus.Pending,
      //     });

      //     return ObjectStatus.Pending;

      //   } else {

      //     await objectServices.updateObject({
      //       id: object?.id,
      //       status: ObjectStatus.Approved,
      //     });

      //     return ObjectStatus.Approved;
      //   }
      // }      
  },

    onLoading: () => toast.loading("Enviando para análise...", { duration: null }),
    onSuccess: (status) => {
      toast.dismiss();
      if (status === ObjectStatus.Pending) {
        toast.success("Recurso enviado para análise!");
      } else {
        // Nenhuma mudança foi realizada, informar ao usuário
        toast.success("Nenhuma mudança foi detectada!");
      };
      // Atualizar o estado do objeto com o novo status
      setObject({ ...object, status: status });

    },
    onError: (err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.message, { duration: 15000, position: 'top-center' });
    },
  });

  const LOADING_PAGE = loadingHistory || sendingToAnalysis;
  const VIEW_ONLY = ObjectStatus.isPending(object?.status) || ObjectStatus.isApproved(object?.status);

  
  useEffect(() => {
    changedState={...object};
    if (!analysisHistory.length) handleGetHistory();
  }, [object?.id]);
  useEffect(() => {
    if (pendingMessage?.length) setHistory(pendingMessage?.concat(analysisHistory));
    else setHistory(analysisHistory);
  }, [analysisHistory?.length, object?.status]);

  return (
    <>
      {!loadingHistory ? (
        <FieldSet
          title="Histórico de Análises"
          items={
            !pendingMessage?.length && !analysisHistory?.length
              ? [
                  {
                    title: "Você ainda não enviou este recurso para análise",
                    description: "Ao enviar, o status atual aparecerá aqui",
                  },
                ]
              : []
          }
        >
          {history.map((item) => (
            <ItemApprovalStatus analysisDate={item.analysisDate} status={item.status} observation={item.observation} />
          ))}
        </FieldSet>
      ) : (
        <Loading.Small />
      )}

      {!VIEW_ONLY && (
        <div className="content-buttons">
          <Button
            disabled={LOADING_PAGE}
            type="button"
            className="button-add-content"
            loading={sendingToAnalysis}
            onClick={handleValidation}
          >
            {`${ObjectStatus.isDisapproved(object?.status) || (analysisHistory?.length) ? "Re" : "E"}nviar Para Análise`}
          </Button>
        </div>
      )}
      <Notification position="bottom-left" />
    </>
  );
}
