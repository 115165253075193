import { apiRoutes, api_ } from "config";
import { ContentInputModel, ContentViewModel } from "models";

export namespace ICreateContent {
    export type Input = ContentInputModel;
    export type Output = ContentViewModel;
}

export const createContent = async (input: ICreateContent.Input): Promise<ICreateContent.Output> => {
    const url = apiRoutes.v1.content;
    const response = await api_.post<ICreateContent.Output>(url, input);
    return response.data;
}