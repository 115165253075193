import { apiRoutes, api_ } from "config";
import { CourseIntegrationProviders } from "enums";

export namespace IAccess {
    export type Input = {
        provider: CourseIntegrationProviders;
        objectId: string;
    };
    export type Output = string;
}

export const access = async (input: IAccess.Input): Promise<IAccess.Output> => {
    const url = apiRoutes.v1.courseIntegration + `/${input.objectId}/access`;

    const response = await api_.post<IAccess.Output>(url, input);
    return response.data;
}