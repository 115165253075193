import { ChangeEvent, FormEvent, useState } from "react"
import { OnChangeValue } from "react-select";
import { MaskTypes } from "enums";
import { useMask } from "./useMask";
import { MultiSelectChange } from "components/MultiSelect";

type IValidations = {
    required?       : boolean
    isEmail?        : boolean 
    numberOfItems?  : number,
    requireLength?  : number,
    minValue?       : number;
}

type Validations<T extends {}> = Partial<Record<keyof T, IValidations>>;
type Erros<T> = Partial<Record<keyof T, string>>;

export const useForm = <T extends Partial<Record<keyof T, any>> = {}> (props: {
    initialValues? : Partial<T>
    validations?   : Validations<T>
    onSubmit?      : () => void
}) => {
    const [data, setData]     = useState<T>((props.initialValues || {}) as T)
    const [errors, setErrors] = useState<Erros<T>>({})
    const { handleMask }      = useMask()

    const messageErrors = {
        required      : 'Campo obrigatório',
        isEmail       : 'E-mail inválido',
        numberOfItems : 'Quantidade de itens inválida',
        requireLength : 'Quantidade de caracteres inválida',
        minValue      : 'O valor do recurso precisa ser maior que 2 R$'
    }

    const handleValidations = {
        required : (value: string) => !!value,
        isEmail  : (value: string) => {
            const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
            return emailRegex.test(value)
        },
        numberOfItems : (values: string[], numberOfItems: number) => {
            return values.length === numberOfItems
        },
        requireLength : (value: string, length: number) => {
            return value.length === length
        },
        minValue      : (value: string, minValue: number) => {
            if (Number(value) === 0 || value === "0,00" ){
                return true
            } else {
                return parseFloat(value) >= minValue
            }
            
        }
    }

    const validate = (input: IValidations | any, value: string | string[] | boolean | File): string | void => {
        const rules = Object.keys(input).filter(rule => input[rule])
        
        for (const rule of rules) {
            if (!handleValidations[rule](value, input[rule])) {
                return messageErrors[rule]
            }
        }
    }

    //remover erros de um campo de input ao digitar e o valor for válido
    function checkInputValidation(key : keyof T, value : string | string[] | boolean | File){
        if (props.validations && errors[key]) {
          let valid = true
          const errors_ = errors
          const error = validate(props.validations[key], value)
          if (error) {
            valid = false
            errors_[key] = error
          } else {
            delete errors_[key]
          }
          if (!valid) {
            setErrors(errors_)
            return false
          }
        }
      }

    const handleChangeValue = (key: keyof T, action?: any) => (
        (event: ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) => {
            const { value } = event.target
            checkInputValidation(key, value)
            setData({ ...data, [key] : value })
            if (action) action(value)
            
        }
    )

    const handleChangeCheckValue = (key: keyof T) => (
        (event: ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target
            checkInputValidation(key, checked) 
            setData({ ...data, [key] : checked })
            
        }
        
    )

    const handleChangeMultiSelectValue = (key: keyof T) => (
        ({ item, action }: MultiSelectChange) => {
            let currentValues: string[] = data[key] ?? []

            if (action === 'append') currentValues.push(item.value)
            if (action === 'remove') currentValues.splice(currentValues.indexOf(item.value), 1)
            if (!action) currentValues = [item.value];
            checkInputValidation(key, currentValues) 
            setData({ ...data, [key]: currentValues })
        }
    )

    const handleChangeValueWithMask = (key: keyof T, mask: MaskTypes, action?: any) => (
        (event: ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) => {
            const { value } = event.target
            const maskValue =  handleMask(value, mask)
            checkInputValidation(key, maskValue) 
            setData({ ...data, [key] : maskValue})
            if (action) action(value)
        }
    )

    const handleChangeFile = (key: keyof T) => (
        (event: ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target
            checkInputValidation(key, files[0]) 
            setData({ ...data, [key] : files[0] })
        }
    )


    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const validations = props.validations

        if (validations) {
            let valid = true
            const errors_: Erros<T> = {}
            
            for (const key in validations) {
                const error = validate(validations[key], data[key])
                if (error) {
                    valid = false
                    errors_[key] = error
                }
            }
            
            if (!valid) {
                setErrors(errors_)
                return
            }
        }

        setErrors({})
        props.onSubmit()
    }

    const handleCleanForm = () => {
        const fields = Object.keys(data)
        for (const field of fields) data[field] = ''
        setData({...data})
    }

    return { 
        data, 
        errors, 
        setData,
        setErrors,
        handleChangeValue, 
        handleSubmit, 
        handleCleanForm, 
        handleChangeCheckValue, 
        handleChangeMultiSelectValue, 
        handleChangeValueWithMask,
        handleChangeFile
    }
}