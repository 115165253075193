import { Spinner } from "react-bootstrap"
import './style.css'
import { ReactElement } from "react"

type Props = {
    children   : React.ReactNode
    className? : string
    hidden?    : boolean
    type       : 'button' | 'submit' | 'reset'
    disabled?  : boolean
    loading?   : boolean
    onClick?   : React.MouseEventHandler<HTMLButtonElement>
}

export default function Button({ children, type, hidden=false, className, disabled = false, loading = false, onClick }: Props ) {
    const loading_ = () => {
        return (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        );
      };

    return (
        <button
            className={`botao ${className} ${loading || disabled ? 'disabled' : ''}`}
            type={type}
            onClick={onClick}
            disabled={loading || disabled}
            hidden={hidden}
        >
            { loading ? loading_() : children }
        </button>
    )
}