import React from "react";
import './style.css';

type Props = {
    title: string;
    children: React.ReactNode;
}

export function HorizontalTable({ title, children }: Props) {
    return (
        <table className="table-horizontal">
            <thead>
                <tr>
                    <th colSpan={100}>{title}</th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )
}

type TrProps = {
    children: React.ReactNode;
}
type TdProps = {
    children: React.ReactNode;
    highlight?: boolean;
    style?: React.CSSProperties;
}
export namespace HorizontalTable {
    export function tr({ children }: TrProps) {
        return (
            <tr>
                { children }
            </tr>
        )
    }
    export function td({ children, highlight = false, style }: TdProps) {
        return (
            <td 
                className={highlight ? 'tb-highlight' : ''}
                style={style}
            >
                {children}
            </td>
        )
    }
}
