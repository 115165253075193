import { ReactNode } from "react"
import './style.css'

type Props = {
    title: string
    items? : fildSetItem[] 
    children? : ReactNode
}

type fildSetItem = {
    title: string
    description: string
}

export default function FieldSet({ title, items, children }: Props) {
    return (
        <>
            <div className="col-xl-12">
                <fieldset className="fieldSet">
                    <legend>{title}</legend>
                    <div className="row">
                        {
                        items != undefined && 
                        
                            items.map(item => (
                            <div className="col-lg-12  labelValor">
                                <div className="txtLabel">{item.title}</div>
                                <div className="txtValor">{item.description}</div>
                            </div>
                        ))
                        }

                        {children != undefined && children}
                    </div>
                </fieldset>
            </div>
        </>
    )
}