import { Form } from 'react-bootstrap'
import './style.css'

type Props = {
    children?: React.ReactNode,
    checked?: boolean,
    messageError?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    type?: 'checkbox' | 'switch'
    label?: string
    name?: string
}

export default function Checkbox({ children, onChange, checked = false, messageError, type, label, name }: Props) {

    return (
        <div className={`col-xl-12 linhaTermos checkbox ${messageError ? 'withError' : ''}`}>
            <div className='left-termos'>
                {type == undefined || type == 'checkbox' ?
                    <input
                        type="checkbox"
                        id="terms-check"
                        onChange={onChange}
                        checked={checked}
                        name={name}
                    />
                    :
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={onChange}
                        checked={checked}
                        label={label}
                        name={name}
                    />
                }
            </div>
            <div className="text">
                {children}
            </div>
            {/* <span>Aceito os <a onClick={() => setShowTerms(!showTerms)}>Termos de uso</a> e <a>Políticas</a> do Academy 4 Life.</span> */}
        </div>
    )

}