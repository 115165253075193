import { ClassViewModel, ObjectViewModel, SubscriptionViewModel } from "models";
import { useState, useEffect } from "react";

type Props = {
  isClass?: boolean;
  data: { object: ObjectViewModel; class: ClassViewModel; subscription?: SubscriptionViewModel };
};

export default function ObjectInfo({ data, isClass = false }: Props) {
  const [classInfo, setClassInfo] = useState<string>("");
  useEffect(() => {
    if (data?.class?.info) setClassInfo(data.class.info);
    if (data?.subscription?.class?.info) setClassInfo(data.subscription.class.info);
    if (!isClass) setClassInfo("");
  }, [data?.class?.info, data?.subscription?.class?.info, isClass]);

  return (
    <>
      <h3 className="titulo">Informações</h3>
      <div className="informacoesGerais">
        <p>{data.object.description}</p>
        {!classInfo && isClass ? <p>*Novas informações disponíveis após a inscrição.</p> : <p>{classInfo}</p>}
      </div>
    </>
  );
}
