import { apiRoutes, api_ } from "config";
import { ContentInputModel, ContentViewModel } from "models";

export namespace IUpdateContent {
    export type Input = Partial<ContentInputModel>;
    export type Output = ContentViewModel;
}

export const updateContent = async (input: IUpdateContent.Input): Promise<IUpdateContent.Output> => {
    const url = `${apiRoutes.v1.content}/${input.id}`;
    const response = await api_.put<IUpdateContent.Output>(url, input);
    return response.data;
}