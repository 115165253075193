import { IAuthSteps } from "interfaces"
import { useAppSelector } from "hooks"
import { Modals } from "../.."

export default function AuthSteps () {
    const { step, props } = useAppSelector((state) => state.authSteps)
    
    const steps = {
        [IAuthSteps.Steps.LOGIN]                  : <Modals.LoginModal
                                                        showProfilesSelector={props.showProfilesSelector}
                                                    />,
        [IAuthSteps.Steps.USER_REGISTER]          : <Modals.UserRegister defaultValues={props.defaultValues}/>,
        [IAuthSteps.Steps.ADDITIONAL_INFORMATION] : <Modals.AdditionalInformation
                                                        previousStep={props.previousStep}
                                                        nextStep={props.nextStep} 
                                                        showContract={props.showContract}
                                                        userData={props.userData}
                                                    />,
        [IAuthSteps.Steps.PASSWORD_RECOVERY]      : <Modals.PasswordRecovery 
                                                        previousStep={props.previousStep}
                                                    />,
        [IAuthSteps.Steps.EMAIL_CONFIRMATION]     : <Modals.EmailConfirmation 
                                                        title={props.title} 
                                                        email={props.email}
                                                        previousStep={props.previousStep} 
                                                        nextStep={props.nextStep} 
                                                        type = {props.type}
                                                        sendEmail = {props.sendEmail}
                                                    />,
    }
    
    return steps[step]
}