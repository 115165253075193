import { Link, useNavigate } from "react-router-dom"
import Brand from '../../../../assets/images/brands/top.svg'
import { useAppDispatch, useSearchForm } from "hooks"
import { IAuthSteps } from "interfaces"
import { openSteps } from "slices/AuthStepsSlice"
import { useState } from 'react'
import './style.css'

export default function PublicHeader({ setHomeSidebar }) {
    const {onSearch, handleSubmitSearch} = useSearchForm()
    const goTo     = useNavigate()
    const dispatch = useAppDispatch()

    const handleOpenLogin = () => dispatch(openSteps({ step: IAuthSteps.Steps.LOGIN }))
    const handleOpenUserRegister = () => dispatch(openSteps({ step: IAuthSteps.Steps.USER_REGISTER }))

    return (
        <div id="topo_home">
            <div className="centro">

                <div className="esquerda">
                    <Link to="/" className="marca">
                        <img src={Brand} alt="Academy 4 Life" title='Academy 4 Life' />
                    </Link>

                    <div
                        className="botaoMenuAbrir"
                        onMouseEnter={() => setHomeSidebar(true)}
                        onClick={() => setHomeSidebar(true)}
                    >
                        <div className="botao">
                            <span>
                                Conteúdos &nbsp;
                            </span>
                            <i className="uil uil-bars"></i>
                        </div>
                    </div>
                </div>

                <div className="direita">
                    <div className="areaBuscar">
                        <form onSubmit={handleSubmitSearch}>
                            <input type="text" name="cp_buscar" placeholder="Buscar Recurso..." onChange={onSearch}/>
                            <button type="submit">
                                <i className="uil uil-search"></i>
                            </button>
                        </form>
                    </div>

                    <div  onClick={handleOpenLogin} className="btnLogin">Login</div>

                    <div onClick={handleOpenUserRegister} className="btnInscrevase">Cadastre-se</div>
                </div>

            </div>
        </div>
    )
}