import { TObjectSearchInput } from "types";
import { apiRoutes, api_ } from "config";
import { ObjectViewModel } from "models";

export namespace IGetObjects {
    export type Input = { search?: Partial<TObjectSearchInput> }
    export type Output = ObjectViewModel[]
}

export const getObjects = async ({ search }: IGetObjects.Input): Promise<IGetObjects.Output> => {
    console.log("🚀 ~ file: GetObjects.ts:11 ~ getObjects ~ search:", search)
    const url = apiRoutes.v1.objects

    const response = await api_.get<IGetObjects.Output>(url, { params: search })
    return response.data
}
