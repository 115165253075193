import { apiRoutes, api_ } from "config";
import { SESSION_STORAGE_KEYS } from "constants_";
import { CourseIntegrationInputModel } from "types";

export namespace IAuthenticate {
    export type Input = Pick<CourseIntegrationInputModel, 'providerAppId' | 'providerAppKey' | 'providerHost' | 'provider'>;
    export type Output = { authenticationUrl: string };
}

export const authenticate = async (input: IAuthenticate.Input): Promise<IAuthenticate.Output> => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.COURSE_INTEGRATION_TEMP_PROVIDER_DATA, JSON.stringify(input));

    const url = apiRoutes.v1.courseIntegration + '/authenticate';
    const response = await api_.post<IAuthenticate.Output>(url, input);
    return response.data;
}