import { apiRoutes, api_ } from "config";
import { ObjectInputModel, ObjectViewModel } from "models";

export namespace IUpdateObject {
    export type Input = Partial<ObjectInputModel>;
    export type Output = ObjectViewModel;
}

export const updateObject = async (object: IUpdateObject.Input): Promise<IUpdateObject.Output> => {
    const formData = new FormData()

    if (object.title) formData.append('title', object.title)
    if (object.description) formData.append('description', object.description)
    if (object.file) formData.append('file', object.file)
    if (object.modality) formData.append('modality', object.modality)
    if (object.price) formData.append('price', String(object.price))
    if (object.areaId) formData.append('typeId', object.typeId)
    if (object.typeId) formData.append('areaId', object.areaId)
    if (object.status) formData.append('status', object.status)

    const url = `${apiRoutes.v1.objects}/${object.id}`
    const response = await api_.put<IUpdateObject.Output>(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    
    return response.data
}