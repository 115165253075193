import { apiRoutes, api_ } from "config";
import { authenticate } from "services_/Auth";

export namespace ISavePartnerInfo {
    export type Input = void;
    export type Output = void;
}

export const savePartnerInfo = async (input: ISavePartnerInfo.Input): Promise<ISavePartnerInfo.Output> => {
    const url = apiRoutes.v1.partners;
    await api_.post(url);
    await authenticate();
}