import { useContext } from "react";
import { RootState } from "Store";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { Cart } from "types/Cart";
import './style.css';
import { deleteCartItens } from "services_/Class";
import toast from "react-hot-toast";
import { UserContext } from "contexts";
import { fetchCartItems, setCartIsActive } from "slices/CartSlice";
import { useNavigate } from "react-router-dom";
import { setOrder } from "slices/OrderSlice";

const CartList = () => {
    const cart = useAppSelector((state: RootState) => state.cart);
    const { userInfo } = useContext(UserContext);
    const dispatch = useAppDispatch();
    const goTo = useNavigate();
 
    const { handle: handleDeleteItens, isLoading: deleting } = useService({
        service: () => 
            toast.promise(
                deleteCartItens({
                    listOfIds: cart.cartList.map((item: Cart.CartItem) => (
                        item.id
                    )),
                    userId: userInfo.userId,
                }),
                {
                    loading: "Removendo itens do carrinho...",
                    success: () => "Itens removidos do carrinho com sucesso!",
                    error: (error) => 
                        error?.response?.data?.message || "Ocorreu um erro ao remover os itens do carrinho!"
                },
                { duration: 15000 }
            ),
        onSuccess: async (res) => {
            await dispatch(fetchCartItems());
            dispatch(setCartIsActive(false))
        }
    });

    const HandleDeleteItem = async (item: Cart.CartItem) => {
     try {
        await deleteCartItens({
            listOfIds: [item.id],
            userId: userInfo.userId,
        });

         toast.success('Item removido do carrinho!');
        await dispatch(fetchCartItems());  
     } catch (error) {
         toast.error( error?.response?.data?.message || 'Erro ao remover item do carrinho.');
     }
       
    };

    const handleToPayment = (item: Cart.CartItem) => {
        dispatch(
            setOrder([
                {
                    class: { id: item.class.id, title: item.class.title },
                    object: { price: item.class.object.price, title: item.class.object.title },
                    addClasses: {id: item.id, class: item.class, entryDate: item.entryDate, exclusionDate: item.exclusionDate, order: item.order,  price:item.price}
                },
            ])
        );
        goTo("/pagamento", { state: "teste" });
    };

    const handleAllToPayment = () => {
        const allItems = cart.cartList.map((item: Cart.CartItem) => ({
            class: { id: item.class.id, title: item.class.title },
            object: { price: item.class.object.price, title: item.class.object.title },
            addClasses: {id: item.id, class: item.class, entryDate: item.entryDate, exclusionDate: item.exclusionDate, order: item.order,  price:item.price}
        }));
        dispatch(setOrder(allItems));
        goTo("/pagamento", { state: "teste" });
    };

    return (
        <div className="cart-wrapper">
            <header className="text-center">Meu carrinho</header>
            <ul className="items-wrapper">
                {cart.cartList.length === 0 ? (
                    <div className="empty-cart">
                        <small>Carrinho vazio</small>
                    </div>
                ) : (
                    cart.cartList.map((item: Cart.CartItem) => (
                        <>
                            <li key={item.id}>
                                <div className="img-title-wrapper">
                                    <img src={`${process.env.REACT_APP_IMAGE_LINK}/${item.class.object?.image}`} alt="Item" />
                                    <a title="" onClick={() => handleToPayment(item)}>{item.class.object.title}</a>
                                </div>
                                <i className="uil uil-trash-alt trash" onClick={() => HandleDeleteItem(item)}></i>
                            </li>                      
                        </>
                    ))
                )}
            </ul>
            {cart.cartList.length >= 1 && (
                <button onClick={handleAllToPayment}>
                    {cart.cartList.length > 1 ? "Resumo das Compras" : "Finalizar Compra"}
                </button>
            )}
            
            <footer>
                {cart.cartList.length !== 0 && (
                    <button onClick={handleDeleteItens}>
                        Limpar <i className="uil uil-trash-alt"></i>
                    </button>
                )}
            </footer>
        </div>
    );
};

export default CartList;
