import { Box, Button, ButtonArea, Input, Loading } from "components"
import { useForm, useRequest } from "hooks"
import { authServices } from "services"

type Props = {
    _return? : () => void
}

export default function PasswordRecovery ({ _return }: Props) {
    const {isLoading, handleMakeRequest } = useRequest({
        loadingMessage: 'Enviando link para recuperação de senha...',
        sucessMessage : 'Link enviado com sucesso!',
        requestFn: () => authServices.sendValidationCode({
            email    : data.email,
            codeType : 'password',
            sendType : 'email',
            isAdmin  : true
        })
    })

    const { data, errors, handleChangeValue, handleSubmit } = useForm<{ email: string }>({
        validations: {
            email: {
                required : true,
                isEmail  : true
            }
        },
        onSubmit: () => handleMakeRequest()
    })

    return (
        <Box title="Recuperar Senha">
            {
                !isLoading ?
                    <form onSubmit={handleSubmit}>
                        Insira o email da conta que deseja recuperar.
                        <div className="row" style={{marginTop:'20px'}}>
                            <Input
                                type="email"
                                icon="uil-envelope"
                                placeholder="Insira seu E-mail"
                                name="cp_email"
                                value={data.email}
                                onChange={handleChangeValue('email')}
                                messageError={errors.email}
                            />

                            <ButtonArea className="linhaBotoes login-button-area">
                                <Button
                                    type="submit"
                                >
                                    Continuar
                                </Button>
                                <Button
                                    type="button"
                                    className="cadastrese"
                                    onClick={_return}
                                >
                                    Voltar
                                </Button>
                            </ButtonArea>
                        </div>
                    </form> :
                <Loading.Small loading={isLoading} />
            }
        </Box>
    )
}