import Modal from "components/Modal";
import ButtonArea from "components/ButtonArea";
import Button from "components/Button";

interface IModalDeletionConfirmation {
    itemName: string,
    confirmDeletion: () => void
    showModal: boolean,
    setShowModal: (show: boolean) => void,
}

const ModalDeletionConfirmation = ({ itemName, confirmDeletion, showModal, setShowModal }: IModalDeletionConfirmation) => {
    const handleConfirmDeletion = () => {
        confirmDeletion();
        setShowModal(false);
    }

    return (
        <Modal show={showModal} onClose={() => setShowModal(false)} isLoading={false}>
            <p>Confirmar exclusão de <strong>{itemName}</strong>?</p>
            <ButtonArea className="text-center">
                <Button className="admin vermelho" type="submit" onClick={() => setShowModal(false)}>
                    <i className="uil uil-times-circle"></i> Cancelar
                </Button>
                <Button className="admin azul" type="button" onClick={handleConfirmDeletion}>
                    <i className="uil uil-check-circle"></i> Confirmar
                </Button>
            </ButtonArea>
        </Modal>
    );
};

export default ModalDeletionConfirmation;
