import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStates } from "interfaces";
import { IGetHighlightsObjects, IGetObjects, IGetObjectsRecommendation } from "services_/Object";
import { IGetSubscribedClasses } from "services_/User";
import { IGetHomeObjects } from "services_/Utils";

/* Dados globais de recursos que são usados em mais de uma página ou que não precisam ser carregados 
 * toda vez que uma página é acessada.
 */
interface IGlobalObjectsState extends IStates {
    highlightedObjects: IGetHighlightsObjects.Output;
    homeObjects: IGetHomeObjects.Output;
    objectsToAnalyze: IGetObjects.Output;
    recommendedObjects: IGetObjectsRecommendation.Output;
    apprenticeClasses: IGetSubscribedClasses.Output;
}

const initialState: IGlobalObjectsState = {
    highlightedObjects: [],
    homeObjects: {},
    objectsToAnalyze: [],
    recommendedObjects: [],
    apprenticeClasses: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    errorData: null,
}

export const globalObjectsSlice = createSlice({
    name: "globalObjects",
    initialState,
    reducers: {
        setHighlightedObjects: (state, action: PayloadAction<IGetHighlightsObjects.Output>) => {
            state.highlightedObjects = action.payload;
        },
        setHomeObjects: (state, action: PayloadAction<IGetHomeObjects.Output>) => {
            state.homeObjects = action.payload;
        },
        setObjectsToAnalyze: (state, action: PayloadAction<IGetObjects.Output>) => {
            state.objectsToAnalyze = action.payload;
        },
        setRecommendedObjects: (state, action: PayloadAction<IGetObjectsRecommendation.Output>) => {
            state.recommendedObjects = action.payload;
        },
        setApprenticeClasses: (state, action: PayloadAction<any[]>) => {
            state.apprenticeClasses = action.payload;
        }
    }
})

export const { setHighlightedObjects, setHomeObjects, setObjectsToAnalyze, setRecommendedObjects, setApprenticeClasses } = globalObjectsSlice.actions;
export default globalObjectsSlice.reducer;