import { useAppDispatch, useForm, useService } from "hooks";
import { closeSteps, openSteps } from "slices/AuthStepsSlice";
import { Box, Loading, Modal, Input, Select, Button, ButtonArea, Terms } from "../../../../../index";
import { useEffect, useState, useContext } from "react";
import { Row } from "react-bootstrap";
import { Genders, UserTypes } from "enums";
import { IAuthSteps } from "interfaces";
import toast from "react-hot-toast";
import { utilsService } from "services";
import { UserContext } from "contexts";
import { UserInputModel } from "models";
import { ISaveAdditionalInfo, saveAdditionalInfo, savePartner, savePartnerInfo } from "services_/User";
import { useNavigate } from "react-router-dom";

type DocumentTypes = "cpf" | "cnpj" | null;

type Props = {
  previousStep?: {
    step: IAuthSteps.Step;
    props: any;
  };
  nextStep?: {
    step: IAuthSteps.Step;
    props: any;
  };
  showContract?: boolean;
  userData?: UserInputModel;
};

export default function AdditionalInformation({ previousStep, nextStep, showContract = false, userData }: Props) {
  const [showPartnerContract, setShowPartnerContract] = useState(showContract);
  const [documentType, setDocumentType] = useState<DocumentTypes>("cpf");
  const { userInfo, handleSelectProfile, handleSetUserInfoByToken } = useContext(UserContext);

  const USER_TYPE = userInfo?.type || userData?.type;
  const USER_NAME = userInfo?.name || userData?.name;
  const USER_EMAIL = userInfo?.email || userData?.email;

  const dispatch = useAppDispatch();

  const handleCloseModal = () => dispatch(closeSteps());

  const { data, handleChangeValue, handleSubmit, handleChangeValueWithMask, setData, errors } = useForm<ISaveAdditionalInfo.Input>({
    validations: {
      birthDate: { required: documentType !== "cnpj" },
      sex: { required: documentType !== "cnpj" },
      cpf_cnpj: { required: true },
      phone: { required: true },
      cep: { required: true },
      city: { required: true },
      state: { required: true },
    },
    onSubmit: () => (UserTypes.userIsOnlyApprentice(USER_TYPE) ? handleSaveAdditionalInfo() : handleSavePartner()),
  });

  const { isLoading: savingAdditionalInfo, handle: handleSaveAdditionalInfo } = useService({
    service: () => saveAdditionalInfo({ ...data }),
    onSuccess: () => {
      toast.success("Informações complementares salvas com sucesso!");
      
      if (nextStep) setShowPartnerContract(true);
      // else if (userInfo) handleCloseModal();
      else window.location.reload();
    },
    onError: (error) => toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'}),
  });

  const { isLoading: savingPartner, handle: handleSavePartner } = useService({
    service: () =>
      savePartner({
        additionalInfo: { ...data },
        partnerInfo: { ...userData, type: documentType === "cnpj" ? UserTypes.Partner : USER_TYPE },
      }),
    onSuccess: () => {
      handleSetUserInfoByToken(localStorage.getItem("accessToken"))

      toast.success("Informações complementares salvas com sucesso!");
      handleNextPartnerStep();
    },
    onError: (error) => toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'}),
  });

  const { isLoading: savingPartnerInfo, handle: handleSavePartnerInfo } = useService({
    service: () => savePartnerInfo(),
    //após confirmar o contrato, ele será fechado e a página vai dar reload
    onSuccess: () => {handleCloseModal() ; window.location.reload()},
    onError: (error) => toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'}),
  });

  const IS_LOADING = savingAdditionalInfo || savingPartner || savingPartnerInfo;

  const handleBackToPreviousStep = () => dispatch(openSteps({ step: previousStep.step, props: previousStep.props }));
  const handleNextPartnerStep = () => {
    if (userInfo?.emailVerified) handleCloseModal();
    else {
      const profile = documentType === "cnpj" ? UserTypes.Partner : UserTypes.Apprentice;
      handleSelectProfile(profile, false);
      dispatch(
        openSteps({
          step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
          props: {
            title: "Confirmação de E-mail",
            email: USER_EMAIL,
            type: "email",
            nextStep: {
              step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
              props: { showContract: true },
            },
          },
        })
      );
    }
  };

  const handleSetCEPData = (cep: string) => {
    const cepWithoutMask = cep.replace(/\D/g, "");
    if (cepWithoutMask.length === 8) {
      toast
        .promise(utilsService.getDataFromCEP(cepWithoutMask), {
          loading: "Buscando dados do CEP informado...",
          success: "Dados do CEP encontrados com sucesso!",
          error: "Não foi possível encontrar os dados do CEP informado!",
        })
        .then((response) => {
          setData({
            ...data,
            address: response.address,
            city: response.city,
            state: response.state,
            cep: response.cep,
            neighborhood: response.neighborhood,
          });
        });
    }
  };

  useEffect(() => {
    if (UserTypes.userIsApprentice(USER_TYPE)) setDocumentType("cpf");
    else if (UserTypes.userIsOnlyPartner(USER_TYPE) || documentType === "cnpj") setDocumentType("cnpj");
  }, []);

  return (
    // aqui bloqueava a tela de contrato de parceria ISLOADING || showcontract
    <Modal className="additional-info-modal" show={true} onClose={handleCloseModal} isLoading={IS_LOADING}>
      <Box title={!showPartnerContract ? `Informações Complementares` : "Contrato de parceria A4L"}>
        {!IS_LOADING ? (
          <form onSubmit={handleSubmit}>
            {!showPartnerContract && (
              <>
                <div className="row">
                  <div className="col-xl-12 linhaPessoa">
                    <div className="linha">
                      <i className="uil uil-user"></i>
                      <span> {USER_NAME}</span>
                    </div>
                  </div>

                  {UserTypes.userIsPartner(USER_TYPE) && (
                    <Select
                      placeholder="Tipo de documento *"
                      data={[
                        { value: "cpf", text: "CPF" },
                        { value: "cnpj", text: "CNPJ" },
                      ]}
                      value={documentType}
                      messageError={errors.cpf_cnpj}
                      onChange={(e) => setDocumentType(e.target.value as DocumentTypes)}
                    />
                  )}

                  {documentType && (
                    <Input
                      name="cp_cpf_cnpj"
                      placeholder={documentType === "cpf" ? "CPF *" : "CNPJ *"}
                      type="text"
                      icon="uil-user-square"
                      value={data.cpf_cnpj}
                      messageError={errors.cpf_cnpj}
                      onChange={handleChangeValueWithMask("cpf_cnpj", documentType as "cpf" | "cnpj")}
                      maxLength={documentType === "cpf" ? 14 : 18}
                    />
                  )}

                  {documentType && documentType === "cpf" && (
                    <Input
                      name="cp_date"
                      placeholder="Nascimento *"
                      type="date"
                      icon="uil-calender"
                      value={String(data.birthDate)}
                      messageError={errors.birthDate}
                      max={"9999-01-01"}
                      onChange={handleChangeValue("birthDate")}
                    />
                  )}

                  <Input
                    name="cp_telefone"
                    placeholder="Telefone *"
                    type="text"
                    icon="uil-phone"
                    value={data.phone}
                    messageError={errors.phone}
                    onChange={handleChangeValueWithMask("phone", "phone")}
                    maxLength={16}
                  />

                  {documentType && documentType === "cpf" && (
                    <Select
                      placeholder="Sexo"
                      data={[
                        { text: "Feminino", value: Genders.Feminine },
                        { text: "Masculino", value: Genders.Masculine },
                        { text: "Não informado", value: Genders.NotApplicable },
                      ]}
                      value={data.sex}
                      messageError={errors.sex}
                      onChange={handleChangeValue("sex")}
                    />
                  )}

                  <div className="address-area">
                    <Input
                      name="cp_CEP"
                      placeholder="CEP"
                      type="text"
                      icon="uil-user-location"
                      value={data.cep}
                      messageError={errors.cep}
                      onChange={handleChangeValueWithMask("cep", "cep", handleSetCEPData)}
                      maxLength={9}
                    />

                    <Row className="register-input-row">
                      <Input
                        name="cp_cidade"
                        placeholder="Cidade"
                        type="text"
                        icon="uil-user-location"
                        value={data.city}
                        messageError={errors.city}
                        onChange={handleChangeValue("city")}
                        col="6"
                      />

                      <Input
                        name="cp_estado"
                        placeholder="Estado"
                        type="text"
                        icon="uil-user-location"
                        value={data.state}
                        messageError={errors.state}
                        onChange={handleChangeValue("state")}
                        col="6"
                      />
                    </Row>

                    <Input
                      name="cp_address"
                      placeholder="Endereço"
                      type="text"
                      icon="uil-user-location"
                      value={data.address}
                      messageError={errors.address}
                      onChange={handleChangeValue("address")}
                    />

                    <Input
                      name="cp_neighborhood"
                      placeholder="Bairro"
                      type="text"
                      icon="uil-user-location"
                      value={data.neighborhood}
                      messageError={errors.neighborhood}
                      onChange={handleChangeValue("neighborhood")}
                    />
                  </div>

                  <ButtonArea className="additional-info">
                    <Button type="submit">Salvar</Button>
                    {/* <Button
                      type="button"
                      className="cadastrese"
                      onClick={() => handleCloseModal() && window.location.reload()}
                      hidden={UserTypes.userIsPartner(USER_TYPE)}
                    >
                      Pular
                    </Button> */}
                    {!!previousStep && (
                      <Button type="button" className="cadastrese" onClick={handleBackToPreviousStep} hidden={!UserTypes.userIsPartner(USER_TYPE)}>
                        Voltar
                      </Button>
                    )}
                  </ButtonArea>
                </div>
                <div className="mensageLabels">
                  {UserTypes.userIsPartner(USER_TYPE)
                    ? "* Informações obrigatórias como parceiro."
                    : "* Informações necessárias para futuras inscrições ou compras na plataforma."}
                </div>
              </>
            )}
            {showPartnerContract && (
              <div>
                <Terms contract="partner" />
                <ButtonArea className="additional-info">
                  <Button type="button" onClick={handleSavePartnerInfo}>
                    Aceitar
                  </Button>
                </ButtonArea>
              </div>
            )}
          </form>
        ) : (
          <Loading.Small loading={IS_LOADING} />
        )}
      </Box>
    </Modal>
  );
}
