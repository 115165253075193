import { Link } from 'react-router-dom'
import { ILink } from "./Modulos"

type Props = {
    id: string
    title: string
    links: ILink[]
    onClick?: React.MouseEventHandler<HTMLDivElement>
    showHomeSidebarModules: object
}

export default function SidebarModule({ id, title, links, onClick, showHomeSidebarModules }: Props) {
    return (
        <div className={`modulo ${showHomeSidebarModules[id] ? "aberto" : ""}`} onClick={onClick} id={'modulo-' + id}>
            <div className="cabecalho">
                <div className="titulo">{title}</div>
                <i className="uil uil-angle-down icone"></i>
            </div>

            <div className="links">
                {links.map((link, index) => (
                    <Link key={index} to={link.path}>{link.title}</Link>
                ))}
            </div>
        </div>
    )
}
