export function isDeadlinePassed(deadLine: Date) {
  //true = deadline is valid | false = deadline is invalid
  // const limitDate = new Date(deadLine).toLocaleDateString("pt-br");
  // const limitTime = new Date(deadLine).toLocaleTimeString("pt-br").substring(0, 5)
  // let [day, month, year] = limitDate.split("/");
  // let deadline;
  // if (limitTime) {
  //   let [hour, minute] = limitTime.split(":");
  //   deadline = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute));
  // } else {
  //   deadline = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 23, 59);
  // }

  // let today = new Date();
  // return today <= deadline;

  if (!deadLine) return false;
  
  const today = new Date();
  const deadline = new Date(deadLine);

  return today.getTime() > deadline.getTime();
}