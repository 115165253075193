import { apiRoutes, api_ } from "config";
import { PaymentOrderViewModel } from "models";

export namespace IGetOrders {
    export type Input = {};
    export type Output = PaymentOrderViewModel[];
};

export const getOrders = async (): Promise<IGetOrders.Output> => {
    const url = apiRoutes.v1.orders;
    const response = await api_.get<IGetOrders.Output>(url);
    return response.data;
}