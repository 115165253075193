import { apiRoutes, api_ } from "config";
import { CourseIntegrationInputModel } from "types";

export namespace ISaveCredentials {
    export type Input = Partial<CourseIntegrationInputModel>;
    export type Output = void;
}

export const saveCredentials = async (input: ISaveCredentials.Input): Promise<ISaveCredentials.Output> => {
    const url = apiRoutes.v1.courseIntegration + '/saveCredentials';
    await api_.post(url, input);
}
