import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_URL

export const routes = {
    user               : baseUrl + '/v1/users',
    additionalInfo     : baseUrl + '/v1/users/info',
    login              : baseUrl + '/v1/login',
    emailConf          : baseUrl + '/v1/emailConfirmation',
    areas              : baseUrl + '/v1/areas',
    saveChoseAreas     : baseUrl + '/v1/users/areas',
    savePartner        : baseUrl + '/v1/partners',
    sendValidationCode : baseUrl + '/v1/auth/sendValidationCode',
    passwordReset      : baseUrl + '/v1/users/passwordReset',
    getDataFromCEP     : baseUrl + '/v1/services/getCepData',
    homeHighlights     : baseUrl + '/v1/services/homeHighlights',
    modalities         : baseUrl + '/v1/modalities',
    types              : baseUrl + '/v1/types',
    objects            : baseUrl + '/v1/objects',
    content            : baseUrl + '/v1/content',
    contents           : baseUrl + '/v1/contents',
    subContent         : baseUrl + '/v1/subContent',
    class              : baseUrl + '/v1/class',
    classes            : baseUrl + '/v1/classes',
    auth               : baseUrl + '/v1/auth',
    authPartner        : baseUrl + '/v1/authenticatePartner',
    authApprentice     : baseUrl + '/v1/authenticateApprentice',
    subscribedClasses  : baseUrl + '/v1/subscribedClasses',
    paymentProfiles    : baseUrl + '/v1/paymentProfiles',
    admin              : baseUrl + '/v1/admin',
    analyze            : baseUrl + '/v1/analyze',
}

export const api = axios.create({
    baseURL: baseUrl,
})

api.interceptors.response.use((response) => {
    return response
}, (error) => {
    if ([401].includes(error.response.status)) {
        localStorage.clear()
        window.location.href = '/'
    } else throw error
})

export const setAuthToken = () => {
    const token = localStorage.getItem('accessToken')
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const deleteAuthToken = () => {
    delete api.defaults.headers.common['Authorization']
}

type QueryParams = {
    url    : string
    params : object
}
export const setQueryParams = ({ url, params }: QueryParams) => {
    const params_ = Object.keys(params)

    url += '?'
    for (const key of params_) {
        if (params[key]) {
            const values = params[key].split(',')
            for (const value of values) url += `${key}=${value}&`
        }
    }
    
    return url.replace(/[aA-zZ]+=&/g, '')
}