export type TAddress = {
    cep          : string
    address      : string
    neighborhood : string
    city         : string
    state        : string
    number?      : string
}

export const addressToObject = (address_: string): TAddress => {
    const [cep, city_state, address, neighborhood, number] = address_.split(', ')
    const [city, state] = city_state.split(' - ')

    return {
        cep,
        city,
        state,
        address,
        neighborhood,
        number
    }
}