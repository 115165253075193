import { Button, ButtonArea, Input } from "components"
import { UserContext } from "contexts"
import { PaymentMethods } from "enums"
import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import { classSubscription } from "services_/Class";
import "./style.css";
import { useAppSelector } from "hooks"

export default function PaymentPix() {
    const {userInfo} = useContext(UserContext)
    const { order } = useAppSelector(state => state.order);

    const dispatch = useDispatch()

    const handleGeneratePix = () =>{
        classSubscription({
            classId: order.classes[0].class.id,
            pixInfo: {
                payment_method    :  PaymentMethods.PIX,
                payment:{
                    payment_method_id: 27
                }
            }
        })
    }
    
    return(
        <>
            <div
            className="payment-pix-div"
            >
               
                    <Button 
                    type="submit"
                    className="generate-pix"
                    onClick={()=> handleGeneratePix()}

                >
                Gerar Pix
                </Button>
                
                

            </div>
        </>
    )
}