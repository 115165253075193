import { api, routes } from "../config"
import { HomeHighlights, TAddress } from "types"

export const getDataFromCEP = async (cep: string): Promise<TAddress> => {
    const response = await api.get<TAddress>(`${routes.getDataFromCEP}/${cep}`)
    return response.data
}

const homeHighlights = async ({ size }: { size: number}): Promise<HomeHighlights> => {
    const response = await api.get<HomeHighlights>(routes.homeHighlights, { params: { size } })
    return response.data
}

export const utilsService = {
    getDataFromCEP,
    homeHighlights
}

export default utilsService