import { Button, ButtonArea, Input } from "components";
import { useEffect, useContext } from 'react'
import { useForm, usePasswordState, useService } from "hooks";
import { authenticate } from "services_/CourseIntegration";
import {  } from "models/D2L_Integration";
import { CourseIntegrationProviders } from "enums";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { SESSION_STORAGE_KEYS } from "constants_";
import { CourseIntegrationInputModel } from "types";
import { DataContext } from "../../..";

export default function D2LService() {
    const TEMP_DATA = (
        JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.COURSE_INTEGRATION_TEMP_PROVIDER_DATA))
    ) as Pick<CourseIntegrationInputModel, 'providerAppId' | 'providerAppKey' | 'providerHost'>
    
    const { passwordState, handleChangeStatePassword } = usePasswordState()
    const [params] = useSearchParams()

    const {
        accordionsErrors,

        setAccordionsErrors,
        setProviderInputData
    } = useContext(DataContext)

    const {
        data,
        errors,
        setData,
        setErrors,
        handleChangeValue,
    } = useForm<CourseIntegrationInputModel>({
        initialValues: {
            provider: CourseIntegrationProviders.D2L,
            providerHost: TEMP_DATA?.providerHost || '',
            providerAppId: TEMP_DATA?.providerAppId || '',
            providerAppKey: TEMP_DATA?.providerAppKey || '',
            providerOrgId: '',
            providerInstallCode: '',
            providerKey: '',
            providerUserId: '',
            providerUserKey: '',
            providerCourseId: '',
            providerApprenticeRoleId: '',
        }
    })

    const {
        isLoading: authenticating,
        handle: handleAuthentication,
    } = useService({
        service: () => authenticate({
            provider: CourseIntegrationProviders.D2L,
            providerAppId: data.providerAppId,
            providerAppKey: data.providerAppKey,
            providerHost: data.providerHost,
        }),
        onLoading: () => { toast.loading('Gerando autenticação...', { duration: 0 }) },
        onSuccess: (response) => { window.location.href = response.authenticationUrl },
        onError: (error) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'})
        },
    })

    const validateAuthenticationInput = () => {
        let isValid = true;
        const inputErrors: Record<keyof Pick<CourseIntegrationInputModel, 'providerAppId' | 'providerAppKey' | 'providerHost'>, string> = {
            providerAppId: '',
            providerAppKey: '',
            providerHost: '',
        }

        if (!data.providerAppId) { inputErrors.providerAppId = 'Campo obrigatório'; (isValid = false) }
        if (!data.providerAppKey) { inputErrors.providerAppKey = 'Campo obrigatório'; (isValid = false) }
        if (!data.providerHost) { inputErrors.providerHost = 'Campo obrigatório'; (isValid = false) }

        setErrors(inputErrors);

        return isValid;
    }

    const handleProviderInputData = () => {
        if (!validateInput()) return;

        setProviderInputData(data);
    }

    const validateInput = () => {
        let isValid = true;

        const inputErrors: Record<keyof Omit<CourseIntegrationInputModel, 'objectId' | 'provider'>, string> = {
            providerAppId: '',
            providerAppKey: '',
            providerHost: '',
            providerOrgId: '',
            providerInstallCode: '',
            providerKey: '',
            providerUserId: '',
            providerUserKey: '',
            providerCourseId: '',
            providerApprenticeRoleId: '',
        }

        for (const key in data) {
            if (!data[key as keyof CourseIntegrationInputModel]) {
                inputErrors[key as keyof CourseIntegrationInputModel] = 'Campo obrigatório';
                isValid = false;
            }
        }

        setErrors(inputErrors);

        if (!isValid) { setAccordionsErrors({ ...accordionsErrors, 1: true })}
        else setAccordionsErrors({ ...accordionsErrors, 1: false })
        return isValid;
    }

    const handleAuthenticationButtonAction = () => {
        if (!validateAuthenticationInput()) return;
        handleAuthentication();
    }

    useEffect(() => {
        const dataObject: Record<keyof Pick<CourseIntegrationInputModel, 'providerUserId' | 'providerUserKey'>, string> = {
            providerUserId: '',
            providerUserKey: '',
        }

        if (params.get('x_a')) dataObject.providerUserId = String(params.get('x_a'));
        if (params.get('x_a')) dataObject.providerUserKey = String(params.get('x_b'));

        setData({ ...data, ...dataObject })
    }, [params])

    const LOADING_PAGE = authenticating

    return (
        <div className="form-slide-down" onMouseLeave={handleProviderInputData}>
            <hr />
            <div className='row'>
                <div className='col-xl-12'>
                    <Input
                        name="providerHost"
                        placeholder="Host"
                        type="text"
                        label="Host *"
                        value={data.providerHost}
                        messageError={errors.providerHost}
                        onChange={handleChangeValue('providerHost')}

                        disabled={LOADING_PAGE}
                    />
                </div>
            </div>

            <div className="row">
                <div className='col-xl-4'>
                    <Input
                        name="providerOrgId"
                        placeholder="Organização"
                        type="text"
                        label="ID da Organização *"
                        value={data.providerOrgId}
                        messageError={errors.providerOrgId}
                        onChange={handleChangeValue('providerOrgId')}
                        disabled={LOADING_PAGE}
                    />
                </div>
                <div className='col-xl-4'>
                    <Input
                        name="providerCourseId"
                        placeholder="Curso"
                        type="text"
                        label="ID do Curso *"
                        value={data.providerCourseId}
                        messageError={errors.providerCourseId}
                        onChange={handleChangeValue('providerCourseId')}
                        disabled={LOADING_PAGE}
                    />
                </div>
                <div className='col-xl-4'>
                    <Input
                        name="providerApprenticeRoleId"
                        placeholder="ID"
                        type="text"
                        label="ID do Cargo de Aluno *"
                        value={data.providerApprenticeRoleId}
                        messageError={errors.providerApprenticeRoleId}
                        onChange={handleChangeValue('providerApprenticeRoleId')}
                        disabled={LOADING_PAGE}
                    />
                </div>
            </div>

            <div className="row">
                <div className='col-xl-6'>
                    <Input
                        name="providerAppId"
                        placeholder="Aplicativo"
                        type="text"
                        label="ID do Aplicativo *"
                        value={data.providerAppId}
                        messageError={errors.providerAppId}
                        onChange={handleChangeValue('providerAppId')}
                        disabled={LOADING_PAGE}
                    />
                </div>
                <div className='col-xl-6'>
                    <Input
                        name="providerAppKey"
                        placeholder="Chave do Aplicativo"
                        type="password"
                        label="Chave do Aplicativo *"
                        passwordState={passwordState}
                        changePasswordState={handleChangeStatePassword}
                        value={data.providerAppKey}
                        messageError={errors.providerAppKey}
                        onChange={handleChangeValue('providerAppKey')}
                        disabled={LOADING_PAGE}
                    />
                </div>
            </div>

            <div className="row">
                <div className='col-xl-6'>
                    <Input
                        name="providerInstallCode"
                        placeholder="Código"
                        type="text"
                        label="Código de Instalação *"
                        value={data.providerInstallCode}
                        messageError={errors.providerInstallCode}
                        onChange={handleChangeValue('providerInstallCode')}
                        disabled={LOADING_PAGE}
                    />
                </div>
                <div className='col-xl-6'>
                    <Input
                        name="providerKey"
                        placeholder="Chave"
                        type="password"
                        label="Chave da Organização *"
                        passwordState={passwordState}
                        changePasswordState={handleChangeStatePassword}
                        value={data.providerKey}
                        messageError={errors.providerKey}
                        onChange={handleChangeValue('providerKey')}
                        disabled={LOADING_PAGE}
                    />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className='col-xl-6'>
                    <Input
                        name="providerUserId"
                        placeholder="Usuário"
                        type="text"
                        label="ID do Usuário *"
                        value={data.providerUserId}
                        messageError={errors.providerUserId}
                        onChange={handleChangeValue('providerUserId')}
                        disabled={LOADING_PAGE}
                    />
                </div>
                <div className='col-xl-6'>
                    <Input
                        name="providerUserKey"
                        placeholder="Chave do Usuário"
                        type="password"
                        label="Chave do Usuário *"
                        passwordState={passwordState}
                        changePasswordState={handleChangeStatePassword}
                        value={data.providerUserKey}
                        messageError={errors.providerUserKey}
                        onChange={handleChangeValue('providerUserKey')}
                        disabled={LOADING_PAGE}
                    />
                </div>
            </div>

            <ButtonArea>
                <Button
                    type="button"
                    loading={authenticating}
                    disabled={LOADING_PAGE}
                    onClick={handleAuthenticationButtonAction}
                >
                    Autenticar
                </Button>
            </ButtonArea>
        </div>
    )
}