import { apiRoutes, api_ } from "config";

export namespace ISendValidationCode {
    export type Input = {
        email: string;
        codeType: 'email' | 'password';
        sendType: 'email';
        isAdmin?: boolean;
    };
    export type Output = void;
}

export const sendValidationCode = async (input: ISendValidationCode.Input): Promise<ISendValidationCode.Output> => {
    const url = apiRoutes.v1.auth + '/sendValidationCode';

    await api_.post(url, input);
}