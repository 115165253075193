import { api, routes, setAuthToken, setQueryParams } from "../config";
import { IField } from "interfaces";
import { ApprovalItem, TContents, TObject, TType } from "types";

// ------------------- Object -------------------
export const createObject = async (object: TObject.Input): Promise<TObject.Entity> => {
    setAuthToken()

    const formData = new FormData()
    formData.append('title', object.title)
    formData.append('description', object.description)
    formData.append('file', object.file)
    formData.append('modality', object.modality)
    formData.append('price', String(object.price))
    formData.append('typeId', object.typeId)
    formData.append('areaId', object.areaId)

    const response = await api.post<TObject.Entity>(routes.objects, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response.data
}

export const getObject = async (id: string): Promise<TObject.Entity> => {
    setAuthToken()

    const response = await api.get<TObject.Entity>(`${routes.objects}/${id}`)
    return response.data
}

export const updateObject = async (object: Partial<TObject.Entity>): Promise<TObject.Entity> => {
    setAuthToken()

    const formData = new FormData()
    if (object.title) formData.append('title', object.title)
    if (object.description) formData.append('description', object.description)
    if (object.file) formData.append('file', object.file)
    if (object.modality) formData.append('modality', object.modality)
    if (object.price) formData.append('price', String(object.price))
    if (object?.type?.id) formData.append('typeId', object.type.id)
    if (object?.area?.id) formData.append('areaId', object.area.id)
    if (object.status) formData.append('status', object.status)

    const response = await api.put<TObject.Entity>(`${routes.objects}/${object.id}`, object)
    return response.data
}

export const deleteObject = async (id: string): Promise<void> => {
    setAuthToken()

    await api.delete(`${routes.objects}/${id}`)
}

export const getAllObjectsByPartner = async (partnerId: string, search?: TObject.ObjectSearchInput): Promise<TObject.Entity[]> => {
    setAuthToken()

    let url = `${routes.objects}/partner/${partnerId}`
    if (search) url = setQueryParams({ url, params: search })

    const response = await api.get<TObject.Entity[]>(url)
    return response.data
}



export const getObjects = async (search?: TObject.ObjectSearchInput ): Promise<TObject.Entity[]> => {
    setAuthToken()
    let url = search.highlights ?  `${routes.objects}/highlights` :routes.objects
    if (search) url = setQueryParams({ url, params: search })
    const response = await api.get<TObject.Entity[]>(url)
    return response.data
}      

const getObjectById = async (id: string): Promise<TObject.Entity> => {
    const response = await api.get<TObject.Entity>(`${routes.objects}/${id}`)
    return response.data
}

export const getHighlightsObjects = async () => {
    setAuthToken()
    let url = `/v1/objectsHighlights` 
    const response = await api.get<TObject.Entity[]>(url)
    return response.data
}  

export const getRecommendedObjects = async (search?: TObject.ObjectSearchInput ): Promise<TObject.Entity[]> => {
    setAuthToken()
    let url =  `/v1/objectsRecommendation`
    if (search) url = setQueryParams({ url, params: search })
    const response = await api.get<TObject.Entity[]>(url)
    return response.data
}   

export const getAnalysisHistory = async (objectId: string): Promise<ApprovalItem[]> => {
    setAuthToken()

    const response = await api.get<ApprovalItem[]>(`${routes.objects}/${objectId}/analysisHistory`)
    return response.data
}


// ------------------- Content -------------------
export const createContent = async (content: TContents.Content): Promise<TContents.Content> => {
    setAuthToken()
    const response = await api.post<TContents.Content>(routes.content, content)
    return response.data
}

export const getContents = async (objectId: string): Promise<TContents.Content[]> => {
    setAuthToken()
    const response = await api.get<TContents.Content[]>(`${routes.contents}/object/${objectId}`)
    return response.data
}

export const updateContent = async (content: Partial<TContents.Content>): Promise<TContents.Content> => {
    setAuthToken()
    const response = await api.put<TContents.Content>(`${routes.content}/${content.id}`, content)
    return response.data
}

export const deleteContent = async (id: string): Promise<void> => {
    setAuthToken()
    await api.delete(`${routes.content}/${id}`)
}

// ------------------- SubContent -------------------

export const createSubContent = async (subContent: TContents.SubContent): Promise<TContents.SubContent> => {
    setAuthToken()
    const response = await api.post<TContents.SubContent>(routes.subContent, subContent)
    return response.data
}

export const updateSubContent = async (subContent: Partial<TContents.SubContent>): Promise<TContents.SubContent> => {
    setAuthToken()
    const response = await api.put<TContents.SubContent>(`${routes.subContent}/${subContent.id}`, subContent)
    return response.data
}

export const deleteSubContent = async (id: string): Promise<void> => {
    setAuthToken()
    await api.delete(`${routes.subContent}/${id}`)
}

// ------------------- Utils -------------------
export const getModalities = async (): Promise<IField[]> => {
    const response = await api.get(routes.modalities)
    return response.data
}

export const getTypes = async (): Promise<TType.Entity[]> => {
    const response = await api.get(routes.types)
    return response.data
}

export const objectServices = {
    createObject,
    getObject,
    getAllObjectsByPartner,
    createContent,
    getContents,
    updateContent,
    deleteContent,
    createSubContent,
    updateObject,
    deleteSubContent,
    updateSubContent,
    getModalities,
    deleteObject,
    getTypes,
    getObjects,
    getHighlightsObjects,
    getRecommendedObjects,
    getAnalysisHistory,
    getObjectById
}

export default objectServices