import { apiRoutes, api_ } from "config";
import { ObjectStatus } from "enums";

export namespace IAnalyzeObject {
    export type Input = {
        objectId     : string;
        status       : ObjectStatus;
        observation? : string;
    };
    export type Output = void;
}

export const analyzeObject = async (input: IAnalyzeObject.Input): Promise<IAnalyzeObject.Output> => {
    const url = `/v1/analyze/object/${input.objectId}`;
    
    const body = {
        status      : input.status,
        observation : input.observation
    };
    const response = await api_.post(url, body);
}