import { api, routes, setAuthToken } from "../config"
import { ISubscription, TPayment } from "types"

const getPaymentProfiles = async (): Promise<TPayment.PaymentProfile[]> => {
    setAuthToken()
    const response = await api.get<TPayment.PaymentProfile[]>(`${routes.paymentProfiles}`)
    return response.data;
}

// TODO: Any temporário
const classSubscription = async (data: ISubscription.Input): Promise<any> => {
    setAuthToken()
    const response = await api.post(`${routes.class}/${data.classId}/subscription`, data)
    return response.data;
}

const deletePaymentProfile = async (id: number): Promise<void> => {
    setAuthToken()
    await api.delete(`${routes.paymentProfiles}/${id}`)
}

export const orderService = {
    getPaymentProfiles,
    classSubscription,
    deletePaymentProfile
}