import { apiRoutes, api_ } from "config";
import { AreaViewModel } from "models";

export namespace IGetAreas {
    export type Input  = void;
    export type Output = AreaViewModel[];
}

export const getAreas = async (): Promise<IGetAreas.Output> => {
    const url = apiRoutes.v1.areas

    const response = await api_.get<IGetAreas.Output>(url)
    return response.data
}