import BrandImage from 'assets/images/brands/top.svg'
import 'assets/css/headerUser_.css'
import { Link, useNavigate } from "react-router-dom"
import { useSearchForm, useAppSelector, useSidebar, useAppDispatch } from "hooks"
import { UserInfo } from "../../app"
import { Sidebar } from "../../home"
import { UserTypes } from 'enums'
import { useContext, useEffect } from 'react'
import { UserContext } from 'contexts'
import './style.css'
import Cart from 'components/Cart'
import { fetchCartItems, setCartIsActive } from 'slices/CartSlice'

type Props = {
	isAdmin?: boolean
}

export default function UserHeader({ isAdmin=false }: Props) {
	const { showHomeSidebar, setHomeSidebar, showHomeSidebarModules, handleHomeSidebarModuleChange } = useSidebar()
	const { userInfo, profileSelected, handleBecomePartner } = useContext(UserContext);
	const {onSearch, handleSubmitSearch} = useSearchForm()
	const goTo = useNavigate()

	const dispatch = useAppDispatch();
	const cart = useAppSelector((state) => state.cart);

	useEffect(() => {
		if(profileSelected === UserTypes.Apprentice){
		  dispatch(fetchCartItems());
		}
	  }, [profileSelected]);

	  const handleCartMouseEnter = () => {
		if (window.innerWidth > 767) {
			dispatch(setCartIsActive(true));
		}
	};

	const handleCartMouseLeave = () => {
		dispatch(setCartIsActive(false));
	};


	return (
		<>
			<div id="topo">
				<div className="esquerda">
					<div className="marca" role='button'>
						<Link to={'/'} className="logo">
							<img src={BrandImage} alt="" />
						</Link>
					</div>
					<div className="iconeAbirMenuLateral"
						onMouseEnter={() => setHomeSidebar(true)}
						onClick={() => setHomeSidebar(true)}>
						<div className="botao" id="btnAbrirMenuLateral"></div>
					</div>
					
					{/* Exibição do carrinho apenas na visualização mobile */}
					{
						profileSelected === UserTypes.Apprentice && (
							<div className='btn-cart-mobile position-relative'>
								<i className="cart-icon uil-shopping-cart" onClick={() => dispatch(setCartIsActive(!cart.cartIsActive))}></i>
								<div className='counter-items'>{cart.cartList.length}</div>
							</div>
						)
					}
					<div className='btn-cart-mobile'>
						{cart.cartIsActive && <Cart/>}
					</div>
				</div>

				<div className="centro">
					{
						profileSelected === UserTypes.Apprentice && (
							<div className="areaBuscar" style={{ width: '500px'}}>
								<form onSubmit={handleSubmitSearch}>
									<input type="text" name="cp_buscar" placeholder="Buscar Recurso..." onChange={onSearch}/>
									<button type="submit">
										<i className="uil uil-search"></i>
									</button>
								</form>
							</div>
						)
					}
				</div>

				<div className="direita">
					<div className="menuLinks position-relative">
						{
							!isAdmin && (
								<>
									{
										UserTypes.userIsOnlyApprentice(userInfo?.type) && (
											<a className="item" onClick={() => handleBecomePartner()}>
												Virar Parceiro
											</a>
										)
									}
									{
										profileSelected === UserTypes.Apprentice && (
											<a className="item" onClick={() => goTo('/minhas-turmas')}>
												Minhas Turmas
											</a>
										)
									}
									{/* {
										UserTypes.userIsApprenticeAndPartner(userInfo?.type) && (
											<a  
												className="item"
												onClick={() => handleSelectProfile(profileSelected == "A" ? UserTypes.Partner : UserTypes.Apprentice)}>{
												profileSelected === "A" ? "Parceiro" : "Aprendiz"}
											</a>
										)
									} */
									}
										<a className="item" href='/'> Home </a>
									{
										profileSelected === UserTypes.Apprentice && (
											<div className='position-relative'
												onMouseEnter={handleCartMouseEnter}>
												{/* // onMouseLeave={handleCartMouseLeave} */}
												<i className="cart-icon uil-shopping-cart" 
												onClick={() => dispatch(setCartIsActive(!cart.cartIsActive))}
												>
												</i>
												<div className='counter-items'>{cart.cartList.length}</div>
											</div>
										)
									}
									{cart.cartIsActive && <div onMouseLeave={handleCartMouseLeave}><Cart/></div>}
								</>
							)
						}
					</div>
					<UserInfo />
				</div>
			</div>

			<Sidebar
				showHomeSidebar={showHomeSidebar}
				setHomeSidebar={setHomeSidebar}
				showHomeSidebarModules={showHomeSidebarModules}
				handleHomeSidebarModuleChange={handleHomeSidebarModuleChange}
				isAdmin={isAdmin}
			/>
		</>

	)
}