import { apiRoutes, api_ } from "config";

export namespace IDeleteArea {
    export type Input = { id: string }
    export type Output = void
}

export const deleteArea = async (info: IDeleteArea.Input): Promise<IDeleteArea.Output> => {
    const url = `${apiRoutes.v1.areas}/${info.id}`;
    await api_.delete(url);
}