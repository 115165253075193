import { Modality, ObjectStatus, PaymentType } from "enums";
import { TField } from "types";

export const OBJECT_STATUS: TField[] = [
    { text: "Criando", value: ObjectStatus.Creating },
    { text: "Pendente", value: ObjectStatus.Pending },
    { text: "Aprovado", value: ObjectStatus.Approved },
    { text: "Reprovado", value: ObjectStatus.Disapproved },
]

export const PAYMENT_TYPES: TField[] = [
    { text: "Grátis", value: PaymentType.Free },
    { text: "Pago", value: PaymentType.Paid },
]

export const OBJECT_MODALITIES: TField[] = [
    { text: "Online", value: Modality.Online },
    { text: "Presencial", value: Modality.InPerson },
    { text: "Híbrido", value: Modality.Hybrid },
]

export const SESSION_STORAGE_KEYS = {
    COURSE_INTEGRATION_TEMP_PROVIDER_DATA: 'courseIntegrationTempProviderData',
    OBJECT_TEMP_DATA: 'objectTempData',
}