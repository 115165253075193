import { ReactNode } from "react"

type Props = {
    children: ReactNode
}

export default function ObjectBody({ children }: Props) {
    return (
        <div id="corpo">
            <div className="caixa menor">
                {children}
            </div>
        </div>
    )
}