import { apiRoutes, api_ } from "config";

export namespace IUpdateManyProgress {
    export type Input = {
        classId: string,
        userIds: string[],
    };
    export type Output = void
}

export const updateManyProgress = async (data: IUpdateManyProgress.Input): Promise<IUpdateManyProgress.Output> => {
    const url = `${apiRoutes.v1.subscriptions}/class/${data.classId}`;
    await api_.put(url, data);
}