import { useState } from "react"

type Return = {
    passwordState: object
    handleChangeStatePassword: Function
}

export const usePasswordState = (): Return => {
    const [passwordState, setPasswordState] = useState({})
    
    const handleChangeStatePassword = (name: string): void => {
        let newPasswordState = {...passwordState}
        newPasswordState[name] = passwordState[name] ? false : true
        setPasswordState(newPasswordState)
    }

    return { passwordState, handleChangeStatePassword }
}