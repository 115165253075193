import { apiRoutes, api_ } from "config";

export namespace IDeleteObject {
    export type Input = { id: string };
    export type Output = void;
}

export const deleteObject = async ({ id }: IDeleteObject.Input): Promise<IDeleteObject.Output> => {
    const url = `${apiRoutes.v1.objects}/${id}`;
    await api_.delete(url);
}