import { apiRoutes, api_ } from "config";
import { Status } from "enums";
import { ClassInputModel, ClassViewModel } from "models";
import { TAddress } from "types";

export namespace IUpdateClass {
    export type Input = Partial<
        Omit<ClassInputModel, 'address'> 
        & TAddress
    >;
    export type Output = ClassViewModel;
}

export const updateClass = async (data: IUpdateClass.Input): Promise<IUpdateClass.Output> => {

    if (!data.statusBool || data.statusBool){
        data.status = data?.statusBool ? Status.Active : Status.Inactive
    }    
    const body: Partial<ClassInputModel> = {
        ...data,
        status: data.status,
        limitOfUsers: Number(data?.limitOfUsers),
        startDate: data?.startDate,
        endDate: data?.endDate,
        subscriptionDeadline: data?.subscriptionDeadline,
        address: {
            address: data?.address,
            city: data?.city,
            cep: data?.cep,
            state: data?.state,
            neighborhood: data?.neighborhood,
            number: data?.number,
        }
    }

    const url = `${apiRoutes.v1.class}/${data.id}`;
    const response = await api_.put<IUpdateClass.Output>(url, body);
    return response.data;
}