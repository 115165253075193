import { useState } from "react";
import { Loading } from "components";
import { useAppSelector } from "hooks";
import CardInfo from "./CardInfo";
import PaymentForm from "./Form";
import './style.css'

export default function PaymentProfiles() {
    const { paymentProfiles, isLoading } = useAppSelector(state => state.order)
    const [onAddCard, setOnAddCard] = useState(false);

    const handleAddCard = () => setOnAddCard(!onAddCard)
    return( 
        <>  
            {
                isLoading ? 
                    <div className="loading-profiles">
                        <Loading.Small />
                    </div> : 
                    <>
                        {
                            onAddCard || !paymentProfiles.length ?
                            <PaymentForm 
                                onUseSavedCards={handleAddCard}
                            />
                            :
                            <>
                                {
                                    paymentProfiles.map(profile => (
                                        <div> <CardInfo data={profile} /> </div>
                                    ))
                                }
                                <div className='add-card' onClick={handleAddCard}>        
                                    + utilizar outro cartão
                                </div>
                            </>
                        }
                    </>
            }
        </>
    )
}