import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserTypes } from "enums";
import { useAppDispatch, useAppSelector } from "hooks";
import './style.css'
import { UserContext } from "contexts";

export default function UserInfo() {
    const { userInfo, profileSelected, handleLogout, handleSelectProfile, handleBecomePartner } = useContext(UserContext);

    const [menuShow, setMenuShow] = useState(false)
    const dispatch = useAppDispatch()
    const goTo = useNavigate()

    const profilePicture = userInfo?.profilePicture || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'
    const handleLogout_ = () => {
        handleLogout()
        window.location.reload()
    }

    return (
        <>
            <div className={`perfil ${menuShow && 'aberto'}`}
                onMouseEnter={() => window.innerWidth > 767 && setMenuShow(true)}
                onMouseLeave={() => setMenuShow(false)}>

                <div className="identificacao">

                    {/* <!--<div className="abreviacao">FC</div>--> */}

                    <img src={profilePicture} alt="" />
                </div>


                <div className="menu">
                    <div className="areaSeta">
                        <div className="setinha"></div>
                    </div>

                    <div className="lista">
                        <div className="botaoMobileFechar">
                            <i className="uil uil-multiply"></i>
                        </div>

                        <div className="nome">{userInfo?.name}</div>
                        {
                            profileSelected === UserTypes.Apprentice && (
                                <>
                                    <a
                                        className="link icoEstrela"
                                        onClick={() => goTo('/minhas-turmas')}
                                    >
                                        Minhas Turmas
                                    </a>
                                    <a
                                        className="link icoShopping"
                                        onClick={() => goTo('/minhas-compras')}
                                    >
                                        Minhas Compras
                                    </a>

                                    {/* <a
                                        className="link icoWallet"
                                        onClick={() => goTo('/carteira')}
                                    >
                                        Carteira
                                    </a> */}
                                </>
                            )
                        }

                        {/* TODO: Implementar no próximo MVP */}
                        {/* <a className="link icoLapis">Editar dados</a> */}

                        {
                            UserTypes.userIsOnlyApprentice(userInfo?.type) && (
                                <a
                                    className="link icoMaleta"
                                    onClick={() => handleBecomePartner()}
                                >
                                    Virar Parceiro
                                </a>
                            )
                        }
                        {
                            UserTypes.userIsApprenticeAndPartner(userInfo?.type) && (
                                <a
                                    className="link icoSync"
                                    onClick={() => handleSelectProfile(profileSelected == "A" ? UserTypes.Partner : UserTypes.Apprentice, true)}
                                >
                                    Alternar para {profileSelected === "A" ? "Parceiro" : "Aprendiz"}
                                </a>
                            )
                        }

                        {/* <a
                            className="link home-link"
                            onClick={() => goTo('/home')}
                        >
                            Home
                        </a> */}

                        <a className="link icoPorta"
                            onClick={() => handleLogout_()}>Sair</a>
                    </div>
                </div>

            </div>

        </>
    )
}