type Props = {
    children: any
    title: String
    description: String
}

export default function Depositions({ children, title, description }: Props) {
    return (
        <div className="inicioDepoimentos">
            <div className="container-fluid areas">
                <div className="row cabecalho">
                    <div className="col-xl-12">
                        <div className="titulo">{title}</div>
                        <div className="descricao">
                            {description}
                        </div>
                    </div>
                </div>

                { children }
            </div>
        </div>
    )
}