import { apiRoutes, api_ } from "config";

export namespace IDeleteContent {
    export type Input = { id: string };
    export type Output = void;
}

export const deleteContent = async (input: IDeleteContent.Input): Promise<IDeleteContent.Output> => {
    const url = `${apiRoutes.v1.content}/${input.id}`;
    await api_.delete(url);
}