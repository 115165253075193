import { apiRoutes, api_ } from "config"
import { Status } from "enums"
import { AreaInputModel, AreaModel, AreaViewModel } from "models"

export namespace ISaveArea {
    export type Input = AreaInputModel
    export type Output = AreaViewModel
}

export const saveArea = async (info: ISaveArea.Input): Promise<ISaveArea.Output> => {
    const url = apiRoutes.v1.areas;

    const body: Pick<AreaModel, 'name' | 'status'> = {
        name: info.name,
        status: info.statusBool ? Status.Active : Status.Inactive
    }

    const response = await api_.post<AreaViewModel>(url, body);
    return response.data;
}