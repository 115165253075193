import UserHeader from "components/templates/Header/UserHeader";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadGlobalValues } from 'slices/GlobalValues';
import { useEffect } from 'react';

export default function AdminApp() {
    const { areas, types } = useAppSelector((state) => state.globalValues)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const emptyAreas = areas.length === 0
        const emptyTypes = types.length === 0

        if (emptyAreas || emptyTypes) dispatch(loadGlobalValues())
    }, [])

    return (
        <div className="AdminApp">
            <UserHeader isAdmin/>
            <Outlet />
        </div>
    )
}