import { ContentViewModel, SubContentViewModel } from 'models';
import React, { useCallback, useEffect, useState } from 'react'
import { TContents } from 'types'
import AccordionContent from './Content';
import './style.css'

export type ContentState = Omit<ContentViewModel, 'subContents'> & {
	elementContent? : React.ReactNode
	subContents? 	: SubContentState[]
	isOpen       	: boolean
}
type SubContentState = SubContentViewModel & {
	isOpen : boolean
}

type Props = {
	data	  : ContentState[]
	children? : React.ReactNode
	title?    : string
	onPaymentMethodSelect?: (method: string) => void;
}
export default function ObjectAccordion({ data, children, title, onPaymentMethodSelect }: Props) {
	const [contents, setContents] = useState<ContentState[]>([])

	const handleOpenContent = useCallback((id: string, isSubContent: boolean = false) => {
		let selectedMethod = '';
		if (isSubContent) {
			setContents(contents.map(content => {
				
				if (content.subContents) {
					return {
						...content,
						subContents: content.subContents.map(subContent => {
							if (subContent.id === id) {
								return {
									...subContent,
									isOpen: !subContent.isOpen
								}
							}
							return { ...subContent, isOpen: false }
						})
					}
				}
				return content
			}))
		} else {
			setContents(contents.map(content => {
				if (content.id === id) {
					selectedMethod = content.title; // Assume title como o nome do método de pagamento
					return { ...content, isOpen: !content.isOpen };
				}
				return { ...content, isOpen: false };
			}));
		}
		if (selectedMethod && onPaymentMethodSelect) {
			onPaymentMethodSelect(selectedMethod);
		}
	},
	[contents, onPaymentMethodSelect]
);

	useEffect(() => {
		if (data) {
			const contents_ = data.map((content): ContentState => {
				return {
						...content,
						subContents	: content.subContents?.map(subContent => ({ ...subContent, isOpen: false })),
						isOpen 		  : false
				}
			})
			setContents(contents_)
		}
	}, [])

	
	return (
		<>
			{ !!title && <h3 className="titulo">{ title }</h3> }
			<div className="accordion" id="accordionRecurso">
				{
					children == undefined && !!contents.length && contents.map((content, index) => {
						return (
							<AccordionContent
								index={index}
								data={content}
								isOpen={content.isOpen}
								onOpen={handleOpenContent}
								key={'content-' + index}
							>
								{content.elementContent}
							</AccordionContent>
						)
					})
				}

			</div>
		</>
	)
}