import { LinkedinCertificateModel } from "models";

export namespace ICreateLinkedinCertificate {
    export type Input = LinkedinCertificateModel
    export type Output = void
}

export const CreateLinkedinCertificate = async (data: ICreateLinkedinCertificate.Input): Promise<ICreateLinkedinCertificate.Output> => {
    const url = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${data.name.replaceAll(/\s/g, '%20')}&organizationName=${data.organizationName}&issueYear=${data.issueYear}&issueMonth=${data.issueMonth}&expirationYear=${data.expirationYear}&expirationMonth=${data.expirationMonth}&certUrl=${data.certUrl.replaceAll(/\//g, '%2F')}&certId=${data.certId}
    `
    window.open(url, "_blank")
}