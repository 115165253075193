import { Button } from 'components'
import { windowConfirm } from 'Functions'
import { TPayment } from 'types/Payment'
import { useEffect, useState } from 'react'
import './style.css'
import { useAppDispatch, useAppSelector } from 'hooks'
import { deletePaymentProfile, removePaymentProfile, setPaymentProfile } from 'slices/OrderSlice'
import { PaymentMethods } from 'enums'
import ModalDeletionConfirmation from 'components/ModalDeletionConfirmation'

type Props = {
    data         : TPayment.PaymentProfile;
}
export default function CardInfo({ data }: Props) {
    const { order }               = useAppSelector(state => state.order);
    const [selected, setSelected] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useAppDispatch();

    const expirationMonth = new Date(data.card_expiration).getMonth() + 1 < 10 ? 
        '0' + (new Date(data.card_expiration).getMonth() + 1) : new Date(data.card_expiration).getMonth() + 1
    const expirationYear  = new Date(data.card_expiration).getFullYear() - 2000

    const flagImage = (flag: string) => {
        if (flag == 'mastercard') return require('assets/images/cartoes/mastercard.png')
        else if (flag == 'visa') return require('assets/images/cartoes/visa.png')
    }

    const handleDeleteCard = () => dispatch(deletePaymentProfile(data.id))

    const handleSelectCard = () => dispatch(setPaymentProfile({
        payment_method     : PaymentMethods.CREDIT_CARD,
        payment_profile_id : data.id
    }))

    useEffect(() => { setSelected(order.payment?.payment_profile_id === data.id) }, [order.payment?.payment_profile_id])

    return (
        <div className={'row cards-container ' + (selected && 'selected')} onClick={handleSelectCard}>
            <div className='col-sm-2 esquerda'>
                <img src={flagImage(data.card_flag)} className='card-flag'></img>
            </div>

            <div className='col-sm-10 direita'>

                <div className='row card-info'>
                    Terminado em {data.card_number.slice(-4)}
                </div>

                <div className='row card-info'>
                    <div className='col validity'>
                        {expirationMonth}/{expirationYear}
                    </div>

                   <div className='col card-button-area-remove'>
                        <Button
                            type="button"
                            className="card-button-remove"
                            onClick={()=> setShowModal(true)}
                        >
                            <i className="uil uil-trash"></i>
                        </Button>
                    </div>
                    
                </div>
            </div>
            <ModalDeletionConfirmation 
                itemName={'cartão'} 
                showModal={showModal}
                confirmDeletion={handleDeleteCard}
                setShowModal={setShowModal}
            />
        </div>
    )
}