import { Button, ButtonArea, Container, FieldSet, Loading, Notification, ObjectAccordion } from "components";
import { ObjectStatus, PaymentType } from "enums";
import { useService } from "hooks";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { calculateTime } from "Functions";
import ApprobationModal from "./ApprobationModal";
import Forma from 'assets/images/forma.png';
import { ObjectViewModel } from "models";
import { getObject } from "services_/Object";
import { toast } from "react-hot-toast";
import { ObjectAnalysisContext } from "contexts";
import './style.css'
import { access } from "services_/CourseIntegration";

enum ModalityTranslation {
    'P' = 'Presencial',
    'H' = 'Híbrido',
    'O' = 'Online',
}

export default function ObjectApprobation() {
    const { handleSubmitAnalysis, sendingAnalysis }  = useContext(ObjectAnalysisContext)

    const [showModal, setShowModal] = useState(false)
    const [object, setObject] = useState<ObjectViewModel>(null);

    const params = useParams<{ objectId: string }>()
    const goTo = useNavigate()

    const {
        isLoading: loadingObject,
        handle: handleGetObject,
    } = useService({
        service: () => getObject({ id: params.objectId }),
        onLoading: () => toast.loading('Carregando recurso...'),
        onSuccess: (res) => {
            toast.dismiss()

            if (res?.status !== ObjectStatus.Pending) goTo('/admin/home');
            else setObject(res);
        },
        onError: (error) => {
            toast.dismiss()
            toast.error(error?.response?.data?.message, { duration: 15000 , position: 'bottom-center'})
            
            if (error?.response?.status === 404) goTo('/admin/home');
        }
    })

    const {
        isLoading: loadingAccess,
        handle: handleAccess,
    } = useService({
        service: () => access({ provider: object?.provider, objectId: object?.id }),
        onSuccess: (res) => { window.open(res, "_blank"); }
    })

    const LOADING_PAGE = loadingObject || sendingAnalysis;

    useEffect(() => { handleGetObject() }, [params.objectId])

    return (
        <>
            {
                !LOADING_PAGE ? (
                    <>
                        {/* TODO: Componentizar */}
                        <div id="bannerInternas" style={{ height: '510px'}}>
                            <div className="fundo">
                                <img src={object?.image} alt="" />
                            </div>
                            
                            <div className="dados">
                            
                                <div className="esq">
                                    <div className='recurso-arrow-area'>
                                        <div onClick={() => goTo(`/admin/home`)} className='uil uil-arrow-left recurso-arrow-left'/>
                                    </div>
                                    
                                    <div className="titulo">{ object?.title }</div>
                                </div>


                                <div className="dir">
                                    <div className="quadro">
                                        <img src={object?.image} alt="" className="foto" />
                                        {
                                            !!object?.provider && (
                                                <div 
                                                    role='button'
                                                    className="botao"
                                                    style={{ marginTop: '20px' }}
                                                    onClick={handleAccess}
                                                >
                                                    ACESSAR RECURSO
                                                    <i style={{marginLeft: '5px'}} className="uil uil-external-link-alt"></i>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="forma">
                                <img src={Forma} alt="" />
                            </div>
                        </div>
                        {/* ---------------------------------------- */}
                        <Container.ObjectBody>
                            <div className="col-xl-12">
                                <h3 className="titulo text-center">Informações gerais</h3>

                                <FieldSet
                                    title='Informações do Parceiro'
                                    items={[
                                        { title: 'Nome', description: object?.user?.name },
                                        { title: 'Tempo de Plataforma', description: calculateTime(object?.user.createdAt) },
                                    ]}
                                />

                                <FieldSet
                                    title='Informações do Recurso'
                                    items={[
                                        { title: 'Nome', description: object?.title },
                                        { title: 'Descrição', description: object?.description },
                                        { title: 'Tipo', description: object?.type?.name },
                                        { title: 'Área', description: object?.area?.name },
                                        { title: 'Modalidade', description: ModalityTranslation[object?.modality] },
                                        { title: 'Preço', description: (object?.paymentType === PaymentType.Free ? 'Gratuito' : String(object?.price)) },
                                        { title: 'Serviço Externo', description: !!object?.provider ? object?.provider : 'Nenhum' },
                                        { title: 'Data de Criação', description: new Date(object?.createdAt).toLocaleDateString('pt-br') },
                                    ]}
                                />

                                <FieldSet title='Conteúdos do Recurso'>
                                    {/* TODO: Alterar para o accordion refatorado (Mesmo utilizadono cadastro de recurso). */}
                                    <div><ObjectAccordion data={
                                        object?.contents?.map(content => ({
                                            id: content.id,
                                            title: content.title,
                                            description: content.description,
                                            isOpen: false,
                                            subContents: content.subContents?.map(subContent => ({
                                                id: subContent.id,
                                                title: subContent.title,
                                                description: subContent.description,
                                                isOpen: false,
                                            })) as any
                                        }))
                                    } /> </div>
                                </FieldSet>
                            </div>

                            <ButtonArea className='text-center'>
                                <Button 
                                    className='admin verde' 
                                    type='button' 
                                    loading={sendingAnalysis}
                                    disabled={LOADING_PAGE}
                                    onClick={() => handleSubmitAnalysis({
                                        status   : ObjectStatus.Approved,
                                        objectId : params.objectId,
                                    })}
                                >
                                    <i className="uil uil-check-circle"></i> Aprovar recurso
                                </Button>
                                <Button 
                                className='admin vermelho' 
                                type='button' 
                                onClick={()=> setShowModal(true)}
                                disabled={LOADING_PAGE}>
                                    <i className="uil uil-times-circle"></i> Reprovar recurso
                                </Button>
                            </ButtonArea>

                            <ApprobationModal show={showModal} onClose={()=> {!sendingAnalysis && setShowModal(false)}}/>
                        </Container.ObjectBody>

                    </>
                ) : (
                    <Loading.Big />
                    )
                }
            <Notification position='bottom-left' />
        </>
    )
}