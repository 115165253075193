import './style.css'

type Props = {
    children   : React.ReactNode
    className? : string
}

export default function ButtonArea({ children, className }: Props) {
    return (
        <div className={`col-xl-12 linhaBotoes ${className}`}>
            { children }
        </div>
    )
}