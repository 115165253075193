import { useAppDispatch, useAppSelector, useService } from "hooks";
import { useEffect, Fragment, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Banner, Box, Button, Container, Loading } from "components";
import Forma from 'assets/images/forma.png';
import { getObjects } from "services_/Object";
import { ObjectStatus } from "enums";
import { toast } from "react-hot-toast";
import { setObjectsToAnalyze } from "slices/GlobalObjects";
import { AdminContext } from "contexts";
import './style.css'
import AdminHomeCard from "./Card";

export const AdminHome: React.FC = () => {
    const { objectsToAnalyze } = useAppSelector((state) => state.globalObjects)
    const { adminInfo } = useContext(AdminContext);

    const goTo = useNavigate()
    const dispatch = useAppDispatch()

    const {
        isLoading: loadingObjectsToAnalyze,
        handle: handleGetObjectsToAnalyze,
    } = useService({
        service: () => getObjects({ search: { status: ObjectStatus.Pending } }),
        onLoading: () => toast.loading('Carregando recursos para aprovação...'),
        onSuccess: (response) => {
            toast.dismiss()
            dispatch(setObjectsToAnalyze(response));
        },
        onError: (error) => {
            toast.dismiss()
            toast.error(error?.response?.data?.message, { duration: 15000 , position: 'top-center'})
        },
    })

    const LOADING_PAGE = loadingObjectsToAnalyze

    useEffect(() => { if (!objectsToAnalyze.length) handleGetObjectsToAnalyze() }, [])

    return (
        <>
            {
                !LOADING_PAGE ? (
                    <>
                        <Banner />
                        <Container.Internals>
                            <div className="title-config">
                                Olá <span className="title-highlight">{adminInfo?.name}</span>,
                                {objectsToAnalyze.length > 0 ? '' : ' não '} existem recursos a serem aprovados.
                            </div>
                            {
                                objectsToAnalyze.map((object, index) => (
                                    <>
                                        <Fragment key={index}>
                                            <AdminHomeCard title={object.title} link={() => goTo(`/admin/recurso/${object?.id}`)} />
                                        </Fragment>
                                    </>

                                ))
                            }
                        </Container.Internals>
                    </>
                ) : <Loading.Big />
            }
        </>
    );
}

export default AdminHome;