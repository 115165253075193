import { Accordion_, Button, ButtonArea } from "components";
import { ContentOrSubContent, ContentRegisterContext } from "../ContentRegisterContext";
import { useContext, useState } from "react";
import { deleteSubContent } from "services_/SubContent";
import { deleteContent } from "services_/Content";
import { useService } from "hooks";
import { toast } from "react-hot-toast";
import { ObjectRegisterContext } from "contexts";
import { windowConfirm } from "Functions";
import './style.css'
import ModalDeletionConfirmation from "components/ModalDeletionConfirmation";

type Props = {
    data: ContentOrSubContent;
    index: number;
    isSubContent?: boolean;
    viewOnly: boolean;
}
export default function Content({ data, viewOnly, isSubContent = false, index }: Props) {
    const { openContentModal } = useContext(ContentRegisterContext)
    const { object, setObject } = useContext(ObjectRegisterContext)
    const [showModal, setShowModal] = useState(false);

    const {
        isLoading: deletingContentOrSubContent,
        handle: handleDeleteContentOrSubContent
    } = useService({
        service: () => {
            if (isSubContent) return deleteSubContent({ id: data?.id });
            else return deleteContent({ id: data?.id });
        },
        onLoading: () => toast.loading(`Excluindo ${isSubContent ? 'SubConteúdo' : 'Conteúdo'}...`, { duration: 0 }),
        onSuccess: (res) => {
            toast.dismiss()
            toast.success(`${isSubContent ? 'SubConteúdo' : 'Conteúdo'} excluído com sucesso.`)

            if (!isSubContent) {
                const updatedContents = object?.contents.filter((content) => content.id !== data?.id);
                setObject({ ...object, contents: updatedContents });
            } else {
                console.log('content', data)
                const updatedContents = object?.contents?.map((content) => {
                    if (content.id === data?.contentId) {
                        const updatedSubContents = content?.subContents?.filter((subContent) => subContent.id !== data?.id);
                        return { ...content, subContents: updatedSubContents }
                    }
                    return content;
                })
                setObject({ ...object, contents: updatedContents });
            }
        },
        onError: (err) => {
            toast.dismiss();
            toast.error(err?.response?.data?.message, { duration: 15000 , position: 'top-center'})
        }
    })

    return (
        <Accordion_.Item className="content-item" opened={index === 0 && !isSubContent}>
            <Accordion_.Header secondary={isSubContent}> {data?.title} </Accordion_.Header>
            <Accordion_.Body secondary={isSubContent}>
                <p style={{ textAlign: 'left' }}> {data?.description} </p>
                {
                    !isSubContent && (
                        <Accordion_ secondary={true}>
                            {
                                !!data?.subContents?.length && (
                                    data?.subContents.map((subContent, index) => (
                                        <Content
                                            data={subContent}
                                            key={subContent.id}
                                            isSubContent={true}
                                            viewOnly={viewOnly}
                                            index={index}
                                        />
                                    ))
                                )
                            }
                        </Accordion_>
                    )
                }

                {
                    !viewOnly && (
                        <ButtonArea className="content-buttons">
                            <Button
                                className="content-buttons-button"
                                type="button"
                                disabled={deletingContentOrSubContent}
                                onClick={() => openContentModal({
                                    type: isSubContent ? 'subContent' : 'content',
                                    action: 'edit',
                                    selectedContent: data
                                })}
                            >
                                Editar
                            </Button>
                            {
                                !isSubContent && (
                                    <Button
                                        className="content-buttons-button"
                                        type="button"
                                        disabled={deletingContentOrSubContent}
                                        onClick={() => openContentModal({
                                            type: 'subContent',
                                            action: 'create',
                                            selectedContent: data
                                        })}
                                    >
                                        Adicionar SubConteúdo
                                    </Button>
                                )
                            }
                            <Button
                                className="content-buttons-button deleteButton exclude"
                                type="button"
                                disabled={deletingContentOrSubContent}
                                loading={deletingContentOrSubContent}
                                onClick={() => setShowModal(true)}>
                                <i className="uil uil-trash"></i>Excluir
                            </Button>
                        </ButtonArea>
                    )
                }
            </Accordion_.Body>
            <ModalDeletionConfirmation 
                itemName={data.title} 
                confirmDeletion={isSubContent ? handleDeleteContentOrSubContent : handleDeleteContentOrSubContent}
                showModal={showModal}
                setShowModal={setShowModal}/>
        </Accordion_.Item>
    )
}