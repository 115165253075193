import { apiRoutes, api_ } from "config";

export namespace IGenerateCertificate {
    export type Input = {
        classId: string;
        userId: string;
    }
    export type Output = {
        certificateURL: string;
    }
}

export const generateCertificate = async (data: IGenerateCertificate.Input): Promise<IGenerateCertificate.Output> => {
    const url = `${apiRoutes.v1.subscriptions}/class/${data.classId}/certificate`;

    const response = await api_.post(url, data);
    return response.data;
}