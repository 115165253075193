import { api, routes } from "config"
import { IPasswordReset } from "interfaces"
import { TObject } from "types"

const changePassword = async (info: IPasswordReset) => {
    const body = {
        password        : info.password,
        confirmPassword : info.confirmPassword
    }
    await api.patch(routes.admin + '/passwordReset', body, { headers: { 'Token': info.token } })
}

const analyzeObject = async (input: TObject.AnalyzeObject) => {
    const body = {
        status      : input.status,
        observation : input.observation
    }
    const response = await api.post(`${routes.analyze}/object/${input.objectId}`, body)
    return response.data
}

export const adminServices = {
    changePassword,
    analyzeObject
}