import { Banner, Container, ListItems, Loading, Notification, Slides } from "components";
import 'assets/css/homeUser.css'
import '../ApprenticeHome/style.css'
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { useEffect, useContext } from 'react'
import { UserContext } from "contexts";
import { getSubscribedClasses } from "services_/User";
import { setApprenticeClasses } from "slices/GlobalObjects";
import { toast } from "react-hot-toast";

export default function ApprenticeResource() {
    const { apprenticeClasses } = useAppSelector(state => state.globalObjects)
    const { userInfo } = useContext(UserContext);

    const dispatch = useAppDispatch();

    const {
        isLoading: loadingApprenticeClasses,
        handle: handleGetApprenticeClasses,
    } = useService({
        service: () => getSubscribedClasses(),
        onSuccess: (res) => dispatch(setApprenticeClasses(res)),
        onError: (err) => toast.error(err?.response?.data?.message || 'Erro ao carregar as turmas inscritas do aluno.', { duration: 15000 , position: 'top-center'}),
    })

    const LOADING_PAGE = loadingApprenticeClasses

    useEffect(() => {
        if (!apprenticeClasses.length) handleGetApprenticeClasses()
    }, [])

    return (
        <div className="home-user a">
            {/* <PaymentAlert
                text="Recursos com pagamento em processo."
                count={pendingOrders.length} 
                variant="warning"
                show={!!pendingOrders.length}/> */}
                
            <Banner />  
            <Container.Internals>

                {/* <SearchFilters
                    showAreas={true}
                    showModalities={true}
                    showTypes={true}
                /> */}

                
            {   
                !LOADING_PAGE ?
                !apprenticeClasses.length ?
                <div className="col-xl-12">
                    <div className="tituloArea text-center">Olá <em>{userInfo?.name}</em>, Você ainda não tem recursos.</div>
                    <div className="descricaoArea text-center">Adquirda já novos recursos.</div>
                </div> : 
                (
                    <ListItems
                        data={{ classes: apprenticeClasses }}
                        listType="classes"
                        maxItemShow={0}
                        titleType="homeObjects"
                        leftTitle="Essas são as suas turmas, "
                        middleTitle={userInfo?.name}
                        rightTitle=". "
                        description="Estas já foram adquiridas por você."
                        partnerObject= {false}
                    />
                )
                :
                <div className='loading-box'>
                    <Loading.Small />
                </div>
            }
            </Container.Internals>
            <Notification position="bottom-left" />
        </div>

    )
}