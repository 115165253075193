import { Banner, SearchFilters, Container, ResultsLabel, ListItems, Notification } from "components";
import { useContext, useEffect, useState } from "react";
import { useAppSelector, useService } from "hooks";
import { getObjects, IGetObjects } from "services_/Object";
import { ObjectsSearchContext } from "contexts";
import { toast } from "react-hot-toast";
import { ObjectStatus, Status } from "enums";

export default function Search() {
    const [foundObjects, setFoundObjects] = useState<IGetObjects.Output>([]);
    const { areas, modalities, types } = useAppSelector((state) => state.globalValues)
    
    const { getSearchValues, page, searchParams } = useContext(ObjectsSearchContext);

    const ITEMS_PER_PAGE = 9

    const { 
        isLoading: loadingObjects, 
        error: objectsError,
        handle: handleGetObjects
    } = useService({
        service: () => getObjects({ search: getSearchValues({ size: ITEMS_PER_PAGE + 1, status: ObjectStatus.Approved })}),
        onLoading: () => toast.loading('Buscando recursos...', { duration: null }),
        onSuccess: () => toast.dismiss(),
        onError: () => {
            toast.dismiss()
            console.error('Erro ao buscar os recursos!')
        }
    })
    useEffect(() => {
        handleGetObjects().then((res) => setFoundObjects(res)) 
    }, [searchParams])

    useEffect(() => {
        if (page > 1) handleGetObjects().then((res) => setFoundObjects([...foundObjects, ...res]))
    }, [page])

    return (
        <>
            <Banner />
            <Container.Internals>
                <SearchFilters
                    modalities={modalities}
                    areas={ areas.filter((area) => area.status === Status.Active) }
                    types={ types.filter((type) => type.status === Status.Active) }
                />
                <ResultsLabel
                    loading={loadingObjects}
                    numResults={
                        foundObjects.length > (ITEMS_PER_PAGE * page) ? 
                        ITEMS_PER_PAGE * page : foundObjects?.length
                    }
                />

                <ListItems
                    titleType="search"
                    maxItemShow={ITEMS_PER_PAGE} 
                    data={{ objects: foundObjects }}
                    listType="objects"
                    partnerObject={false}
                />

            </Container.Internals>
            <Notification position="bottom-left" />
        </>
    )
}