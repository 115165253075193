import { useAppDispatch, useAppSelector } from 'hooks';
import { loadGlobalValues } from 'slices/GlobalValues';
import { Outlet, useLocation } from 'react-router-dom';
import { Header_, Steps } from './components';
import { useEffect, useContext } from 'react';
import { UserContext, ObjectAnalysisProvider } from 'contexts';

function App() {
  const { areas, types } = useAppSelector((state) => state.globalValues)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { handleAdditionalInfo } = useContext(UserContext)

  useEffect(() => {
      const emptyAreas = areas.length === 0
      const emptyTypes = types.length === 0

      if (emptyAreas || emptyTypes) dispatch(loadGlobalValues())
  }, [])

  useEffect(() => { handleAdditionalInfo() }, [pathname])

  return (
    <ObjectAnalysisProvider>
    <div className="App">
      <Header_ setHomeSidebar={false} />
      <Outlet />
      <Steps.AuthSteps />
    </div>
    </ObjectAnalysisProvider>
  );
}

export default App;
