import { useEffect, useState, useContext } from "react";
import { Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Button, ButtonArea, Checkbox, Input, Notification, TextArea } from "components";
import { Modality, Status } from "enums";
import { fixDateTime, isDeadlinePassed } from "Functions";
import { useForm, useService } from "hooks";
import { ClassViewModel } from "models";
import { ObjectRegisterContext } from "contexts";
import { ICreateClass, createClass, deleteClass, updateClass } from "services_/Class";
import { utilsService } from "services";
import "./style.css";
import { TAddress } from "types";
import ModalDeletionConfirmation from "components/ModalDeletionConfirmation";

type Props = {
  data: ClassViewModel;
  handleSetClass: React.Dispatch<React.SetStateAction<ClassViewModel>>;
  className?: string;
  setManageSubscriptions: (value: boolean | " ") => void;
  handleAddClass: (title: string, info: string, limit: number, link: string, address: TAddress) => void;
  handleGoToInfos?: () => void;
};
export default function ClassInfos({
  data,
  handleSetClass,
  className = "",
  setManageSubscriptions,
  handleAddClass,
  handleGoToInfos = () => void "",
}: // handleGoToInfos = () => void "",
Props) {
  const {
    object,
    classes,
    setClasses,
    removeClass: removeOneClass,
    updateClass: updateOneClass,
  } = useContext(ObjectRegisterContext);
  const [class_, setClass_] = useState<ClassViewModel>(null);
  const [showModal, setShowModal] = useState(false);
  const MIN_DATETIME = `${new Date().toISOString().substring(0, 10)}T00:00:00.00`;

  const CLASS_CLOSED = class_?.expired && !data?.id?.match("Temp");
  const CLASS_STARTED = new Date().getTime() > new Date(class_?.startDate).getTime();

  const {
    data: dataForm,
    errors,
    setData,
    handleChangeCheckValue,
    handleChangeValue,
    handleCleanForm,
    handleSubmit,
    handleChangeValueWithMask,
  } = useForm<ICreateClass.Input>({
    validations: {
      address: { required: Modality.isInPerson(object?.modality) },
      cep: { required: Modality.isInPerson(object?.modality) },
      city: { required: Modality.isInPerson(object?.modality) },
      state: { required: Modality.isInPerson(object?.modality) },
      number: { required: Modality.isInPerson(object?.modality) },
      neighborhood: { required: Modality.isInPerson(object?.modality) },
      link: { required: Modality.isOnline(object?.modality) },
      title: { required: true },
      info: { required: true },
    },
    onSubmit: () => (data?.id?.match("Temp") ? handleCreateClass() : handleUpdateClass()),
  });

  const { isLoading: creatingClass, handle: handleCreateClass } = useService({
    service: () => createClass({ ...dataForm, objectId: object?.id }),
    onLoading: () => toast.loading("Criando turma...", { id: "creatingClass" }),
    onSuccess: (res) => {
      toast.dismiss("creatingClass");
      toast.success("Turma criada com sucesso.", { id: "creatingClass" });
      setClasses([
        ...classes,
        { ...res, statusBool: Status.toBoolean(res.status), expired: isDeadlinePassed(res.endDate) },
      ]);
      handleSetClass({ ...res, statusBool: Status.toBoolean(res.status), expired: isDeadlinePassed(res.endDate) });
    },
    onError: (err) => {
      toast.dismiss("creatingClass");
      console.log(err);
      toast.error(err.response?.data?.message, { id: "creatingClass" });
    },
  });

  const { isLoading: updatingClass, handle: handleUpdateClass } = useService({
    service: () => updateClass({ ...dataForm, objectId: object?.id }),
    onLoading: () => toast.loading("Atualizando turma...", { id: "updatingClass", duration: 100000 }),
    onSuccess: (res) => {
      toast.dismiss("updatingClass");
      toast.success("Turma atualizada com sucesso.", { id: "updatingClass", duration: null });
      updateOneClass({ ...res, statusBool: Status.toBoolean(res.status), expired: isDeadlinePassed(res.endDate) });
      setClass_({ ...res, statusBool: Status.toBoolean(res.status), expired: isDeadlinePassed(res.endDate) });
    },
    onError: (err) => {
      toast.dismiss("updatingClass");
      //reverter alterações (status) onError
      setData({ ...dataForm, statusBool: class_.statusBool });
      toast.error(err.response.data.message || "Erro ao atualizar turma.", { id: "updatingClass", duration: null });
    },
  });

  const { isLoading: deletingClass, handle: handleDeleteClass } = useService({
    service: () => deleteClass({ id: class_.id }),
    onLoading: () => toast.loading("Deletando turma...", { id: "deletingClass" }),
    onSuccess: (res) => {
      toast.dismiss("deletingClass");
      toast.success("Turma removida com sucesso.");

      removeOneClass(class_.id);
      handleCleanForm();
      setClass_(null);
    },
    onError: (err) => {
      toast.dismiss("deletingClass");
      toast.error(err.response.data.message, { duration: 15000 , position: 'top-center'});
    },
  });

  const handleSetCEPData = (cep: string) => {
    const cepWithoutMask = cep.replace(/\D/g, "");
    if (cepWithoutMask.length === 8) {
      toast
        .promise(utilsService.getDataFromCEP(cepWithoutMask), {
          loading: "Buscando dados do CEP informado...",
          success: "Dados do CEP encontrados com sucesso.",
          error: "Não foi possível encontrar os dados do CEP informado!.",
        })
        .then((response) => {
          setData({
            ...dataForm,
            address: response.address,
            city: response.city,
            state: response.state,
            cep: response.cep,
            neighborhood: response.neighborhood,
          });
        });
    }
  };

  const LOADING_PAGE = creatingClass || updatingClass || deletingClass;

  useEffect(() => {
    if (!!data) setClass_(data);
  }, [data]);

  useEffect(() => {
    if (class_?.id) {
      setData({
        ...class_,
        ...class_?.address,
        title: class_?.title,
        info: class_?.info,
        link: class_?.link,
        id: class_?.id,
        objectId: class_?.objectId,
        statusBool: class_?.status === Status.Active,
        status: class_?.status,
        limitOfUsers: class_?.limitOfUsers,
        startDate: class_?.startDate ? fixDateTime(new Date(class_.startDate)).toInput() : "",
        endDate: class_?.endDate ? fixDateTime(new Date(class_.endDate)).toInput() : "",
        subscriptionDeadline: class_?.subscriptionDeadline
          ? fixDateTime(new Date(class_?.subscriptionDeadline)).toInput()
          : "",
      });
    }
  }, [class_]);

  useEffect(() => {
    if (CLASS_CLOSED) {
      updateClass({ id: class_.id, objectId: object?.id, status: Status.Inactive, title: object.title, pass: true})
    }
  }, [CLASS_CLOSED, class_]);

  console.log(CLASS_STARTED);
  return (
    <form className={`col-xl-6 ${className} classInfos`} onSubmit={handleSubmit} id="classInfos">
      {!!class_ ? (
        <>
          <div className="label title">
            {!data?.id?.match("Temp")
              ? "Informações da Turma " +
                (!CLASS_CLOSED ? (class_?.statusBool ? "(Aberta)" : "(Fechada)") : "(Encerrada)")
              : "Criar nova turma"}

            {(class_?.statusBool || CLASS_CLOSED) && (
              <div className="manage-subscription" onClick={() => setManageSubscriptions(true)}>
                Gerenciar Inscrições {">"}
              </div>
            )}
          </div>

          <div className="row" id="classInfos">
            <Input
              name="title"
              label="Nome da Turma *"
              placeholder="Ex: Turma de Matemática 1"
              type="text"
              onChange={handleChangeValue("title")}
              value={dataForm.title}
              messageError={errors.title}
              disabled={LOADING_PAGE || CLASS_CLOSED || (class_?.statusBool && CLASS_STARTED)}
              col="12"
            />
          </div>

          <div className="row">
            <Input
              name="startDate"
              label="Data de Inicio"
              type="datetime-local"
              onChange={handleChangeValue("startDate")}
              value={dataForm.startDate}
              col="6"
              disabled={LOADING_PAGE || CLASS_CLOSED || (class_?.statusBool && CLASS_STARTED)}
              min={MIN_DATETIME}
            />
            <Input
              name="endDate"
              label="Data de Fim"
              type="datetime-local"
              onChange={handleChangeValue("endDate")}
              value={dataForm.endDate}
              col="6"
              disabled={LOADING_PAGE || CLASS_CLOSED || (class_?.statusBool && CLASS_STARTED)}
              min={dataForm?.startDate || MIN_DATETIME}
            />
          </div>
          <div className="row">
            <Input
              name="deadline"
              label="Data Limite para Inscrições"
              type="datetime-local"
              value={dataForm.subscriptionDeadline}
              onChange={handleChangeValue("subscriptionDeadline")}
              col="6"
              disabled={
                LOADING_PAGE ||
                CLASS_CLOSED ||
                (dataForm?.endDate && new Date(dataForm?.endDate).getTime() < new Date().getTime())
              }
              min={MIN_DATETIME}
              max={dataForm?.endDate}
            />

            <Input
              name="studentsLimit"
              placeholder="Limite de Inscrições"
              type="number"
              label="Número Máximo de Inscrições"
              value={dataForm.limitOfUsers}
              onChange={handleChangeValue("limitOfUsers")}
              disabled={LOADING_PAGE || CLASS_CLOSED}
              min={1}
              col="6"
            />
          </div>
          <TextArea
            name="infos"
            placeholder="Informações exibidas após a inscrição do aluno."
            label="Informações *"
            rows={2}
            maxLength={600}
            showTextLimit={true}
            value={dataForm.info}
            messageError={errors.info}
            disabled={LOADING_PAGE || CLASS_CLOSED}
            onChange={handleChangeValue("info")}
          />

          {Modality.isOnline(object.modality) && (
            <Input
              name="link"
              label="Link para realização virtual *"
              type="text"
              placeholder="Link de acesso"
              value={dataForm.link}
              messageError={errors.link}
              disabled={LOADING_PAGE || CLASS_CLOSED}
              onChange={handleChangeValue("link")}
            />
          )}
          {Modality.isInPerson(object.modality) && (
            <div className="address-area">
              <label className={`form-label ${""}`}>Endereço para Realização Presencial</label>
              <Input
                name="cp_CEP"
                placeholder="CEP *"
                type="text"
                maxLength={9}
                value={dataForm.cep}
                messageError={errors.cep}
                disabled={LOADING_PAGE || CLASS_CLOSED}
                onChange={handleChangeValueWithMask("cep", "cep", handleSetCEPData)}
              />

              <Row className="register-input-row">
                <Input
                  name="cp_cidade"
                  placeholder="Cidade *"
                  type="text"
                  col="6"
                  value={dataForm.city}
                  messageError={errors.city}
                  disabled={LOADING_PAGE || CLASS_CLOSED}
                  onChange={handleChangeValue("city")}
                />

                <Input
                  name="cp_estado"
                  placeholder="Estado *"
                  type="text"
                  col="6"
                  value={dataForm.state}
                  messageError={errors.state}
                  disabled={LOADING_PAGE || CLASS_CLOSED}
                  onChange={handleChangeValue("state")}
                />
              </Row>

              <Input
                name="cp_address"
                placeholder="Endereço *"
                type="text"
                value={dataForm.address}
                messageError={errors.address}
                disabled={LOADING_PAGE || CLASS_CLOSED}
                onChange={handleChangeValue("address")}
              />

              <Row>
                <Input
                  name="cp_neighborhood"
                  placeholder="Bairro *"
                  type="text"
                  col="6"
                  value={dataForm.neighborhood}
                  messageError={errors.neighborhood}
                  disabled={LOADING_PAGE || CLASS_CLOSED}
                  onChange={handleChangeValue("neighborhood")}
                />
                <Input
                  name="cp_numero"
                  placeholder="Número *"
                  type="text"
                  col="6"
                  value={dataForm.number}
                  messageError={errors.number}
                  disabled={LOADING_PAGE || CLASS_CLOSED}
                  onChange={handleChangeValue("number")}
                />
              </Row>
            </div>
          )}

          {!CLASS_CLOSED ? (
            <>
              <div className="switch">
                <Checkbox
                  type="switch"
                  label="Ofertar esta Turma"
                  checked={dataForm.statusBool}
                  onChange={handleChangeCheckValue("statusBool")}
                />
              </div>

              <ButtonArea className="user-register save-buttons-area">
                <Button
                  type="submit"
                  className="botao"
                  disabled={LOADING_PAGE}
                  loading={creatingClass || updatingClass}
                >
                  {!data?.id?.match("Temp") ? "Salvar" : "Criar Turma"}
                </Button>
                {data?.id?.match("Temp") && (
                  <Button
                    type="button"
                    className="botao"
                    disabled={LOADING_PAGE}
                    onClick={() => {
                      handleCleanForm();
                      handleSetClass(null);
                      setClass_(null);
                    }}
                  >
                    Cancelar
                  </Button>
                )}
                {!data?.id?.match("Temp") && (
                  <Button
                    type="button"
                    className="botao exclude"
                    disabled={LOADING_PAGE}
                    loading={deletingClass}
                    onClick={() => setShowModal(true)}
                  >
                    <i className="uil uil-trash"></i>
                    Excluir
                  </Button>
                )}
              </ButtonArea>
            </>
          ) : (
            <>
              <div
                className="openNewClassBtn"
                onClick={() => {
                  handleAddClass(data?.title, data?.info, data?.limitOfUsers, data?.link, class_?.address);
                }}
              >
                Abrir nova turma com estas configurações
              </div>
            </>
          )}
        </>
      ) : (
        <div className="labelClassInfo">Nenhuma turma selecionada.</div>
      )}
      {/* <Notification position="bottom-left" /> */}
      <ModalDeletionConfirmation 
        itemName={data?.title} 
        showModal={showModal}
        confirmDeletion={handleDeleteClass}
        setShowModal={setShowModal}
      />
    </form>
  );
}
