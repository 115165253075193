import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, ButtonArea, Container, Input, Loading, Notification } from "components";
import { useForm, usePasswordState, useRequest } from "hooks";
import { IAuth, IPasswordReset } from "interfaces";
import { adminServices } from "services";
import './style.css'
import { toast } from "react-hot-toast";

enum Status {
    Success = 0,
    Error = 1,
    PasswordChanged = 2,
    Loading = 3
}

const returnMessage = (status: Status, action: () => void) => {
    const messages = {
        [Status.PasswordChanged] : { main: 'Senha alterada com sucesso!', secondary: 'Realizar login.' },
        [Status.Error] : { main: 'Link inválido ou expirado.', secondary: 'Reenviar uma solicitação.' }
    }

    const icons = {
        [Status.PasswordChanged] : 'uil uil-check-circle',
        [Status.Error] : 'uil uil-exclamation-circle'
    }

    return (
        <div className="col-xl-12">
            <div className={`${icons[status]} icon ${status === Status.PasswordChanged ? 'success' : 'error'}`} />
            <div className='col-xl-12 label'>
                { messages[status].main }
            </div>
            <div className="col-xl-12 linhaLinks-centered" onClick={action}>
                <strong>{ messages[status].secondary }</strong>
            </div>
        </div>
    )
}

export default function ChangePassword() {
    const [params] = useSearchParams()
    const [status, setStatus] = useState(Status.Error);
    const [token, setToken]   = useState('');

    const goTo = useNavigate()

    const { isSuccess, isError, isLoading, error, handleMakeRequest} = useRequest({
        loadingMessage : 'Alterando senha...',
        requestFn      : () => handleSubmitPassword()
    })
    const { passwordState, handleChangeStatePassword } = usePasswordState()
    const { data, errors, handleChangeValue, handleSubmit } = useForm<IPasswordReset>({
        validations: {
            password: {
                required: true,
            },
            confirmPassword: {
                required: true,
            }
        },
        onSubmit: () => handleMakeRequest()
    })

    const handleSubmitPassword = () => adminServices.changePassword({ ...data, token })

    useEffect(() => {
        const token = params.get('token')
        const decodedToken = token ? jwtDecode<IAuth.ResetPasswordToken>(token) : null
        if (token) {
            const isTokenValid = decodedToken && decodedToken.exp > Date.now() / 1000
            if (isTokenValid) {
                setToken(token)
                setStatus(Status.Success)
            }
        } else setStatus(Status.Error)
    }, [])

    useEffect(() => { 
        if (isError) {
            if (error?.data.name === 'Unauthorized') setStatus(Status.Error)
            else toast.error(error.data.message, { duration: 15000 , position: 'top-center'})
        }
    }, [isError])

    useEffect(() => { if (isSuccess) setStatus(Status.PasswordChanged) }, [isSuccess])

    return (
        <Container.Normal>
            <Box title="Recuperação Senha">
                {
                    !isLoading ?
                    <form onSubmit={handleSubmit}>
                        <div className="row recovery-container">
                            {
                                status === Status.Success &&
                                <>
                                    <Input
                                        type="password"
                                        placeholder="Nova senha *"
                                        name="cp_senha"
                                        passwordState={passwordState}
                                        changePasswordState={handleChangeStatePassword}
                                        value={data.password}
                                        messageError={errors.password}
                                        onChange={handleChangeValue('password')}
                                    />
                                    <Input
                                        type="password"
                                        placeholder="Confirmação de senha *"
                                        name="cp_confimacaoSenha"
                                        passwordState={passwordState}
                                        changePasswordState={handleChangeStatePassword}
                                        value={data.confirmPassword}
                                        messageError={errors.confirmPassword}
                                        onChange={handleChangeValue('confirmPassword')}
                                    />
                                    <ButtonArea className="linhaBotoes login">
                                        <Button type="submit">Confirmar</Button>
                                        <Button
                                            type="button"
                                            className="cadastrese"
                                            onClick={() => goTo('/login')}
                                        >
                                            Voltar
                                        </Button>
                                    </ButtonArea>
                                </>
                            }
                            {
                                status === Status.Error &&
                                returnMessage(Status.Error, () => goTo('/login'))
                            }
                            {
                                status === Status.PasswordChanged &&
                                returnMessage(Status.PasswordChanged, () => goTo('/login'))
                            }
                            {
                                status === Status.Loading &&
                                <div className='col-xl-12 recovery-container-loading'>
                                    <Loading.Small loading={true} />
                                </div>
                            }
                        </div>
                    </form> :
                    <Loading.Small loading={isLoading} />
                }
                <Notification position={"top-right"}/>
            </Box>
        </Container.Normal>
    )
}