import { createContext, useState, useEffect } from "react";
import { UserTypes } from "enums";
import { TJwt } from "types";
import jwtDecode from "jwt-decode";

type ContextProps = {
    profileSelected: UserTypes.Admin | null;
    adminInfo: TJwt.AdminContent | null;
    handleSetUserInfoByToken: (token: string) => void;
    handleLogout: () => void;
}
  
type ProviderProps = {
    children: React.ReactNode;
}

export const AdminContext = createContext<ContextProps>({} as ContextProps);
export const AdminProvider = ({ children }: ProviderProps) => {
    const [profileSelected, setProfileSelected] = useState<UserTypes.Admin | null>(null);
    const [info, setInfo] = useState<TJwt.AdminContent | null>(null);

    useEffect(() => {
        const storageProfileSelected = localStorage.getItem('profileSelected');
        const storageToken = localStorage.getItem('accessToken');

        if (storageProfileSelected) setProfileSelected(storageProfileSelected as UserTypes.Admin);
        if (storageToken) setInfo(jwtDecode<TJwt.AdminContent>(storageToken));
    }, [])

    const handleSetInfoByToken = (token: string) => setInfo(jwtDecode<TJwt.AdminContent>(token));
    const handleLogout = () => {
        localStorage.clear();
        setProfileSelected(null);
        setInfo(null);
    }

    return (
        <AdminContext.Provider value={{ 
                profileSelected, 
                adminInfo: info, 
                handleSetUserInfoByToken: handleSetInfoByToken, 
                handleLogout
            }}
        >
            { children }
        </AdminContext.Provider>
    )
}