import { ChangeEventHandler } from "react"
import './style.css'

type Props = {
    type                 : 'email' | 'password' | 'text' | 'date' | 'number' | 'datetime-local'
    className?           : string 
    placeholder?         : string  
    label?               : string
    icon?                : string
    value?               : string | number
    name                 : string
    passwordState?       : any
    changePasswordState? : any
    messageError?        : string
    onChange?            : ChangeEventHandler<HTMLInputElement>
    col?                 : string
    maxLength?           : number
    disabled?            : boolean
    min?                 : number | string
    max?                 : number | string  
    omitIcon?            : boolean
}

export default function Input({ 
    type, 
    className,
    placeholder, 
    icon, 
    name, 
    passwordState, 
    value, 
    changePasswordState, 
    messageError, 
    onChange, 
    col, 
    maxLength, 
    min, 
    disabled=false, 
    label ,
    max,
    omitIcon
}: Props) {
    col == null? col = '12' : col = col
    if (type === "password") {
        return (
            <div className={`col-xl-12 linhaCampo senha ${ messageError ? 'withError' : '' }`}>
                {
                    label &&
                    <label className={`form-label ${ messageError && 'withError' }`}>{label}</label>
                }
                {
                    icon &&
                    <div className="ico">
                        <i className={`uil ${icon}`}></i>
                    </div>
                }
                {
                    !omitIcon &&
                    <div className="ico dir cursor cinza btnOlho" id={'icons_' + name } onClick={() => changePasswordState(name)}>
                        <i className={`uil uil-eye ${passwordState[name] ? 'd-none' : ''}`} title="Mostrar senha"></i>
                        <i className={`uil uil-eye-slash ${passwordState[name] ? '' : 'd-none'}`} title="Esconder senha"></i>
                    </div>
                }
                <input 
                    type={passwordState[name] ? 'text' : 'password'}
                    id={'input_' + name}
                    className={`campo campoSenha padRight ${icon == undefined || icon == '' ? 'withoutIcon' : ''} ${className}`} 
                    value={value || ''}
                    name={name} 
                    placeholder={placeholder}
                    onChange={onChange} 
                />
                <div className="message-error">
                    { messageError }
                </div>
            </div>
        )
    } else {
        return (
            <div className={`col-xl-${col} linhaCampo ${ messageError ? 'withError' : '' }`}>
                {
                    label &&
                    <label className={`form-label ${ messageError && 'withError' }`}>{label}</label>
                }
                {
                    icon &&
                    <div className="ico">
                        <i className={`uil ${icon}`}></i>
                    </div>
                }
                <input 
                    type={type} 
                    className={`campo ${icon == undefined || icon == '' ? 'withoutIcon' : ''} ${className}`} 
                    value={value || ''}
                    name={name} 
                    placeholder={placeholder}
                    onChange={onChange} 
                    maxLength={maxLength}
                    disabled={disabled}
                    min={min}
                    max={max}
                />
                {name === 'cp_preco' && <small className="form-label text-muted"> Curso gratuito: Campo vazio | Curso pago: A partir de 2 reais. </small>}
                <div className="message-error">
                    { messageError }
                </div>
            </div> 
        ) 
    }
}