import { configureStore } from "@reduxjs/toolkit";
import authStepsReducer  from './slices/AuthStepsSlice';
import stepsBoxReducer from './slices/StepsBoxSlice';
import orderReducer from './slices/OrderSlice';
import globalObjectsReducer from "slices/GlobalObjects";
import globalValuesReducer from './slices/GlobalValues';
import cartReducer from './slices/CartSlice';

export const store = configureStore({
    reducer    : {
        authSteps     : authStepsReducer,
        stepsBox      : stepsBoxReducer,
        order         : orderReducer,
        globalObjects : globalObjectsReducer,
        globalValues  : globalValuesReducer,
        cart          : cartReducer,
    },
    middleware : (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch