import { apiRoutes, api_ } from "config";
import { PD_UsersInputModel, PD_UsersViewModel } from "models";
import { authenticate } from "services_/Auth";

export namespace ISaveAdditionalInfo {
    export type Input = PD_UsersInputModel;
    export type Output = PD_UsersViewModel;
}

export const saveAdditionalInfo = async (input: ISaveAdditionalInfo.Input): Promise<ISaveAdditionalInfo.Output> => {
    const url = apiRoutes.v1.users + '/info';

    const response = await api_.post<ISaveAdditionalInfo.Output>(url, input);

    await authenticate()
    return response.data;
}