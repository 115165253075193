import { ReactNode } from "react";

type Props = {
    title: string
    children: ReactNode
}
export function PaymentBody({ title, children }: Props) {
    return (
        <div id="corpo">
            {/* por algum motivo a classe 'minimo' nao esta aplicando no component */}
            <div className="caixa minimo" style={{ maxWidth: '768px' }}>
                <div className="row colunaCentral">
                    <div className="col-xl-12">
                        <h3 className="titulo">{title}</h3>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}