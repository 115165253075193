import { apiRoutes, api_ } from "config";
import { Status } from "enums";
import { TypeInputModel, TypeModel, TypeViewModel } from "models"

export namespace ISaveType {
    export type Input = TypeInputModel
    export type Output = TypeViewModel
}

export const saveType = async (info: ISaveType.Input): Promise<ISaveType.Output> => {
    const url = apiRoutes.v1.types;

    const body: Pick<TypeModel, 'name' | 'status'> = {
        name: info.name,
        status: info.statusBool ? Status.Active : Status.Inactive
    }

    const response = await api_.post<TypeViewModel>(url, body);
    return response.data;
}