import { Banner, Card, Container, HomeFooter, Slides } from "components";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HighlightedObjectsContext, UserContext } from "contexts/public";
import { getObjectsRecommendation } from "services_/Object";
import { toast } from "react-hot-toast";
import { setApprenticeClasses, setRecommendedObjects } from "slices/GlobalObjects";
import { ObjectStatus } from "enums";
import 'assets/css/homeUser.css'
import './style.css'
import { getSubscribedClasses } from "services_/User";

export default function ApprenticeHome() {
    const { recommendedObjects, apprenticeClasses, highlightedObjects } = useAppSelector(state => state.globalObjects)
    const { loadingData } = useContext(HighlightedObjectsContext);
    const { userInfo } = useContext(UserContext);

    const dispatch = useAppDispatch();
    const goTo = useNavigate();

    const {
        isLoading: loadingRecommendation,
        handle: handleGetRecommendation,
    } = useService({
        service: () => getObjectsRecommendation({ size: 10, status: ObjectStatus.Approved }),
        onSuccess: (res) => dispatch(setRecommendedObjects(res)),
        onError: (err) => toast.error(err?.response?.data?.message || 'Erro ao carregar os recursos recomendados.', { duration: 15000 , position: 'top-center'}),
    })

    const {
        isLoading: loadingApprenticeClasses,
        handle: handleGetApprenticeClasses,
    } = useService({
        service: () => getSubscribedClasses(),
        onSuccess: (res) => dispatch(setApprenticeClasses(res)),
        onError: (err) => toast.error(err?.response?.data?.message || 'Erro ao carregar as turmas inscritas do aluno.', { duration: 15000 , position: 'top-center'}),
    })

    const LOADING_HOME = loadingData || loadingRecommendation || loadingApprenticeClasses;

    useEffect(() => { 
        if (!recommendedObjects.length) handleGetRecommendation()
        if (!apprenticeClasses.length) handleGetApprenticeClasses()
    }, [])

    return (
        <div className="home-user a">
            <Banner />
            <Container.Internals>
            {/* TODO: Criar mensagem alternativa para cada um desses slides, caso não tenha items para listar. */}
                <Slides.Large
                    type='normal-4'
                    title={!apprenticeClasses.length ? 'Olá ':`Essas são as suas turmas, `}
                    midTitle={userInfo?.name}
                    endTitle={!apprenticeClasses.length ?', Você ainda não tem turmas.' : '.'}
                    description={!apprenticeClasses.length ? 'Adquira já novas turmas.' : 'Estas já foram adquiridas por você.'}
                    textPlus={!apprenticeClasses.length ? '' : 'Ver minhas turmas'}
                    link="/minhas-turmas"
                    icon='uil-book-open'
                    isLoading={LOADING_HOME}
                    showButtons={!!apprenticeClasses.length}
                >
                    {
                        apprenticeClasses?.map((card) =>(
                            <Card
                                onclick={() => goTo(`/recurso/${card.class?.objectId}/turma/${card.class?.id}`)}
                                title={card.title}
                                text={card.type.name}
                                image={card.object.image}
                                type='caixa1'
                            />
                        ))
                    }
                </Slides.Large>

               
               <>
               
                <Slides.Large
                        type='normal-4'
                        title={`Recomendamos para você.`}
                        description='Recomendações que podem ser do seu interesse.'
                        icon='uil-graduation-cap'
                        isLoading={LOADING_HOME}
                    >
                        {
                            recommendedObjects?.map((obj) =>(
                                <Card
                                    title={obj.title}
                                    text={obj.type.name}
                                    image={obj.image}
                                    onclick={() => goTo(`/recurso/${obj.id}`)}
                                    type='caixa1'
                                />
                            ))
                        }
                    </Slides.Large>
                    
                    <Slides.Large
                        type='normal-4'
                        title='Destaques de Vendas'
                        description='Estes são os recursos que estão em alta.'
                        icon='uil-award'
                        isLoading={LOADING_HOME}
                    >
                        {
                            highlightedObjects?.map((obj) => (
                                <Card
                                    onclick={() => goTo(`/recurso/${obj.id}`)}
                                    title={obj.title}
                                    text={obj.type.name}
                                    image={obj.image}
                                    type='caixa1 comBordas'
                                />
                            ))
                        }

                    </Slides.Large>
                </>
               
            </Container.Internals>
            <div style={{marginTop:'60px'}}/>
            <HomeFooter />
        </div>
        
    )
}