import { ListItems, Loading } from "../../..";
import './style.css'
import NoResultImg from "../../../../assets/images/imgNenhumResultado.png"
import { useSearchParams } from "react-router-dom";
import NoResult from "../../../NoResult";

type Props = {
    numResults: number
    loading: boolean
}

export default function ResultsLabel({ numResults, loading }: Props) {
    const [params, setParams] = useSearchParams()

    return (
        <div className='row lista ultimo teste'>
            <div className='col-xl-12'>
                {
                    numResults > 0 ?

                        <div className="legendaResultados">
                            {numResults} {numResults > 1 ? 'Recursos ' : 'Recurso '}
                            {numResults > 1 ? 'encontrados ' : 'encontrado '}
                        </div>
                        :
                        !loading ?

                            <NoResult 
                                firstTitle="Nenhum resultado" 
                                midTitle={`${!!params.get('search') ? `encontrado para ` : 'encontrado'}`}
                                endTitle={`
                                    ${!!params.get('search') ? `"${params.get('search')}"` : ''}
                                `}
                            />
                            :

                            <div className='search-loading-area'>
                                <div className='row'>
                                    <div className='col-xl-5' />
                                    <div className='col-xl-2'>
                                        <Loading.Small />
                                        <div className='col-xl-5' />
                                    </div>
                                </div>
                            </div>


                }
            </div>

        </div>
    )
}