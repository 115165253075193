import { ReactNode, useState } from "react"
import './style.css'

type Props = {
    title             : string
    size              : '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' 
    children          : ReactNode
    emptyListMessage? : string
    showSearchBar     : boolean
    buttonFunction?   : Function
    onSearch?         : (search: string) => void;
    className?        : string
}
export default function BoxList({ title, size, children, showSearchBar, buttonFunction, emptyListMessage, onSearch, className='' }: Props) {
    const [search, setSearch] = useState<string>('')

    const handleSetSearch = (search: string) => {
        setSearch(search)
        if (!search) onSearch(null)
    }
    const handleOnSearch = (e: React.FormEvent, search: string) => {
        e.preventDefault()
        if (onSearch) onSearch(search)
    }

    return (
        <div className={`col-xl-${size} ${className}`}>
            <div className="caixaListagem">

                <div className="cabecalho">
                    <div className='left '>
                        <div className="tituloArea">{title}</div>
                    </div>
                    <div className='right row'>
                        {
                            showSearchBar &&
                            <div className='areaBuscar col-sm-1'>
                                <form id="formBuscar" lang="pt" name="formBuscar" onSubmit={(e) => handleOnSearch(e, search)}>
                                    <input 
                                        type="text" 
                                        name="search" 
                                        placeholder="Buscar por..." 
                                        onChange={(e) => handleSetSearch(e.target.value)}
                                    />
                                    <button type="submit">
                                        <i className="uil uil-search"></i>
                                    </button>
                                </form>

                            </div>
                        }
                        <div className="botoes col-sm-1 areaBotao">
                            <button
                                onClick={() => buttonFunction()}
                            >
                                Novo
                            </button>
                        </div>
                    </div>

                </div>

                <div className="areaListagem">
                    {
                        !!children ? children :
                        <div>
                            { emptyListMessage ? <p>{emptyListMessage} </p> : <p>Não há itens para exibir</p> }
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}