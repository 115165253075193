import { Box, Button, ButtonArea, Modal } from "components"
import { PaymentOrderViewModel } from "models"
import { OrderStatus, cardMethodMap, orderStatusMap } from "enums";
import { useAppDispatch } from "hooks";
import './style.css';
import { setOrder } from "slices/OrderSlice";
import { useNavigate } from "react-router-dom";

type Props = {
	show: boolean
	order: PaymentOrderViewModel
	title: string
	onClose: () => void
}
export default function OrderDetails({ show, title, order, onClose }: Props) {
	const dispatch = useAppDispatch();
	const goTo = useNavigate();

	const handleRetryPayment = () => {
		dispatch(
			setOrder(order.items.map(item => (
				{
					class: { id: item.id, title: item.name.split(' - ')[0] },
					object: { price: item.value, title: item.name.split(' - ')[1] },
				}
			)))
		);
		goTo("/pagamento", { state: 'teste' });
	}

	return (
		<Modal show={show} size='lg' onClose={onClose} isLoading={false}>
			<Box title={title}>
				<div className="order-items">
					<div className="order-items-title">Produtos:</div>
					{
						order?.items?.map((item, index) => (
							<div key={index} className="order-item">
								<div className="order-item-info">
									<div className="order-item-title">{item.name}</div>
									<div className="order-item-price">
										{
											item.value
												.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
										}
									</div>
								</div>
							</div>
						))
					}
					<hr />

					{/* ----- Informações sobre o pedido */}
					<div className="order-items-info">
						{/* ----- Informações sobre total da compra */}
						<div className="order-items-info-total">
							<div className="order-items-lbl-total">
								Total:
							</div>
							<div className="order-items-total">
								{
									order?.value
										.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
								}
							</div>
						</div>
					</div>
					<hr />

					{/* ----- Informações sobre o pagamento */}
					<div className="order-items-payment">
						{/* ----- Informações sobre o status do pagamento */}
						<div className="order-items-payment-status">
							<div className="order-items-lbl-status">
								Status do Pagamento:
							</div>
							<div className="order-items-status">
								{orderStatusMap[order?.status]}
							</div>
						</div>

						{/* ----- Informações sobre a data do pagamento */}
						{
							order?.status === OrderStatus.Finished && (
								<div className="order-items-payment-date">
									<div className="order-items-lbl-date">
										Data de Pagamento:
									</div>
									<div className="order-items-date">
										{
											new Date(order?.date).toLocaleDateString()
										}
									</div>
								</div>
							)
						}

						{/* ----- Informações sobre o método de pagamento */}
						<div className="order-items-payment-method">
							<div className="order-items-lbl-payment">
								Pagamento:
							</div>
							<div className="order-items-payment">
								{
									`${cardMethodMap[order?.payment.card_method]}
														- ${order?.payment.card_flag[0].toUpperCase() + order?.payment.card_flag.substring(1)} ${order?.payment.card_number.substring(10)}`

								}
							</div>
						</div>

						{/* ----- Observação do pagamento */}
						<div className="order-items-payment-observation">
							<div className="order-items-lbl-observation">
								Observação:
							</div>
							<div className="order-items-observation">
								{order?.payment?.gatewayMessageError}
							</div>
						</div>
					</div>
				</div>

				<ButtonArea>
					{
						order?.status === OrderStatus.Rejected && (
							<Button
								type="button"
								className="btn-retry"
								onClick={handleRetryPayment}
							>
								<i className="uil uil-sync"></i>
								Tentar Novamente
							</Button>
						)
					}
				</ButtonArea>
			</Box>
		</Modal>
	)
}