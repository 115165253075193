import NoResult from "../../assets/images/imgNenhumResultado.png"

type Props = {
    firstTitle?: string,
    midTitle?: string,
    endTitle?: string
}
export default function NoResults({ firstTitle, midTitle, endTitle }: Props) {
    return (
        <>
            <div className="col-xl-12 nenhumResultado">
                <img src={NoResult} alt="" className="imgNenhumResultado" />
                <div className="txtNenhumResultado">
                    <strong>{firstTitle}</strong> {midTitle} {
                        !!endTitle && (
                            <>
                                <em>{endTitle}</em>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}