import Forma from "assets/images/forma.png";
import { Modality, PaymentType } from "enums";
import { useAppDispatch, useAppSelector, useService } from "hooks";
import { openSteps } from "slices/AuthStepsSlice";
import { IAuthSteps } from "interfaces";
import { removeCurrencyMask } from "Functions";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ClassViewModel, ObjectViewModel, SubscriptionViewModel } from "models";
import { UserContext } from "contexts";
import { classSubscription, generateCertificate, addItemsToCart} from "services_/Class";
import { toast } from "react-hot-toast";
import { access } from "services_/CourseIntegration";
import { Spinner } from "react-bootstrap";
import "./style.css";
import { getCertificate } from "services_/Class/GetCertificate";
import AddLinkdinButton from "./AddToLinkedin";
import { CreateLinkedinCertificate } from "services_/Object";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { setOrder } from "slices/OrderSlice";
import { useParams } from "react-router-dom"; 
import { fetchCartItems, setCartIsActive } from "slices/CartSlice";

type Props = {
  data: { object: ObjectViewModel; class: ClassViewModel; subscription: SubscriptionViewModel};
  isClass?: boolean;
};
export default function ObjectBanner({ data, isClass = false }: Props) {
  const { userInfo } = useContext(UserContext);

  const [certificateURL, setCertificateURL] = useState("");

  const dispatch = useAppDispatch();
  const goTo = useNavigate();
  const param = useParams();
  
  const cart = useAppSelector((state) => state.cart);
  const existsInTheCart = cart.cartList.some(item => item.class.objectId === param.objectId);  

  const startDate = !!data.class?.startDate ? new Date(data.class.startDate).toLocaleDateString("pt-br") : "-";
  const endDate = !!data.class?.endDate ? new Date(data.class.endDate).toLocaleDateString("pt-br") : "-";
  const startTime = !!data.class?.startDate
    ? new Date(data.class.startDate).toLocaleTimeString("pt-br").substring(0, 5)
    : "-";
  const limitDate = !!data.class?.subscriptionDeadline
    ? new Date(data.class.subscriptionDeadline).toLocaleDateString("pt-br")
    : "-";
  const limitTime = !!data.class?.subscriptionDeadline
    ? new Date(data.class.subscriptionDeadline).toLocaleTimeString("pt-br").substring(0, 5)
    : "-";
  const certificateDate = new Date(data.subscription?.finishedAt) ?? undefined;

  const goToClasses = () => {
    const anchor = document.querySelector("#turmas");
    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleButtonAction = () => {
    if (!isClass) goToClasses();
    else {
      /* Verifica se o usuário está com todas as informações cadastradas, caso contrário, abre o fluxo de cadastro,
       * caso contrário, inscreve o usuário na turma.
       */
      if (!userInfo) dispatch(openSteps({ step: IAuthSteps.Steps.LOGIN }));
      else if (!userInfo.emailVerified) {
        dispatch(
          openSteps({
            step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
            props: {
              title: "Confirmação de E-mail",
              email: userInfo?.email,
              type: "email",
              nextStep: {
                step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
                props: { userData: userInfo },
              },
            },
          })
        );
      } else if (!userInfo.document)
        dispatch(
          openSteps({
            step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
            props: { userData: userInfo },
          })
        );
      else if (!isSubscribing) {
        if (data?.object?.paymentType === PaymentType.Free) handleSubscription();
        else {
          dispatch(
            setOrder([
              {
                class: { id: data.class?.id, title: data.class?.title },
                object: { price: data.object?.price, title: data.object?.title },
                // addClasses: {id: item.id, class: item.class, entryDate: item.entryDate, exclusionDate: item.exclusionDate, order: item.order,  price:item.price}
              },
            ])
          );
          goTo("/pagamento", { state: "teste" });
        }
      }
    }
  };

  const handleButtonCart = () => {
    if (!userInfo) dispatch(openSteps({ step: IAuthSteps.Steps.LOGIN }));
    else if (!userInfo.emailVerified) {
      dispatch(
        openSteps({
          step: IAuthSteps.Steps.EMAIL_CONFIRMATION,
          props: {
            title: "Confirmação de E-mail",
            email: userInfo?.email,
            type: "email",
            nextStep: {
              step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
              props: { userData: userInfo },
            },
          },
        })
      );
    } 
    else if (!userInfo.document)
      dispatch(
        openSteps({
          step: IAuthSteps.Steps.ADDITIONAL_INFORMATION,
          props: { userData: userInfo },
        }
      )
    );
    
    !existsInTheCart && handleAddButtonCart();
    existsInTheCart && dispatch(setCartIsActive(!cart.cartIsActive));
  };
 

  const { handle: handleAddButtonCart, isLoading: isOnCart } = useService({
    service: () =>
      toast.promise(
        addItemsToCart({
          classId: data.class?.id,
          userId: userInfo.userId,
        }),
        {
          loading: "Adicionando ao carrinho...",
          success: () => "Recurso adicionado ao Carrinho com sucesso!",
          error: (error) =>
            error?.response?.data?.message || "Ocorreu um erro ao adicionar ao Carrinho."
        },
        { duration: 15000 }
      ),
      onSuccess: async (res) => {
        await dispatch(fetchCartItems());
      }

  })

  const { handle: handleSubscription, isLoading: isSubscribing } = useService({
    service: () =>
      toast.promise(
        classSubscription({ classId: data.class?.id }),
        {
          loading: "Realizando inscrição...",
          success: () => "Inscrição realizada com sucesso!",
          error: (error) =>
            error?.response?.data?.message || "Ocorreu um erro ao realizar a inscrição. Tente novamente mais tarde.",
        },
        { duration: 0 }
      ),

    onSuccess: () => (window.location.href = "/minhas-turmas"),
  });

  const { isLoading: loadingAccess, handle: handleAccess } = useService({
    service: () =>
      access({
        provider: data?.object?.provider,
        objectId: data?.object?.id,
      }),
    onSuccess: (res) => {
      const newWindow = window.open(res, "_blank");
      newWindow.onload = function () {
        window.location.reload();
      };
    },
  });

  //jsPDF
  const generatePDF = async (html: any) => {
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = html;

    let canvas = await html2canvas(iframedoc.body, { useCORS: true });

    let imgData = canvas.toDataURL("image/png");

    const doc = new jsPDF({ format: "a4", orientation: "landscape" });
    const pdfMeasures = doc.internal.pageSize;
    doc.addImage(imgData, "PNG", -2, -2, pdfMeasures.getWidth() + 2, pdfMeasures.getHeight() + 4);

    let url = doc.output("bloburl");
    window.open(url, "_blank");

    document.body.removeChild(iframe);
  };

  const handleButtonCertificate = async () => {
    const hasUserFinishedClass = data?.subscription?.finished;
    const hasUserGeneratedCertificate = data?.subscription?.certificate;

    if (hasUserFinishedClass && hasUserGeneratedCertificate) handleGetCertificate();
    else if (hasUserFinishedClass && !hasUserGeneratedCertificate) handleGenerateCertificate();
  };

  const { handle: handleGenerateCertificate, isLoading: loadingGenerateCertificate } = useService({
    service: () =>
      generateCertificate({
        classId: data.class?.id,
        userId: userInfo.userId,
      }),
    onLoading: () => toast.loading("Gerando Certificado...", { id: "generateCertificate" }),
    onError: (err) =>
      toast.error(err?.response?.data?.message || "Ocorreu um erro ao gerar o certificado.", {
        id: "generateCertificate",
      }),
    onSuccess: async (res) => {
      toast.success("Certificado gerado com sucesso!", { id: "generateCertificate" });
      setCertificateURL(res.certificateURL);
      //TODO: Colocar este código em outra função.
      const response = await fetch(res.certificateURL);
      const html = await response.text();

      await generatePDF(html);

      window.location.reload();
    },
  });

  const { handle: handleGetCertificate, isLoading: loadingGetCertificate } = useService({
    service: () =>
      getCertificate({
        classId: data.class?.id,
        userId: userInfo.userId,
      }),
    onLoading: () => {
      toast.loading("Carregando Certificado...", { id: "getCertificate" });
    },
    onError: (err) => {
      toast.dismiss("getCertificate");
      toast.error(err?.response?.data?.message || "Ocorreu um erro ao buscar o certificado.", { id: "getCertificate" });
    },
    onSuccess: async (res) => {
      toast.dismiss("getCertificate");
      // toast.success("Certificado carregado com sucesso!", { id: "getCertificate" });
      setCertificateURL(res.certificateURL);
      //TODO: Colocar este código em outra função.
      const response = await fetch(res.certificateURL);
      const html = await response.text();
      await generatePDF(html);
    },
  });

  const { handle: handleAddToLinkedin, isLoading: loadingAddToLinkedin } = useService({
    service: () =>
      CreateLinkedinCertificate({
        certId: data?.subscription?.certificate,
        certUrl: certificateURL,
        expirationMonth: 0,
        expirationYear: 0,
        issueMonth: certificateDate.getMonth(),
        issueYear: certificateDate.getFullYear(),
        name: data?.object?.title,
        organizationName: "Academy4Life",
      }),
    onLoading: () => {
      toast.loading("Redirecionando ao linkedin", { id: "addToLinkedIn" });
    },
    onSuccess: () => {
      toast.dismiss("addToLinkedIn");
    },
  });

  const CLASS_ALREADY_SUBSCRIBED = !!data?.subscription;

  // const [progression, setProgression] = useState<ClassSubscriptionProgression>({ certified: '', conclusion: false })

  // useEffect(() => {
  //   // if (CLASS_ALREADY_SUBSCRIBED) setProgression(data?.class?.progression)
  //   // if (CLASS_ALREADY_SUBSCRIBED) setProgression({ certified: '', conclusion: true })
  // }, [data]);

  return (
    <>
      <div id="bannerInternas">
        <div className="fundo">
          <img src={data.object.image} alt="" />
        </div>

        <div className="dados">
          <div className="esq">
            {isClass && (
              <div className="recurso-arrow-area">
                <div
                  onClick={() =>
                    goTo(`/recurso/${data.object.id}`, {
                      replace: true,
                    })
                  }
                  className="uil uil-arrow-left recurso-arrow-left"
                />
              </div>
            )}
     
            {!isClass && (
              <div className="recurso-arrow-area">
                <div
                  onClick={() =>
                    goTo(`/`, {
                      replace: true,
                    })
                  }
                  className="uil uil-arrow-left recurso-arrow-left"
                />
              </div>
            )}            

            <div className="titulo">{isClass ? `${data.class?.title} - ${data.object.title}` : data.object.title}</div>
            <div className="descricao">{data.object.description}</div>

            <div className="itens">
              {isClass && (
                <div className="item">
                  <i className="uil uil-calender"></i>
                  <span>
                    De {startDate} até {endDate}
                  </span>
                </div>
              )}

              {isClass && (
                <div className="item">
                  <i className="uil uil-clock"></i>
                  <span>Às {startTime}</span>
                </div>
              )}

              {Modality.isInPerson(data.object.modality) && isClass && (
                <div className="item">
                  <i className="uil uil-map-marker"></i>
                  <span>
                    {data.object.type.name} Presencial em {data.class?.place}
                  </span>
                </div>
              )}

              {Modality.isOnline(data.object.modality) && isClass && (
                <div className="item">
                  <i className="uil uil-cloud"></i>
                  <span>
                    {data.object.type.name} Online via {data.class?.link}
                  </span>
                </div>
              )}

              <div className="item">
                <i className="uil uil-user"></i>
                <span>{data.object.user.name}</span>
              </div>

              <div className="item">
                <i className="uil uil-book-open"></i>
                <span>{data.object.area.name}</span>
              </div>

              {!!isClass && !CLASS_ALREADY_SUBSCRIBED && (
                <div className="item inscricao">
                  <span>
                    Inscrição até o dia {limitDate} às {limitTime}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="dir">
            <div className="quadro">
              <img src={data.object.image} alt="" className="foto" />
              {
                <>
                  {!CLASS_ALREADY_SUBSCRIBED && (
                    <div className="valor">
                      {removeCurrencyMask(String(data.object.price)) > 0 ? (
                        <>
                          <span className="menor">R$</span>
                          <span>{String(data.object.price).replace("R$", "")}</span>
                        </>
                      ) : (
                        <span>Gratuito</span>
                      )}
                    </div>
                  )}

                  {/* Botão para acessar conteúdo via provedor externo */}
                  {CLASS_ALREADY_SUBSCRIBED && !!data?.object?.provider && (
                    <div role="button" className="botao" style={{ marginTop: "20px" }} onClick={handleAccess}>
                      ACESSAR RECURSO
                      <i style={{ marginLeft: "5px" }} className="uil uil-external-link-alt"></i>
                    </div>
                  )}

                  {/* Botão para visualizar turmas ou adquirir recurso */}
                  {!CLASS_ALREADY_SUBSCRIBED && (
                    <div role="button" className="botao" onClick={handleButtonAction}>
                      {!isSubscribing ? (
                        !isClass ? (
                          "TURMAS DISPONÍVEIS"
                        ) : removeCurrencyMask(String(data.object.price)) === 0 ? (
                          "INSCREVA-SE"
                        ) : (
                          "COMPRAR AGORA"
                        )
                      ) : (
                        <Spinner animation="border" role="status" size="sm" />
                      )}
                    </div>
                  )}
              
                  { 
                    (!CLASS_ALREADY_SUBSCRIBED && removeCurrencyMask(String(data.object.price)) > 0 && !!isClass) &&
                    <div
                      role='button' 
                      className="botao"
                      onClick={handleButtonCart}
                      style={{marginBottom: '-15px', marginTop:'15px', padding: '5px 20px'}}
                      // onClick={handleButtonAction}
                    >
                    {
                      !isOnCart ? (
                        existsInTheCart ?
                          "VISUALIZAR MEU CARRINHO" :
                          "ADICIONAR AO CARRINHO" 
                      ) : (
                        <Spinner animation="border" role="status" size="sm" />
                      )
                     }
                    {!isOnCart && <i className="uil-shopping-cart"></i>}                   
                    </div> 
                
                  }
                   {/* {cartIsActive && <Cart setCartIsActive={setCartIsActive}/>} */}
                  {/* Botão para gerar certificado */}
                  {CLASS_ALREADY_SUBSCRIBED && data?.subscription?.finished && (
                    <>
                      <div
                        role="button"
                        className="botao"
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          !(loadingGetCertificate || loadingGenerateCertificate) ? handleButtonCertificate() : void "";
                        }}
                      >
                        {
                          /* Se o usuário já concluiu o curso e ainda não gerou o certificado,
                           * exibe o botão para gerar certificado
                           */
                          !(loadingGetCertificate || loadingGenerateCertificate) ? (
                            data?.subscription?.finished && data?.subscription?.certificate ? (
                              "VER CERTIFICADO"
                            ) : (
                              "GERAR CERTIFICADO"
                            )
                          ) : (
                            <Spinner animation="border" role="status" size="sm" />
                          )
                        }
                      </div>
                      {data?.subscription?.finished && data?.subscription?.certificate && (
                        <AddLinkdinButton onClick={handleAddToLinkedin} />
                      )}
                    </>
                  )}

                </>
              }
            </div>
          </div>
        </div>

        <div className="forma">
          <img src={Forma} alt="" />
        </div>
      </div>
    </>
  );
}
