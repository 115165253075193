import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getCartItems } from "services_/Class/GetCartItems";
import { Cart } from "types/Cart";

export const initialState: Cart.ICartState = {
    cartList: [],
    cartIsActive: false,
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorData: undefined,
};

export const fetchCartItems = createAsyncThunk(
    'cart/fetchCartItems', 
    async (_, thunkAPI) => {
        const loadingCartItems = toast.loading('Buscando Itens no Carrinho...')
        try {
            const response = await getCartItems();
            toast.dismiss(loadingCartItems)
            return response; 
        } catch (error) {
            toast.dismiss(loadingCartItems)
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartIsActive: (state, action) => {
            state.cartIsActive = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCartItems.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCartItems.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.cartList = action.payload;
            })
            .addCase(fetchCartItems.rejected, (state, action) => {
                state.isLoading = false;
                state.isError= true;
                state.errorData = action.payload;
            })
    }
})

export const { setCartIsActive } = cartSlice.actions;
export default cartSlice.reducer;