import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { ApiError } from 'types/Error';

type Request<T> = {
    sucessMessage?  : string;
    errorMessage?   : string;
    loadingMessage? : string;
    requestFn       : () => Promise<T>;
}
type Response<T = any> = {
    isError           : boolean;
    isLoading         : boolean;
    isSuccess         : boolean;
    error             : ApiError;
    response          : T;
    handleMakeRequest : () => Promise<void>;
    setIsLoading      : React.Dispatch<React.SetStateAction<boolean>>;
}

export const useRequest = <Output = any>({ 
    sucessMessage, 
    loadingMessage, 
    errorMessage, 
    requestFn 
}: Request<Output>): Response<Output> => {
    const [response, setResponse] = useState<Output | null>(null);
    const [error, setError] = useState<any>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const handleMakeRequest = async () => {
        setIsLoading(true);
        setIsError(false);
        setIsSuccess(false);

        let makingRequest;
        if (loadingMessage) makingRequest = toast.loading(loadingMessage, { duration: null });

        try {
            if (sucessMessage) toast.success(sucessMessage, { id: makingRequest })

            const response = await requestFn();
            setIsSuccess(true);
            setResponse(response);
        } catch (error) {
            if (errorMessage) toast.error(errorMessage, { id: makingRequest })
            else toast.error(error.response.data.message, { id: makingRequest })

            setIsError(true);
            setError(error.response);
        } finally {
            if (!sucessMessage && !errorMessage) toast.remove(makingRequest);

            setIsLoading(false);
        }
    }

    return { response, error, handleMakeRequest, isSuccess, isLoading, setIsLoading, isError }
}